import React, { useEffect, useState } from "react"
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer"
import Cookies from "js-cookie"
import axios from "axios"
import innovis_adobe_express from "./../../../Assets/Img/innovis_adobe_express.png"

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		alignItems: "center",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 10,
	},
	membrete: {
		paddingLeft: 15,
	},
	pt_10: {
		paddingTop: 10,
	},
	logo: {
		width: 140,
		height: 90,
		margin: 20,
	},
	title: {
		fontSize: 34,
		fontWeight: "bold",
	},
	highlight: {
		color: "#fff",
		fontSize: 13,
		backgroundColor: "rgb(47, 161, 161)",
		padding: 1,
	},
	subtitle: {
		fontSize: 15,
		fontWeight: "bold",
	},
	campo: {
		fontSize: 15,
		fontWeight: "bold",
	},
	cardInfo: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	info: {
		fontSize: 12,
		fontWeight: "light",
		textAlign: "left",
		paddingTop: 1,
	},
	date: {
		textAlign: "center",
		fontSize: 12,
		paddingLeft: 35,
	},
	spacer: {
		width: 40,
	},
	table: {
		display: "table",
		width: "90%",
		borderStyle: "solid",
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
	},
	tableRow: {
		// margin: "auto",
		flexDirection: "row",
		//width: "90%"
	},
	tableRowTitle: {
		flexDirection: "row",
	},
	tableCol: {
		width: "30%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableColFirst: {
		width: "30%",
		borderStyle: "solid",
		borderWidth: 1,
		borderTopWidth: 0,
		borderLeftWidth: 0,
	},
	tableCell: {
		margin: "auto",
		marginTop: 4,
		marginBottom: 4,
		fontSize: 8,
		padding: 1,
	},
	tableTitle: {
		margin: "auto",
		marginTop: 4,
		marginBottom: 4,
		fontSize: 8,
		color: "rgb(47, 161, 161)",
		padding: 1,
	},
})

const OrderPDF = ({ orderInfo, orderProduct, orderOtherProducts, orderState, tabSection }) => {
	const [products, setProducts] = useState([])

	useEffect(() => {
		if(tabSection === "biochemicals") {
			setProducts(orderProduct)
		} else {
			setProducts(orderOtherProducts)
		}

	}, [orderProduct, orderOtherProducts])

	return (
		<Document>
			<Page style={styles.body}>
				<View style={styles.header}>
					<View style={styles.membrete}>
						{/* Depende el largo del título si lo separa en dos lineas */}
						<Text style={styles.title}>PEDIDO {orderState.length < 10 && orderState}</Text>
						{orderState.length > 10 && <Text style={styles.title}>{orderState}</Text>}
						<View style={styles.pt_10} />
						<Text style={styles.subtitule}>NÚMERO DE PEDIDO: {orderInfo.nroPedido}</Text>
						<Text style={styles.subtitule}>SEDE: {orderInfo.lab}</Text>
						<View style={styles.spacer} />
					</View>
					<View style={styles.membrete}>
						<Image
							src={innovis_adobe_express}
							style={styles.logo}
						/>
						<Text style={styles.info}>Laboratorio bioquímico integral</Text>
						<Text style={styles.date}>Fecha: {new Date().toLocaleDateString()}</Text>
					</View>
				</View>
				<View style={styles.table}>
					<View style={styles.tableRowTitle}>
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Tipo</Text>
						</View>
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Código</Text>
						</View>
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Nombre</Text>
						</View>
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Cantidad Disponible</Text>
						</View>
						<View style={styles.tableCol}>
							<Text style={styles.tableTitle}>Proveedor</Text>
						</View>
					</View>
					{products.map(product => {
						return (
							<View
								style={styles.tableRow}
								key={product.data._id}
							>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product.isTag ? "Etiqueta" : "Producto"}</Text>
								</View>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product.data.code ? product.data.code : "-"}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product.data.name}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product.data.quantity}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product.data.provider?.name ? product.data.provider.name : "-"}</Text>
								</View>
							</View>
						)
					})}
				</View>
			</Page>
		</Document>
	)
}

export default OrderPDF
