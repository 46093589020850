import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Form from "react-bootstrap/Form"
import { useForm } from "react-hook-form"
import Cookies from "js-cookie"
import Card from "react-bootstrap/Card"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import { Button, CloseButton, InputGroup } from "react-bootstrap"
import "../Laboratories.css"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function LabsForm(props) {
	const navigate = useNavigate()

	const [name, setName] = useState("")
	const [officeNumber, setOfficeNumber] = useState("")
	const [address, setAddress] = useState("")
	const [productsAdded, setProductsAdded] = useState([]) // Productos elegidos para agregar
	const [otherProductsAdded, setOtherProductsAdded] = useState([]) // Productos elegidos para agregar
	const [productTagsAdded, setProductTagsAdded] = useState([]) // Tags elegidos para agregar
	const [otherProductTagsAdded, setOtherProductTagsAdded] = useState([]) // Tags elegidos para agregar

	const [allProducts, setAllProducts] = useState([])
	const [allProductsTags, setAllProductsTags] = useState([])
	const [allOtherProducts, setAllOtherProducts] = useState([])
	const [allOtherProductsTags, setAllOtherProductsTags] = useState([])

	const [productInputValue, setProductInputValue] = useState("") // Guarda el valor del input productos
	const [otherProductInputValue, setOtherProductInputValue] = useState("") // Guarda el valor del input otros productos
	const [categoryInputValue, setCategoryInputValue] = useState("") // Guarda el valor del input categorías

	const [allCategories, setAllCategories] = useState([]) // Categorias encontradas de la búsqueda
	const [categorySelected, setCategorySelected] = useState("") // Categoria Seleccionada

	const [showAllCategories, setShowAllCategories] = useState("") // Mostrar todas las categorías

	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")

  const {
		register,
		handleSubmit,
    setValue,
		formState: { errors },
	} = useForm()

	useEffect(() => {
		;(async () => {
      setName(props?.name || "")
      setOfficeNumber(props?.officeNumber || "")
      setAddress(props?.address || "")
      setProductsAdded(props?.productsAdded || [])
      setOtherProductsAdded(props?.otherProductsAdded || [])
      setProductTagsAdded(props?.productTagsAdded || [])
      setOtherProductTagsAdded(props?.otherProductTagsAdded || [])
      if(props?.name) setValue("name", props.name)
      if(props?.officeNumber) setValue("officeNumber", props.officeNumber)
      if(props?.address) setValue("address", props.address)
      
		})()
	}, [props])

	useEffect(() => {
		;(async () => {
			try {
				const token = Cookies.get("token")
				const responseCategories = await axios({
					method: "get",
					url: `${BACK_APP_URI}/categories`,
					headers: {
						Authorization: `${token}`,
					},
				})

				setAllCategories(responseCategories.data.categories)

				const responseTags = await axios({
					method: "get",
					url: `${BACK_APP_URI}/tags`,
					headers: {
						Authorization: `${token}`,
					},
				})
				setAllProducts(responseTags.data.tags.find(tag => tag.name === "-").products)
				setAllOtherProducts(responseTags.data.tags.find(tag => tag.name === "-").otherProducts)
				setAllProductsTags(responseTags.data.tags.filter(tag => tag.isProducts))
				setAllOtherProductsTags(responseTags.data.tags.filter(tag => tag.isProducts === false))
			} catch (error) {
				console.log(error)
			}
		})()
	}, [])


	const onSubmit = async () => {
		try {
			if (!name || !officeNumber || !address) {
				alert("Completa todos los campos")
				return
			}
			if (productsAdded.length === 0 && otherProductsAdded.length === 0 && productTagsAdded.length === 0 && otherProductTagsAdded.length === 0) {
				alert("Debe seleccionar al menos un producto o etiqueta")
				return
			}

			if (props.type === "addLabs") {
				const response = await axios.post(`${BACK_APP_URI}/laboratory/add`, {
					name: name,
					officeNumber: officeNumber,
					address: address,
					products: productsAdded,
					otherProducts: otherProductsAdded,
					tags: [...productTagsAdded, ...otherProductTagsAdded],
				})
				setAvisomsg("Laboratorio creado correctamente")
			} else if (props.type === "editLabs") {
				const response = await axios({
					method: "post",
					url: `${BACK_APP_URI}/laboratory/edit/${props.id}`,
					data: {
						name: name,
						officeNumber: officeNumber,
						address: address,
						products: productsAdded,
						otherProducts: otherProductsAdded,
						tags: [...productTagsAdded, ...otherProductTagsAdded],
					},
				})
        setAvisomsg("Laboratorio editado correctamente")
			}
			setVariable(true)
			setErrorBool(false)
			setTimeout(() => {
				setVariable(false)
				navigate("/laboratory")
			}, 1200)
		} catch (error) {
			setErrorBool(true)
			setVariable(true)
			setTimeout(() => {
				setVariable(false)
				setAvisomsg(error.response.data.msg)
			}, 1200)
			console.log("Error al agregar el laboratorio:")
		}
	}

	// Cuando se deselecciona una categoria
	const handleUnselectOtherProductCategory = () => {
		setCategorySelected("")
		setCategoryInputValue("")
		setOtherProductInputValue("")
	}

	const handleSelectAllOtherProductsFromCategory = () => {
		setOtherProductsAdded(prev => [
			...prev,
			...allOtherProducts.filter(product => product.category._id === categorySelected._id && !prev.find(prevProduct => prevProduct._id === product._id)),
		])
		setOtherProductTagsAdded(prev => [
			...prev,
			...allOtherProductsTags.filter(
				product => (product.category?._id || product.category) === categorySelected._id && !prev.find(prevProduct => prevProduct._id === product._id)
			),
		])
	}

	return (
		<div className="main-container">
			<div className="header-edit-lab">
				<h1>{props.type === "addLabs" ? "Agregar Laboratorio" : "Editar Laboratorio"}</h1>
				<div className="divider-lab" />
				<div className="content-edit-lab">
					<h2>Laboratorio</h2>
					<Form
						className="form-edit-lab"
						onSubmit={handleSubmit(onSubmit)}
					>
						<FloatingLabel
							label="Nombre"
							className="mb-3"
						>
							<Form.Control
								className="input-primary"
								placeholder="Nombre"
								value={name}
								type="text"
								{...register("name", {
									required: "Este campo es requerido",
								})}
								onChange={e => setName(e.target.value)}
							/>
							{errors.name && <span className="error">{errors.name.message}</span>}
						</FloatingLabel>
						<FloatingLabel
							label="Número de Sede"
							className="mb-3"
						>
							<Form.Control
								className="input-primary"
								placeholder="Número de Sede"
								value={officeNumber}
								type="number"
                min="1"
								{...register("officeNumber", {
									required: "Este campo es requerido",
								})}
								onChange={e => setOfficeNumber(e.target.value)}
							/>
							{errors.officeNumber && <span className="error">{errors.officeNumber.message}</span>}
						</FloatingLabel>
						<FloatingLabel
							label="Dirección"
							className="mb-3"
						>
							<Form.Control
								className="input-primary"
								placeholder="Dirección"
								value={address}
								type="text"
								{...register("address", {
									required: "Este campo es requerido",
								})}
								onChange={e => setAddress(e.target.value)}
							/>
							{errors.address && <span className="error">{errors.address.message}</span>}
						</FloatingLabel>

						{/* Buscador bioquimicos */}
						<Card
							className="input--primary mb-3"
							style={{ width: "100%" }}
						>
							<Card.Body>
								<Card.Text>Agregar Bioquimicos</Card.Text>
								<Form.Control
									className="input-primary"
									placeholder="Buscar Productos o Etiquetas..."
									type="text"
									style={{
										marginBottom: ".5rem",
									}}
									value={productInputValue}
									onChange={e => setProductInputValue(e.target.value)}
								/>
								<div
									style={{
										maxHeight: "400px",
										overflowY: "auto",
									}}
								>
									{allProducts.map(product => {
										if (productsAdded && productsAdded.some(productAdded => productAdded.name === product.name)) return
										if (productInputValue === "" || !product.name.toLowerCase().includes(productInputValue.toLowerCase())) return

										return (
											<InputGroup
												style={{
													flexWrap: "nowrap",
												}}
												key={product.name}
											>
												<InputGroup.Checkbox onChange={() => setProductsAdded(prev => [...prev, product])} />
												<InputGroup.Text
													style={{
														whiteSpace: "normal",
														width: "100%",
														justifyContent: "center",
													}}
												>
													{product.name}
												</InputGroup.Text>
											</InputGroup>
										)
									})}
									{allProductsTags.map(tag => {
										if (productTagsAdded && productTagsAdded.some(tagAdded => tagAdded.name === tag.name)) return
										if (productInputValue === "" || !tag.name.toLowerCase().includes(productInputValue.toLowerCase())) return

										return (
											<InputGroup
												style={{
													flexWrap: "nowrap",
												}}
												key={tag.name}
											>
												<InputGroup.Checkbox onChange={() => setProductTagsAdded(prev => [...prev, tag])} />
												<InputGroup.Text
													style={{
														whiteSpace: "normal",
														width: "100%",
														justifyContent: "center",
													}}
												>
													{tag.name}
												</InputGroup.Text>
											</InputGroup>
										)
									})}
								</div>
							</Card.Body>
						</Card>

						{/* Buscador Varios */}
						<Card
							className="input--primary mb-3"
							style={{ width: "100%" }}
						>
							<Card.Body>
								<Card.Text>Agregar Varios</Card.Text>

								{/* Mostrar buscador de categorias solo si no hay categoria seleccionada */}
								{categorySelected === "" && (
									<>
										<div
											style={{
												display: "flex",
												alignItems: "baseline",
												justifyContent: "center",
												gap: ".3rem",
											}}
										>
											<p>Ver todo</p>
											<Form.Check
												type="switch"
												id="extra-order-switch"
												checked={showAllCategories}
												onChange={() => setShowAllCategories(prev => !prev)}
											></Form.Check>
										</div>

										<Form.Control
											className="input-primary"
											placeholder="Buscar Categoría..."
											type="text"
											style={{
												marginBottom: ".5rem",
											}}
											value={categoryInputValue}
											onChange={e => setCategoryInputValue(e.target.value)}
										/>

										<div
											style={{
												maxHeight: "400px",
												overflowY: "auto",
											}}
										>
											{allCategories.map(category => {
												if (!showAllCategories && categoryInputValue === "") return

												if (!category.name.toLowerCase().includes(categoryInputValue.toLowerCase())) return

												return (
													<InputGroup
														style={{
															flexWrap: "nowrap",
														}}
														key={category._id}
													>
														<InputGroup.Checkbox onChange={() => setCategorySelected(category)} />
														<InputGroup.Text
															style={{
																whiteSpace: "normal",
																width: "100%",
																justifyContent: "center",
															}}
														>
															{category.name}
														</InputGroup.Text>
													</InputGroup>
												)
											})}
										</div>
									</>
								)}

								{/* Mostrar buscador de productos solo con categoria seleccionada */}
								{categorySelected !== "" && (
									<>
										<div
											style={{
												textAlign: "start",
												alignItems: "center",
												marginBottom: ".5rem",
												display: "flex",
												gap: ".2rem",
											}}
										>
											<div>Categoría: {categorySelected.name}</div>

											<CloseButton
												className="remove-button"
												onClick={handleUnselectOtherProductCategory}
											/>

											<Button
												size="sm"
												onClick={handleSelectAllOtherProductsFromCategory}
											>
												Seleccionar Todos
											</Button>
										</div>

										<Form.Control
											className="input-primary"
											placeholder="Buscar Productos o Etiquetas..."
											type="text"
											style={{
												marginBottom: ".5rem",
											}}
											value={otherProductInputValue}
											onChange={e => setOtherProductInputValue(e.target.value)}
										/>

										<div
											style={{
												maxHeight: "400px",
												overflowY: "auto",
											}}
										>
											{allOtherProducts.map(product => {
												if (otherProductsAdded && otherProductsAdded.some(otherProductAdded => otherProductAdded.name === product.name)) return

												if (
													otherProductInputValue === "" ||
													!product.name.toLowerCase().includes(otherProductInputValue.toLowerCase()) ||
													product.category._id !== categorySelected._id
												)
													return

												return (
													<InputGroup
														style={{
															flexWrap: "nowrap",
														}}
														key={product.name}
													>
														<InputGroup.Checkbox onChange={() => setOtherProductsAdded(prev => [...prev, product])} />
														<InputGroup.Text
															style={{
																whiteSpace: "normal",
																width: "100%",
																justifyContent: "center",
															}}
														>
															{product.name}
														</InputGroup.Text>
													</InputGroup>
												)
											})}
											{allOtherProductsTags.map(tag => {
												if (otherProductTagsAdded && otherProductTagsAdded.some(tagAdded => tagAdded.name === tag.name)) return

												if (
													otherProductInputValue === "" ||
													!tag.name.toLowerCase().includes(otherProductInputValue.toLowerCase()) ||
													(tag.category?._id || tag.category) !== categorySelected._id
												)
													return

												return (
													<InputGroup
														style={{
															flexWrap: "nowrap",
														}}
														key={tag.name}
													>
														<InputGroup.Checkbox onChange={() => setOtherProductTagsAdded(prev => [...prev, tag])} />
														<InputGroup.Text
															style={{
																whiteSpace: "normal",
																width: "100%",
																justifyContent: "center",
															}}
														>
															{tag.name}
														</InputGroup.Text>
													</InputGroup>
												)
											})}
										</div>
									</>
								)}
							</Card.Body>
						</Card>

						{/* Tabla agregados  */}

						{productsAdded.length || otherProductsAdded.length || productTagsAdded.length || otherProductTagsAdded.length ? (
							<Card
								className="input--primary"
								style={{
									width: "100%",
									maxHeight: "300px",
									overflow: "auto",
								}}
							>
								<Card.Header as="h6">Productos Agregados</Card.Header>
								<Card.Body>
									<Form.Group>
										{(productsAdded.length > 0 || productTagsAdded.length > 0) && <Form.Label>Bioquímicos:</Form.Label>}

										{productsAdded.length > 0 && (
											<>
												{productsAdded.map(product => {
													return (
														<div
															key={product.name}
															style={{
																display: "flex",
																textAlign: "start",
																justifyContent: "space-between",
																alignContent: "center",
																marginBottom: "0.5rem",
															}}
														>
															<CloseButton
																onClick={() => setProductsAdded(prev => prev.filter(prevProduct => prevProduct._id !== product._id))}
																className="remove-button"
															/>
															<div
																style={{
																	flexGrow: "1",
																}}
															>
																{product.name}
															</div>
														</div>
													)
												})}
											</>
										)}

										{productTagsAdded.length > 0 && (
											<>
												{productTagsAdded.map(tag => {
													return (
														<div
															key={tag.name}
															style={{
																display: "flex",
																textAlign: "start",
																justifyContent: "space-between",
																alignContent: "center",
																marginBottom: "0.5rem",
															}}
														>
															<CloseButton
																onClick={() => setProductTagsAdded(prev => prev.filter(prevTag => prevTag._id !== tag._id))}
																className="remove-button"
															/>
															<div
																style={{
																	flexGrow: "1",
																}}
															>
																{tag.name}
															</div>
														</div>
													)
												})}
											</>
										)}
									</Form.Group>

									{productsAdded.length > 0 && otherProductsAdded.length > 0 && <hr />}

									<Form.Group>
										{(otherProductsAdded.length > 0 || otherProductTagsAdded.length > 0) && <Form.Label>Varios:</Form.Label>}

										{otherProductsAdded.length > 0 && (
											<>
												{otherProductsAdded.map(otherProduct => {
													return (
														<div
															key={otherProduct.name}
															style={{
																display: "flex",
																textAlign: "start",
																justifyContent: "space-between",
																alignContent: "center",
																marginBottom: "0.5rem",
															}}
														>
															<CloseButton
																onClick={() => setOtherProductsAdded(prev => prev.filter(prevProduct => prevProduct._id !== otherProduct._id))}
																className="remove-button"
															/>
															<div
																style={{
																	flexGrow: "1",
																}}
															>
																{otherProduct.name}
															</div>
														</div>
													)
												})}
											</>
										)}

										{otherProductTagsAdded.length > 0 && (
											<>
												{otherProductTagsAdded.map(tag => {
													return (
														<div
															key={tag.name}
															style={{
																display: "flex",
																textAlign: "start",
																justifyContent: "space-between",
																alignContent: "center",
																marginBottom: "0.5rem",
															}}
														>
															<CloseButton
																onClick={() => setOtherProductTagsAdded(prev => prev.filter(prevTag => prevTag._id !== tag._id))}
																className="remove-button"
															/>
															<div
																style={{
																	flexGrow: "1",
																}}
															>
																{tag.name}
															</div>
														</div>
													)
												})}
											</>
										)}
									</Form.Group>
								</Card.Body>
							</Card>
						) : null}

						<div
							className="button-container-lab"
							style={{ margin: "5%" }}
						>
							<button
								className="btn-lab-text"
								style={{ width: "140px" }}
								children="Cancelar"
								onClick={() => {
									navigate("/laboratory")
								}}
							/>
							<button
								className="btn-lab"
								style={{ width: "140px" }}
								children="Guardar"
								type="submit"
								onClick={() => {}}
							/>
						</div>
					</Form>
				</div>
			</div>
			{variable && (
				<AnimacionSuccessful
					variable={variable}
					setVariable={setVariable}
					setErrorBool={setErrorBool}
					avisomsg={avisomsg}
					errorBool={errorBool}
				/>
			)}
		</div>
	)
}
