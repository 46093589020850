import React, { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function SendOrderModal({ showEmailModal, setShowEmailModal, allProviders }) {
	const [subject, setSubject] = useState("")
	const [message, setMessage] = useState("")
	const [file, setFile] = useState(null)
	const [selectedProvider, setSelectedProvider] = useState("")
	const [email, setEmail] = useState(""); // Nuevo estado para el email


	const handleSelectEmailProvider = e => {
		const selectedProviderId = e.target.value
		const selectedProviderObject = allProviders.find(provider => provider._id === selectedProviderId)

		setSelectedProvider(selectedProviderObject);
		setEmail(selectedProviderObject ? selectedProviderObject.email : ""); // Actualiza el email cuando se selecciona un proveedor
	}

	const handleFileChange = e => {
		const file = e.target.files[0]
		if (file) {
			const reader = new FileReader()
			reader.onloadend = () => {
				setFile(reader.result) // Guardar el archivo como Base64
			}
			reader.readAsDataURL(file)
		}
	}

	const sendEmail = async () => {
		try {
			let dataSend = {
				email: email,
				subject: subject,
				message: message,
				file: file,
			}
			const res = await fetch(`${BACK_APP_URI}/email/sendEmail`, {
				method: "POST",
				body: JSON.stringify(dataSend),
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			})
			if (res.status > 199 && res.status < 300) {
				alert("Envío exitoso !")
				setShowEmailModal(false)
			} else {
				alert("Hubo un error al enviar el pedido.")
			}
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<Modal
			size="lg"
			show={showEmailModal}
			onHide={() => setShowEmailModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton>
				<Modal.Title>Enviar pedido</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="formulario-orden">
						<Form.Label>Proveedor</Form.Label>
						<div className="select-container-modal desplegable">
							<Form.Select
								className=""
								onChange={handleSelectEmailProvider}
							>
								<option value="">Seleccionar Proveedor</option>
								{allProviders.map(provider => (
									<option
										key={provider._id}
										value={provider._id}
									>
										{provider.name}
									</option>
								))}
							</Form.Select>
						</div>
						<Form.Label>Dirección de Email</Form.Label>
						<Form.Control
							type="email"
							value={email} // Campo editable del email
							onChange={e => setEmail(e.target.value)} // Permitir edición del email
						/>
						<Form.Label>Asunto</Form.Label>
						<Form.Control
							type="text"
							placeholder="Ingresa el asunto aquí..."
							onChange={e => setSubject(e.target.value)}
						/>

						<Form.Label>Mensaje</Form.Label>
						<Form.Control
							as="textarea"
							placeholder="Ingresa tu mensaje aquí..."
							onChange={e => setMessage(e.target.value)}
						/>

						<Form.Label>Pedido</Form.Label>
						<Form.Group
							controlId="formFile"
							className="mb-3"
						>
							<Form.Control
								type="file"
								onChange={e => {
									handleFileChange(e)
								}}
							/>
						</Form.Group>
					</Form.Group>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button
					variant="primary"
					onClick={() => {
						sendEmail()
					}}
				>
					Enviar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
