import axios from "axios"
import React, { useEffect, useState } from "react"
import { Button, Card, FloatingLabel, Form, InputGroup } from "react-bootstrap"
import Cookies from "js-cookie"
import "./TagSelectorForm.css"
import arrowBack from "../../../Assets/Img/arrow-back-basic.svg"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"

export default function TagSelectorForm({ tagSelected, setTagSelected, isForBiochemicals }) {
	const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI
	const [isDisable, setIsDisable] = useState(true)
	const [isCreatingTag, setIsCreatingTag] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [input, setInput] = useState("")
	const [measure, setMeasure] = useState("")
	const [category, setCategory] = useState("")
	const [provider, setProvider] = useState("")
	const [showIncompleteFields, setShowIncompleteFields] = useState(false)
	const [showInvalidInput, setShowInvalidInput] = useState(false)
	const [reloadFetchedTags, setReloadFetchedTags] = useState(0)

	const [allTags, setAllTags] = useState([])
	const [allUnits, setAllUnits] = useState([])
	const [allProviders, setAllProviders] = useState([])
	const [allCategories, setAllCategories] = useState([])
	const [globalTag, setGlobalTag] = useState()

	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")

	useEffect(() => {
		;(async () => {
			const token = Cookies.get("token")
			const tagsFetched = await axios({
				method: "get",
				url: `${BACK_APP_URI}/tags`,
				headers: {
					Authorization: `${token}`,
				},
			})

			setGlobalTag(tagsFetched.data.tags.find(tag => tag.name === "-"))

			setAllTags(
				tagsFetched.data.tags
					.filter(tag => tag.name !== "-") // Elimina tag global
					.filter(tag => {
						// Filtra por tipo de producto
						if (isForBiochemicals) {
							return tag.isProducts
						} else {
							return !tag.isProducts
						}
					})
			)
		})()
	}, [reloadFetchedTags, tagSelected])

	useEffect(() => {
		;(async () => {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/unidades`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setAllUnits(result.data.units)
				})
			await axios
				.get(`${BACK_APP_URI}/proveedor`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setAllProviders(result.data.provider)
				})
			await axios
				.get(`${BACK_APP_URI}/categories`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setAllCategories(result.data.categories)
				})
		})()
	}, [])
	useEffect(() => {
		if (!globalTag) return
		if (tagSelected === null || tagSelected?.toString() === globalTag?._id.toString()) {
			setIsDisable(true)
		} else {
			setIsDisable(false)
		}
	}, [tagSelected, globalTag])

	const handleTagOnChange = tagId => {
		if (tagId === tagSelected) {
			setTagSelected(null)
		} else {
			setTagSelected(tagId)
		}
	}

	const handleDisableForm = () => {
		if (!isDisable) {
			setTagSelected(null)
		}
		setIsDisable(prev => !prev)
		setInput("")
		setMeasure("")
		setCategory("")
		setProvider("")
		setIsCreatingTag(false)
		setShowIncompleteFields(false)
		setShowInvalidInput(false)
	}

	const handleChangeInput = string => {
		if (/\s/g.test(string) || /[\.\;\,\-\_\:]/g.test(string)) {
			// Check si incluye espacio o caracteres especial
			setShowInvalidInput(true)
		} else {
			setShowInvalidInput(false)
		}
		setInput(string)
	}

	const handleReturnCreation = () => {
		setIsCreatingTag(false)
		setMeasure("")
		setCategory("")
		setProvider("")
	}

	const createTag = async () => {
		try {
			if (showInvalidInput) return

			if (measure === "" || input === "" || provider === "" || (!isForBiochemicals && category === "")) {
				setShowIncompleteFields(true)
				return
			} else {
				setShowIncompleteFields(false)
			}

			setIsLoading(true)
			const newTagData = await axios({
				method: "post",
				url: `${BACK_APP_URI}/tags/add`,
				data: {
					name: input,
					measure: measure,
					isProducts: isForBiochemicals,
					provider: provider,
					category: category === "" ? undefined : category
				},
			})
			setVariable(true)
			setErrorBool(false)
			setAvisomsg("Etiqueta creada exitosamente")
			setTimeout(() => {
				setVariable(false)
				setIsLoading(false)
			}, 1800)

			handleReturnCreation()
			setTagSelected(newTagData.data.tag._id)
			setReloadFetchedTags(prev => prev + 1)
		} catch (error) {
			console.log(error)
			setErrorBool(true)
			setVariable(true)
			setAvisomsg(error.response.data.msg)
			setTimeout(() => {
				setVariable(false)
				setIsLoading(false)
			}, 3200)
		}
	}

	return (
		<div>
			<Card
				className="input--primary mb-3"
				style={{ width: "100%" }}
			>
				<Card.Body>
					<Form.Switch className="flex-container">
						<button
							className="unset"
							style={{
								position: "absolute",
								left: "0",
								display: isCreatingTag ? "inline-block" : "none",
							}}
							type="button"
							onClick={handleReturnCreation}
						>
							<img
								src={arrowBack}
								alt="Flecha volver"
								style={{
									width: "26px",
								}}
							/>
						</button>

						<p style={{ marginBottom: "0" }}>Añadir Etiqueta</p>
						<Form.Check
							type="switch"
							id="extra-order-switch"
							checked={!isDisable}
							onChange={handleDisableForm}
							style={{ fontSize: "1.25rem" }}
						></Form.Check>
					</Form.Switch>

					{!isDisable && (
						<>
							<Form.Control
								className="input-primary"
								placeholder="Buscar Etiquetas..."
								type="text"
								style={{
									marginBlock: ".5rem",
								}}
								disabled={isDisable}
								value={input}
								onChange={e => handleChangeInput(e.target.value)}
							/>
							{!isCreatingTag ? (
								<>
									<div className="tag-add-name">
										{allTags.map(tag => {
											if (tag._id !== tagSelected && (input === "" || !tag.name.toLowerCase().includes(input.toLowerCase()))) return // Filtro por nombre
											return (
												<InputGroup
													style={{
														flexWrap: "nowrap",
													}}
													key={tag.name}
												>
													<InputGroup.Checkbox
														checked={tagSelected === tag._id}
														onChange={() => handleTagOnChange(tag._id)}
													/>
													<InputGroup.Text className="inputGroup-Text">{tag.name}</InputGroup.Text>
												</InputGroup>
											)
										})}
									</div>

									{!allTags.some(tag => tag.name === input) && (
										<button
											type="button"
											className="unset w-100"
											style={{
												marginTop: "8px",
											}}
											onClick={() => setIsCreatingTag(true)}
										>
											<InputGroup>
												<InputGroup.Text className="inputGroup-Text inputGroup-Text-Button">Crear Etiqueta {input && `"${input}"`}</InputGroup.Text>
											</InputGroup>
										</button>
									)}
								</>
							) : (
								<>
									<FloatingLabel
										label="Tipo de unidad"
										className="mb-3"
									>
										<Form.Select
											className="edit-prod-form-select"
											onChange={e => {
												setMeasure(e.target.value)
											}}
										>
											<option value="">Seleccionar</option>
											{allUnits.map(unit => {
												return (
													<option
														key={unit._id}
														value={unit._id}
													>
														{unit.name}
													</option>
												)
											})}
										</Form.Select>
									</FloatingLabel>

									<FloatingLabel
										label="Proveedor"
										className="mb-3"
									>
										<Form.Select
											className="edit-prod-form-select"
											onChange={e => {
												setProvider(e.target.value)
											}}
										>
											<option value="">Seleccionar</option>
											{allProviders.map(providerMapped => {
												return (
													<option
														key={providerMapped._id}
														value={providerMapped._id}
													>
														{providerMapped.name}
													</option>
												)
											})}
										</Form.Select>
									</FloatingLabel>


									{!isForBiochemicals && (
										<FloatingLabel
											label="Categoría"
											className="mb-3"
										>
											<Form.Select
												className="edit-prod-form-select"
												onChange={e => {
													setCategory(e.target.value)
												}}
											>
												<option value="">Seleccionar</option>
												{allCategories.map(categoryMapped => {
													return (
														<option
															key={categoryMapped._id}
															value={categoryMapped._id}
														>
															{categoryMapped.name}
														</option>
													)
												})}
											</Form.Select>
										</FloatingLabel>
									)}
									<Button
										type="button"
										className="w-100"
										onClick={createTag}
										disabled={isLoading}
									>
										Crear Etiqueta
									</Button>
									{showIncompleteFields && <div className="mt-3 color-red">Datos Incompletos</div>}
									{showInvalidInput && isCreatingTag && <div className="mt-3 color-red">Nombre Invalido</div>}
								</>
							)}
						</>
					)}
				</Card.Body>
			</Card>
			{variable && (
				<AnimacionSuccessful
					errorBool={errorBool}
					avisomsg={avisomsg}
					refreshPage={() => setVariable(false)}
				/>
			)}
		</div>
	)
}
