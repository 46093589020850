import axios from "axios";
import React from "react";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Dropdown from "react-bootstrap/Dropdown";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/esm/ToastHeader";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function MermaError() {
  const [allmerma, setAllMerma] = React.useState([]);
  const [mermaSelected, setMermaSelected] = React.useState(null);
  const [mermaPermitida,setMermaPermitida] = React.useState(100);

  const productsExceded = React.useMemo(() => {
    const arrayaux = [];
    allmerma.forEach((merma) => {
      const mermaPorcentaje = (
        100 -
        (merma.quantity / merma.product.quantity) * 100
      ).toFixed(0);
      if (mermaPorcentaje > mermaPermitida) {
        console.log(mermaPorcentaje, mermaPermitida)
        sessionStorage.setItem(`merma_${merma._id}`, mermaPorcentaje);
        arrayaux.push(merma);
      }
    });
    if (arrayaux.length<=0){
      console.log(arrayaux.length<0)
      console.log(mermaPermitida)
      sessionStorage.clear()
    }
    return arrayaux;
  }, [mermaPermitida]);

  const select = (merma) => {
    setMermaSelected(merma._id);
  };
  const deselect = () => {
    setMermaSelected(null);
  };

  React.useEffect(() => {
    async function getDataMerma() {
      await axios.get(`${BACK_APP_URI}/merma`).then((result) => {
        setAllMerma(result.data.data);
      });
    }
    sessionStorage.clear()
    getDataMerma();
  }, []);

  return (
    <div
      style={{
        maxWidth: "80%",
        flexDirection: "column",
        margin: "auto",
        marginTop: "3%",
      }}
    >
      <h1
        style={{
          textAlign: "left",
          marginBottom: "40px",
        }}
      >
        Merma Productos
      </h1>
      <input onChange={(e)=>{setMermaPermitida(parseInt(e.target.value))}} type="number" value={mermaPermitida} step="0"></input>
      {sessionStorage.length > 0 ? (
        <Alert
          style={{ display: "flex", justifyContent: "space-between" }}
          variant="danger"
        >
          Hay productos que exceden la merma permitida
          <Dropdown>
            <Dropdown.Toggle>Ver</Dropdown.Toggle>
            <Dropdown.Menu
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              {productsExceded.map((product) => {
                return (
                  <Dropdown.ItemText key={product._id}>
                    <Toast>
                      <ToastHeader closeButton={false}>
                        <strong>{product.product.name}</strong>
                      </ToastHeader>
                      <Toast.Body>
                        <Table responsive>
                          <thead style={{backgroundColor:"#02959881", color:"black"}}>
                            <tr>
                              <th>Cantidad</th>
                              <th>Stock Deposito</th>
                              <th>Merma</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{product.product.quantity}</td>
                              <td>{product.quantity}</td>
                              <td>
                                {(
                                  100 -
                                  (product.quantity /
                                    product.product.quantity) *
                                    100
                                ).toFixed(0)}
                                %
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Toast.Body>
                    </Toast>
                  </Dropdown.ItemText>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Alert>
      ) : null}

      <Table className="table-class" responsive>
        <thead onClick={deselect}>
          <tr>
            <th>Nombre</th>
            <th>Nro de Lote</th>
            <th>Cantidad</th>
            <th>Stock Deposito</th>
            <th>Merma</th>
          </tr>
        </thead>
        <tbody>
          {allmerma.map((merma) => (     
            <tr
              key={merma.product._id}
              onClick={() => select(merma)}
              className={
                merma.product._id === mermaSelected
                  ? "selected-row tr-table"
                  : "tr-table"
              }
            >
              <td>{merma.product.name}</td>
              <td className="number-th">{merma.product.lotNumber}</td>
              <td className="quantity-th">{merma.product.quantity}</td>
              <td className="quantity-th">{merma.quantity}</td>
              <td>
                {(
                  100 -
                  (merma.quantity / merma.product.quantity) * 100
                ).toFixed(0)}
                %
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        <Link to={`add`}>
          <Button className="btn--primary">Agregar</Button>
        </Link>
      </div>
    </div>
  );
}
