import React from "react";
import { Route, Routes } from "react-router-dom";
import NewUser from "./NewUser";
import EditUser from "./EditUser";
import UsersList from "./UsersList";
import "./Users.css";


//-----------RUTAS-------------
const Users = () => {
  return (
    <Routes>
        <Route exact path='/' element={<UsersList />} />
        <Route exact path='/add' element={<NewUser />} />
        <Route exact path="/edit/:id" element={<EditUser />} />
    </Routes>
  );
};

export default Users;
