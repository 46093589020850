import axios from "axios"
import React, { useState } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import TagSelectorForm from "../Components/TagSelectorForm"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function OtherProductsForm({ unitTypesOtherProduct, providers, categories, handleSuccessfulMessage, handleUnsuccessfulMessage }) {
	const navigate = useNavigate()

	const [name, setName] = useState("")
	const [quantity, setQuantity] = useState(0)
	const [unitType, setUnitType] = useState("")
	const [code, setCode] = useState("")
	const [brand, setBrand] = useState("")
	const [weightOrVolume, setWeightOrVolume] = useState(undefined)
	const [category, setCategory] = useState([])
	const [provider, setProvider] = useState("")
	const [tag, setTag] = useState(null) // Dejarlo en null para que el middleware funcione

	const [showWeightOrVolumeInput, setShowWeightOrVolumeInput] = useState(true)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const onSubmit = async () => {
		try {
			await axios({
				method: "post",
				url: `${BACK_APP_URI}/otros_productos/add`,
				data: {
					code: code,
					category: category,
					name: name,
					quantity: quantity,
					brand: brand,
					weightOrVolume: weightOrVolume,
					unitType: unitType,
					provider: provider,
					tag: tag,
				},
			})

			handleSuccessfulMessage("Producto creado con éxito")
		} catch (error) {
			handleUnsuccessfulMessage(`Error al crear producto. ${error?.response?.data?.msg}`)
		}
	}

	return (
		
		<Form className="form-target" onSubmit={handleSubmit(onSubmit)}>
			<div className="flex-column">
				<FloatingLabel label="Codigo de producto">
					<Form.Control
						className="input--primary"
						placeholder="Codigo"
						name="Codigo"
						type="text"
						{...register("code", {
							required: true,
							maxLength: 40,
						})}
						onChange={e => {
							setCode(e.target.value)
						}}
					/>
					{errors.code?.type === "required" && <p className="error">Campo requerido </p>}
					{errors.code?.type === "maxLength" && <p className="error">Cantidad minima de caracteres es de 40</p>}
				</FloatingLabel>

				<FloatingLabel label="Nombre">
					<Form.Control
						className="input--primary"
						placeholder="name"
						name="name"
						type="text"
						{...register("name", {
							required: true,
							maxLength: 40,
						})}
						onChange={e => {
							setName(e.target.value)
						}}
					/>
					{errors.name?.type === "required" && <p className="error">Campo requerido</p>}
					{errors.name?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
				</FloatingLabel>

				<FloatingLabel label="Cantidad">
					<Form.Control
						className="input--primary"
						placeholder="Cantidad"
						name="Cantidad"
						type="text"
						{...register("quantity", {
							required: true,
							maxLength: 40,
							pattern: /^[0-9]+$/, //Numeros
						})}
						onChange={e => {
							setQuantity(e.target.value)
						}}
					/>
					{errors.quantity?.type === "required" && <p className="error">Campo requerido </p>}
					{errors.quantity?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
					{errors.quantity?.type === "pattern" && <p className="error">Solo puede contener números</p>}
				</FloatingLabel>

				<FloatingLabel label="Tipo de unidad">
					<Form.Select
						className=""
						{...register("unitType", {
							required: true,
						})}
						value={unitType}
						onChange={e => {
							setUnitType(e.target.value)
							const selectedUnit = unitTypesOtherProduct.find(unit => unit._id === e.target.value)
							if (selectedUnit && (selectedUnit.name === "Unitarios" || selectedUnit.name === "Primaria")) {
								setShowWeightOrVolumeInput(false)
							} else {
								setShowWeightOrVolumeInput(true)
							}
						}}
					>
						<option
							value=""
							disabled
						>
							Seleccionar
						</option>
						{unitTypesOtherProduct.map(unitType => (
							<option
								key={unitType._id}
								value={unitType._id}
							>
								{unitType.name}
							</option>
						))}
					</Form.Select>
					{errors.unitType?.type === "required" && <p className="error">Campo requerido</p>}
				</FloatingLabel>

				{showWeightOrVolumeInput && (
					<FloatingLabel label="Peso o Volumen">
						<Form.Control
							className="input--primary"
							placeholder="Peso o Volumen"
							name="weightOrVolume"
							type="text"
							{...register("weightOrVolume", {
								required: true,
								maxLength: 40,
								pattern: /^[0-9]+$/, //Numeros
							})}
							onChange={e => {
								setWeightOrVolume(e.target.value)
							}}
						/>
						{errors.weightOrVolume?.type === "required" && <p className="error">Campo requerido </p>}
						{errors.weightOrVolume?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
						{errors.weightOrVolume?.type === "pattern" && <p className="error">Solo puede contener numeros</p>}
					</FloatingLabel>
				)}

				<FloatingLabel label="Categoría">
					<Form.Select
						className=""
						{...register("category", {
							required: true,
						})}
						value={category}
						onChange={e => {
							setCategory(e.target.value)
						}}
					>
						<option
							value=""
							disabled
						>
							Seleccionar
						</option>
						{categories.map(cat => (
							<option
								key={cat._id}
								value={cat._id}
							>
								{cat.name}
							</option>
						))}
					</Form.Select>
					{errors.category?.type === "required" && <p className="error">Campo requerido</p>}
				</FloatingLabel>

				<FloatingLabel label="Marca">
					<Form.Control
						className="input--primary"
						placeholder="brand"
						name="brand"
						type="text"
						{...register("brand", {
							maxLength: 40,
						})}
						onChange={e => {
							setBrand(e.target.value)
						}}
					/>
					{errors.brand?.type === "maxLength" && <p className="error">Cantidad máxima de caracteres es de 40</p>}
				</FloatingLabel>

				<FloatingLabel label="Proveedor">
					<Form.Select
						className=""
						{...register("provider", {
							required: true,
						})}
						value={provider}
						onChange={e => {
							if (e.target.value === "add") {
								navigate("/provider/add")
							}
							setProvider(e.target.value)
						}}
					>
						<option
							value=""
							disabled
						>
							Seleccionar
						</option>

						{providers.map(provider => (
							<option
								key={provider._id}
								value={provider._id}
							>
								{provider.name}
							</option>
						))}
						<option value="add">Agregar proveedor</option>
					</Form.Select>
					{errors.provider?.type === "required" && <p className="error">Campo requerido</p>}
				</FloatingLabel>


				<TagSelectorForm
					tagSelected={tag}
					setTagSelected={setTag}
					isForBiochemicals={false}
				/>

				<div
					className="button-container"
					style={{ marginTop: "8%", marginBottom: "5%" }}
				>
					<button
						children="Cancelar"
						type="submit"
						className="btn-adp-text"
						onClick={() => {
							navigate("/productos")
						}}
					/>
					<button
						children="Guardar"
						type="submit"
						className="btn-adp"
					/>
				</div>
			</div>
		</Form>
	)
}
