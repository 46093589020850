import React from "react"
import { Button, Modal } from "react-bootstrap"

export default function ProductsModal({ show, products, closeProductModal, isOtherProductsShowingInModal }) {
	return (
		<Modal
			show={show}
			onHide={closeProductModal}
		>
			<Modal.Header closeButton>
				<Modal.Title>
          {isOtherProductsShowingInModal ? "Productos Varios" : "Productos"}
        </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ul className="productsModal-ul">
					{products.map(product => {
						return <li className="productsModal-li" key={product._id}>{product.name}</li>
					})}
				</ul>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={closeProductModal}
				>
					Cerrar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
