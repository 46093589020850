import React, { useState } from "react"
import LabsForm from "../Components/LabsForm"

const AddLabs = () => {

	return <LabsForm type="addLabs"/>
	
}

export default AddLabs
