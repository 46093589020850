import React, { useEffect } from "react";
import { Page, Text, Document, StyleSheet, View } from "@react-pdf/renderer";

import axios from "axios";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const styles = StyleSheet.create({

    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        // margin: "auto", 
        flexDirection: "row"
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    }

});


const ProviderProductsToPdf = ({ products }) => {



   

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Codigo</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Producto</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Marca</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Proveedor</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Cantidad</Text>
                        </View>
                        
                    </View>
                </View>

                {products.map((product, index) => (
        <View style={styles.tableRow} key={index}> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{product.code}</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{product.name}</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{product.brand}</Text> 
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{product.provider.name}</Text> 
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{product.quantity}</Text> 
          </View>
          

        </View> 
          ))}
            </Page>
        </Document>
    )

}

export default ProviderProductsToPdf;