import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, /* clearErrors */ } from "react-hook-form";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Tooltip, Button } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import "./AddProvider.css";
import Card from "react-bootstrap/Card";
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful";

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const daysOfWeek = [
  "lunes",
  "martes",
  "miércoles",
  "jueves",
  "viernes",
  "sábado",
  "domingo",
];

const AddProvider = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [cuit, setCuit] = useState(0);
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState(0);
  const [email, setEmail] = useState("");
  const [deliveryDays, setDeliveryDays] = useState([]);
  const [deliveryDelay, setDeliveryDelay] = useState("");
  const [contacts, setContacts] = useState([{ name: "", phoneNumber: "" }]);
  const [variable, setVariable] = useState(false);
  const [errorBool, setErrorBool] = useState(false);
  const [avisomsg, setAvisomsg] = useState("");

  const handleDaySelection = (selectedDay) => {
    if (deliveryDays.includes(selectedDay)) {
      // Si el día ya estaba seleccionado, lo quitamos del array
      setDeliveryDays(deliveryDays.filter((day) => day !== selectedDay));
    } else {
      // Si el día no estaba seleccionado, lo agregamos al array
      setDeliveryDays([...deliveryDays, selectedDay]);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const onSubmit = () => {
    clearErrors('contacts');
    if (!deliveryDays.some((day) => day)) {
      alert("Selecciona al menos un día de entrega");
      return;
    }
    addProvider(
      name,
      cuit,
      number,
      email,
      address,
      deliveryDays,
      deliveryDelay,
      contacts
    );
  };

  const addProvider = (
    name,
    cuit,
    number,
    email,
    address,
    deliveryDays,
    deliveryDelay,
    contacts
  ) => {
    axios({
      method: "post",
      url: `${BACK_APP_URI}/proveedor/add`,
      data: {

        name: name,
        cuit: cuit,
        number: number,
        email: email,
        address: address,
        deliveryDays: deliveryDays,
        deliveryDelay: deliveryDelay,
        contacts: contacts,
      },
    })
      .then((data) => {
        setVariable(true);
        setErrorBool(false);
        setAvisomsg("Proveedor cargado con éxito");
        setTimeout(() => {
          setVariable(false);
          navigate("/provider");
        }, 1200);

      })
      .catch((data) => {
        setErrorBool(true);
        setVariable(true);
        setTimeout(() => {
          setVariable(false);
          setAvisomsg(data.response.data.msg);
        }, 1200);
        console.log(data.response.data.msg);
      });
  };

  const addContact = () => {
    const updatedContacts = [...contacts, { name: "", phoneNumber: "" }];
    setContacts(updatedContacts);
    setValue('contacts', updatedContacts);
  };

  const updateContact = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
    setValue("contacts", updatedContacts);
    clearErrors(`contacts.${index}.${field}`);
  };

  const removeContact = (index) => {
    const updatedContacts = [...contacts];
    updatedContacts.splice(index, 1);
    setContacts(updatedContacts);

    clearErrors(`contacts[${index}].name`);
    clearErrors(`contacts[${index}].phoneNumber`);
  };


  return (
    <div className="main-container">
      <div className="header-adp-tarjet"> 
        <h1>Agregar Proveedores</h1>
        <div className="dividers-adp" />
        <div className="content-adp-tarjet">
          
          <Form className="container-adp-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="left-column-adp">
          <div className="title-adp-card">
            <h2>Proveedor</h2>
            </div>
            <FloatingLabel label="Nombre" className="mb-3">
              <Form.Control
                className="input-adp"
                placeholder="name"
                name="name"
                type="text"
                {...register("name", {
                  required: true,
                  maxLength: 100,
                  pattern: /^[a-zA-ZÀ-ÿ0-9-\s]{1,40}$/, //letras y espacios, pueden llevas acentos
                })}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {errors.name?.type === "required" && (
                <p className="error">Campo Requerido </p>
              )}
              {errors.name?.type === "maxLength" && (
                <p className="error">Cantidad maxima de caracteres es de 100</p>
              )}
              {errors.name?.type === "pattern" && (
                <p className="error">Caracter inválido</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Cuit/Cuil" className="mb-3">
              <Form.Control
                className="input-adp"
                placeholder="Cuit/Cuil"
                name="cuit"
                type="text"
                {...register("cuit", {
                  required: true,
                  maxLength: 40,
                  minLength: 10,
                  pattern: /^[0-9]{1,}$/,
                })}
                onChange={(e) => {
                  setCuit(e.target.value);
                }}
              />
              {errors.cuit?.type === "required" && (
                <p className="error">Campo Requerido </p>
              )}
              {errors.cuit?.type === "maxLength" && (
                <p className="error">Cantidad max. de caracteres es de 40</p>
              )}
              {errors.cuit?.type === "minLength" && (
                <p className="error">Cantidad min. de caracteres es de 10</p>
              )}
              {errors.cuit?.type === "pattern" && (
                <p className="error">Solo puede contener numeros</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Numero" className="mb-3">
              <Form.Control
                className="input-adp"
                placeholder="numero"
                name="number"
                type="text"
                {...register("number", {
                  required: true,
                  maxLength: 40,
                  pattern: /^[0-9]{1,}$/,
                })}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
              {errors.number?.type === "required" && (
                <p className="error">Campo Requerido </p>
              )}
              {errors.number?.type === "maxLength" && (
                <p className="error">Cantidad max. de caracteres es de 40</p>
              )}
              {errors.number?.type === "pattern" && (
                <p className="error">Solo puede contener numeros</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Email" className="mb-3">
              <Form.Control
                placeholder="Email"
                className="input-adp"
                name="email"
                type="text"
                {...register("email", {
                  pattern: /^\S+@\S+$/i,
                })}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {errors.email?.type === "pattern" && (
                <p className="error">Respete formato de EMAIL</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Dirección" className="mb-3">
              <Form.Control
                placeholder="Direccion"
                className="input-adp"
                name="address"
                type="text"
                {...register("address", {
                  required: false,
                  maxLength: 80,
                })}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
              {errors.address?.type === "required" && (
                <p className="error">Campo Requerido </p>
              )}
              {errors.address?.type === "maxLength" && (
                <p className="error">Cantidad max. de caracteres es de 40</p>
              )}
              {errors.address?.type === "pattern" && (
                <p className="error">Solo puede contener letras</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Demora (dias)" className="mb-3">
              <Form.Control
                placeholder="Demora"
                className="input-adp"
                name="deliveryDelay"
                type="number"
                {...register("deliveryDelay", {
                  required: true,
                  maxLength: 30,
                  pattern: /^[0-9]{1,}$/,
                })}
                onChange={(e) => {
                  setDeliveryDelay(e.target.value);
                }}
              />
              {errors.deliveryDelay?.type === "required" && (
                <p className="error">Campo Requerido </p>
              )}
              {errors.deliveryDelay?.type === "pattern" && (
                <p className="error">Solo puede contener Numeros</p>
              )}
            </FloatingLabel>
            <div className="right-column-adp">
            
            
            <Card className="input-adp-card">
              <Card.Header as="h6">Dias de Entrega</Card.Header>
              <Card.Body>
                <Form.Group>
                  {daysOfWeek.map((day) => (
                    <Form.Check
                      name="deliveryDays"
                      key={day}
                      type="checkbox"
                      id={`day-${day}`}
                      label={day}
                      checked={deliveryDays.includes(day)}
                      onChange={() => handleDaySelection(day)}
                    />
                  ))}
                </Form.Group>
              </Card.Body>
            </Card>
            </div>
            
            <Card className="input-adp-card">
              <Card.Header as="h6">Contactos</Card.Header>
              <Card.Body>
                {contacts.map((contact, index) => (
                  <div key={index} className="contact-row-adp">
                    <div className="contact-field-adp">
                      <FloatingLabel label="Nombre" className="mb-3">
                        <Form.Control
                          value={contact.name}
                          className="input-adp-card"
                          placeholder="Nombre"
                          name={`contact-name-${index}`}
                          type="text"
                          {...register(`contacts.${index}.name`, {
                          })}
                          onChange={(e) => {
                            updateContact(index, "name", e.target.value);
                          }}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="contact-field-adp">
                      <FloatingLabel label="Teléfono" className="mb-3">
                        <Form.Control
                          value={contact.phoneNumber}
                          className="input--primary"
                          placeholder="Teléfono"
                          name={`contact-phone-${index}`}
                          type="text"
                          {...register(`contacts.${index}.phoneNumber`, {
                            maxLength: 30,
                            pattern: /^[0-9]{1,}$/,
                          })}
                          onChange={(e) => {
                            updateContact(index, "phoneNumber", e.target.value);
                          }}
                        />
                        {errors.contacts?.[index]?.phoneNumber?.type === "pattern" && (
                          <p className="error">Solo puede contener Numeros</p>
                        )}
                      </FloatingLabel>
                    </div>
                    <div className="contact-button-adp">
                    <Tooltip title="Eliminar contacto" arrow>
                          <Button   
                          type="button"
                          className="btn-adp-card"
                          onClick={() => removeContact(index)}>
                          <DeleteOutlineIcon className="icon-btn-adp"/>  
                          </Button>
                        </Tooltip>
                        <Tooltip title="Agregar" arrow>
                          <Button
                          type="button"
                          className="btn-adp-card"
                          onClick={addContact} >
                          <AddCircleOutlineIcon className="icon-btn-adp"/>
                        </Button>
                        </Tooltip>
                      
                    </div>
                  </div>
                ))}


              </Card.Body>
            </Card>
            </div>
            <div className="btn-container-adp" style={{ marginTop: "8%" }}>
              <button 
                children="Cancelar"
                type="submit"
                className="btn-adp-text"
                onClick={() => {
                  navigate("/provider");
                }}
              />
              <button
                children="Guardar"
                type="submit"
                className="btn-adp"
              />
            </div>
          </Form>
        </div>
        <div />
        {variable ? (
          <AnimacionSuccessful errorBool={errorBool} avisomsg={avisomsg} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AddProvider;
