import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import ProveedorIcon from '@mui/icons-material/LocalShipping';
import ProductsIcon from '@mui/icons-material/Category';
import UsersIcon from '@mui/icons-material/Group';
import StockIcon from '@mui/icons-material/Report';
import LaboratoriesIcon from '@mui/icons-material/Science';
import OrdersIcon from '@mui/icons-material/Receipt';
import Orders2Icon from '@mui/icons-material/Assignment';
import CategoryIcon from '@mui/icons-material/Category';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import TagIcon from '@mui/icons-material/Label';
import Cookies from "js-cookie";
import "./SideBar.css";

const drawerWidth = 240;

const SidebarItem = ({ to, icon, label, handleSelectItem }) => {
    const location = useLocation();
    return (
        <ListItem 
            button 
            component={NavLink} 
            to={to} 
            selected={location.pathname === to} 
            onClick={() => handleSelectItem(to)}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
        </ListItem>
    );
};

export default function SideBar({ isOpen, isMobile, setIsSidebarOpen }) {
    const [selectedItem, setSelectedItem] = useState("/home");
    const [isAdminOpen, setIsAdminOpen] = useState(false);
    const role = Cookies.get("role");

    const handleSelectItem = (link) => {
        setSelectedItem(link);
        if (isMobile) {
            setIsSidebarOpen(false); // Cierra el sidebar al seleccionar un item en mobile
        }
    };

    const toggleAdminMenu = () => {
        setIsAdminOpen(!isAdminOpen);
    };

    return (
        <div style={{ display: 'flex' }}>
            <Drawer
                variant={isMobile ? "temporary" : "persistent"}
                anchor="left"
                open={isOpen}
                onClose={() => setIsSidebarOpen(false)}
                style={{ width: isMobile ? '100%' : drawerWidth }}
                classes={{
                    paper: { width: isMobile ? '100%' : drawerWidth },
                }}
            >
                <div className="container-sidebar">
                    <List>
                    {(role === "sede" || role === "logistic") ? (
                            <>
                                <SidebarItem to="/home" icon={<HomeIcon className="sidebar-icon" />} label="Inicio" handleSelectItem={handleSelectItem} />
                                <Divider />
                                <SidebarItem to="/orders" icon={<OrdersIcon className="sidebar-icon" />} label="Pedidos de sedes" handleSelectItem={handleSelectItem} />
                            </>
                        ) : (
                            <>
                                <SidebarItem to="/home" icon={<HomeIcon className="sidebar-icon" />} label="Inicio" handleSelectItem={handleSelectItem} />
                                <Divider />
                                <ListItem button onClick={toggleAdminMenu}>
                                    <ListItemIcon>
                                        <AdminIcon className="sidebar-icon" />
                                    </ListItemIcon>
                                    <ListItemText primary="Administración" />
                                    {isAdminOpen ? <ExpandLess className="sidebar-icon" /> : <ExpandMore className="sidebar-icon" />}
                                </ListItem>
                                <Collapse in={isAdminOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <SidebarItem to="/provider" icon={<ProveedorIcon className="sidebar-icon" />} label="Proveedores" handleSelectItem={handleSelectItem} />
                                        <SidebarItem to="/tags" icon={<TagIcon className="sidebar-icon" />} label="Etiquetas" handleSelectItem={handleSelectItem} />
                                        <SidebarItem to="/productos" icon={<ProductsIcon className="sidebar-icon" />} label="Productos" handleSelectItem={handleSelectItem} />
                                        <SidebarItem to="/unidades" icon={<StockIcon className="sidebar-icon" />} label="Unidades" handleSelectItem={handleSelectItem} />
                                        <SidebarItem to="/laboratory" icon={<LaboratoriesIcon className="sidebar-icon" />} label="Laboratorios" handleSelectItem={handleSelectItem} />
                                        <SidebarItem to="/users" icon={<UsersIcon className="sidebar-icon" />} label="Usuarios" handleSelectItem={handleSelectItem} />
                                        <SidebarItem to="/categories" icon={<CategoryIcon className="sidebar-icon" />} label="Categorías" handleSelectItem={handleSelectItem} />
                                    </List>
                                </Collapse>
                                <Divider />
                                <SidebarItem to="/productos/stock" icon={<StockIcon className="sidebar-icon" />} label="Quiebre Stock" handleSelectItem={handleSelectItem} />
                                <SidebarItem to="/orders" icon={<OrdersIcon className="sidebar-icon" />} label="Pedidos de sedes" handleSelectItem={handleSelectItem} />
                                <SidebarItem to="/provider-order" icon={<Orders2Icon className="sidebar-icon" />} label="Pedidos a proveedores" handleSelectItem={handleSelectItem} />
                            </>
                        )}
                    </List>
                </div>
            </Drawer>
        </div>
    );
}
