import axios from "axios"
import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import { Button, Form, Nav, Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import { PDFDownloadLink } from "@react-pdf/renderer"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import StockProductToPdf from "./Components/StockProductsToPdf"
import { DateRangePicker } from "react-dates"
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import moment from "moment"
import provHistory from "../../Assets/Img/icon-back.svg"

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function ProviderOrderHistory() {
	const [providers, setProviders] = useState([])
	const [providerSelected, setProviderSelected] = useState("")
	const [orderHistoryData, setOrderHistoryData] = useState([])
	const [orderHistoryDataFiltered, setOrderHistoryDataFiltered] = useState([])

	const [tabSection, setTabSection] = useState("Bioquimicos")

	//---------------Date Range Picker---------------//

	moment.locale("es")
	const [startDate, setStartDate] = useState(undefined)
	const [endDate, setEndDate] = useState(undefined)
	const [focusedInput, setFocusedInput] = useState(null)

	const startDateTime = new Date(startDate).getTime()
	const endDateTime = new Date(endDate).getTime()

	useEffect(() => {
		; (async f => {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/historial_pedidos`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					const orderHistory = result.data.orderHistory

					setOrderHistoryData(orderHistory)
				})
		})()
	}, [])

	useEffect(() => {
		; (async f => {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/proveedor`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setProviders(result.data.provider.map(provider => provider.name))
				})
		})()
	}, [])

	useEffect(() => {
		const ordersFiltered = orderHistoryData
			.filter(order => {
				if (startDateTime && endDateTime) {
					return new Date(order.date).getTime() >= startDateTime && new Date(order.date).getTime() <= endDateTime
				} else return true
			})
			.filter(order => {
				if (tabSection === "Bioquimicos") {
					return order.isProducts
				} else {
					return !order.isProducts
				}
			})

		setOrderHistoryDataFiltered(ordersFiltered)
	}, [startDateTime, endDateTime, tabSection, orderHistoryData])

	const handleRemovedOrder = async order => {
		try {
			const token = Cookies.get("token")
			const res = await axios.delete(`${BACK_APP_URI}/historial_pedidos/delete/${order}`, {
				headers: {
					Authorization: `${token}`,
				},
			})
			console.log("Orden eliminada con éxito del historial")
			window.location.reload()
		} catch (error) {
			console.error("Error al eliminar la orden:", error)
		}
	}

	const handleTabChange = section => {
		setTabSection(section)
	}

	return (
		<div>
			<div className="main-container">
				<div className="header--container">
					<div className="provider-row">
						<div className="">
							<h1 className="title--header">Historial de pedidos</h1>
						</div>
						<Link
							to="/provider-order"
							className="link-prov-history"
						>
							<img
								src={provHistory}
								alt="Historial de pedidos"
								className="icon"
							/>
							Volver
						</Link>
					</div>
				</div>
				<div className="card--container">
					<div className="card--header--row ">

						<ToggleButtonGroup
							value={tabSection}
							exclusive
							onChange={(event, newTab) => handleTabChange(newTab)}
							aria-label="text alignment"
						>
							<ToggleButton
								value="Bioquimicos"
								aria-label="Bioquimicos"
								className={tabSection === "Bioquimicos" ? "active" : ""}
								sx={{
									width: '149px',
									height: '40px',
									flexShrink: 0,
								}}
							>
								Bioquímicos
							</ToggleButton>
							<ToggleButton
								value="Varios"
								aria-label="Varios"
								className={tabSection === "Varios" ? "active" : ""}
								sx={{
									width: '149px',
									height: '40px',
									flexShrink: 0,
								}}
							>
								Varios
							</ToggleButton>
						</ToggleButtonGroup>



						{/* Selector de fecha */}

						<DateRangePicker
							startDate={startDate}
							startDateId="your_unique_start_date_id"
							endDate={endDate}
							endDateId="your_unique_end_date_id"
							onDatesChange={({ startDate, endDate }) => {
								setStartDate(startDate)
								setEndDate(endDate)
							}}
							focusedInput={focusedInput}
							onFocusChange={focusedInput => setFocusedInput(focusedInput)}
							small
							displayFormat="DD/MM/YY"
							monthFormat="MMMM YYYY"
							weekDayFormat="dd"
							isOutsideRange={() => false}
						/>



						{/* Selector de proveedor */}
						<Form.Select
							className="select-provider"
							onChange={e => {
								setProviderSelected(e.target.options[e.target.selectedIndex].getAttribute("name"))
							}}
						>
							<option value="">Seleccionar Proveedor</option>
							{providers.map((provider, i) => (
								<option
									key={provider}
									value={provider}
									name={provider}
								>
									{provider}
								</option>
							))}
						</Form.Select>
					</div>


					<div className="table-container">
						<Table className="table-striped table-bordered table-hover">
							<thead responsive>
								<tr>
									<th>Nº de Pedido</th>
									<th>Emisor</th>
									<th>Fecha</th>
									<th>Proveedor</th>
									<th>Pedido</th>
									<th>Eliminar</th>
								</tr>
							</thead>
							<tbody>
								{orderHistoryDataFiltered.map(order => {
									// Filtrar por proveedor
									if (providerSelected && order.provider !== providerSelected) return

									const date = new Date(order.date)
									const day = date.getUTCDate()
									const month = date.getUTCMonth() + 1
									const year = date.getUTCFullYear()

									return (
										<tr key={order._id}>
											<td>{order.nroOrden}</td>
											<td>{order.sender.razonSocial}</td>
											<td>{`${day}/${month}/${year}`}</td>
											<td>{order.provider}</td>
											<td>
												<Button className="btn-link-pdf">
													<PDFDownloadLink
														document={
															<StockProductToPdf
																products={order.products}
																sender={order.sender}
																isBiochemicalsSection={tabSection === "Bioquimicos"}
															/>
														}
														fileName="RegistroOrden"
													>
														Descargar PDF
													</PDFDownloadLink>
												</Button>
											</td>
											<td>
											<Tooltip title="Eliminar pedido" arrow>
												<Button
													className="btn-delete"
													onClick={() => handleRemovedOrder(order._id)}
												>
													<DeleteOutlineIcon className="icon-delete"/>
												</Button>
												</Tooltip>
											</td>
										</tr>
									)
								})}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		</div>
	)
}
