import React from "react";
import { Route, Routes } from 'react-router-dom';
import { AllTags } from "./AllTags/AllTags";
import AddTags from "./AddTags/AddTags";
import EditTags from "./EditTags/EditTags";

const Tags = () => {
    return (
        <Routes>
            <Route exact path='/' element={<AllTags/>} />
            <Route exact path='/add' element={<AddTags/>} />
            <Route exact path='/edit/:id' element={<EditTags/>}/>
        </Routes>
    );
    }

export default Tags;