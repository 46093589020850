import React from 'react';
import { Route,Routes } from 'react-router-dom'
import AddProviders from './AddProviders/AddProviders';
import { AllProviders } from './AllProviders/AllProviders';
import EditProviders from './EditProviders/EditProviders';
import PrivateRoutes from "../PrivateRoutes/PrivateRoutes";

import './Providers.css'

const Providers = () => {

    return (
        <Routes>
          <Route element={<PrivateRoutes/>}>
            <Route exact path='/' element={<AllProviders/>}/>
            <Route exact path = '/add' element = {<AddProviders/>}/>
            <Route exact path='/edit/:id' element = {<EditProviders/>}/>
          </Route>
        </Routes>
    );
}

export default Providers;
