import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { set, useForm } from "react-hook-form";
import "./Users.css";
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/es';
import { DateRangePicker } from 'react-dates';
import { Card, InputGroup } from "react-bootstrap";
import BoxSelector from "./Components/BoxSelector";
import EditPasswordModal from "./Components/EditPasswordModal";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const EditUser = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState("");
  const [roleName, setRoleName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [extraOrder, setExtraOrder] = React.useState(false);
  const [biochemicalsNotAllowed, setBiochemicalsNotAllowed] = useState(false);
  
  // Buscador de categorías de varios
  const [categoriesFound, setCategoriesFound] = useState([]);
  const [categoriesAssigned, setCategoriesAssigned] = useState([]);

  // Buscador de categorías de varios
  const [labsFound, setLabsFound] = useState([]);
  const [labsAssigned, setLabsAssigned] = useState([]);
  
  
  // Date Range Picker
  moment.locale('es'); // Establece el idioma para Moment.js
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    async function getLabs() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/laboratory`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          setLabsFound(res.data.laboratories);
          setIsAdmin(res.data.userdata);
        });
    }
    getLabs();
  }, []);

  useEffect(() => {
    async function getDataCategories() {
      const token = Cookies.get("token");
      try {
        const response = await axios.get(`${BACK_APP_URI}/categories`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setCategoriesFound(response.data.categories);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    getDataCategories();
  }, []);

  //-------------POST------------
  const updateUser = (fname, lname, position, role, roleName, username, email, labsAssigned, extraOrder, categoriesAssigned, startDateOrder, endDateOrder, biochemicalsNotAllowed) => {
    axios({
      method: "put",
      url: `${BACK_APP_URI}/users/edit/${id}`,
      data: {
        fname: fname,
        lname: lname,
        position: position,
        role: role,
        roleName: roleName,
        username: username,
        email: email,
        labs: labsAssigned,
        extraOrder: extraOrder,
        categoriesAssigned: categoriesAssigned,
        startDateOrder: startDateOrder,
        endDateOrder: endDateOrder,
        biochemicalsNotAllowed: biochemicalsNotAllowed
      },
    }).then(function (response) {
      // alert("Usuario actualizado correctamente");
      navigate("/users");
    });
  };
  //-----------get----------------
  useEffect(() => {
    async function getUserData() {
      await axios.get(`${BACK_APP_URI}/users/${id}`).then(async (response) => {
        setFname(response.data.fname);
        setValue("fname", response.data.fname);
        setLname(response.data.lname);
        setValue("lname", response.data.lname);
        setPosition(response.data.position);
        setValue("position", response.data.position);
        setRole(response.data.role);
        setValue("role", response.data.role);
        setRoleName(response.data.roleName);
        setValue("roleName", response.data.roleName);
        setUsername(response.data.username);
        setValue("username", response.data.username);
        setEmail(response.data.email);
        setValue("email", response.data.email);
        setRole(response.data.roleName);
        setExtraOrder(response.data.extraOrder)
        setBiochemicalsNotAllowed(response.data.biochemicalsNotAllowed)
        setStartDate(moment(response.data.startDateOrder))
        setEndDate(moment(response.data.endDateOrder))

        // Procesa las categorías y labs
        const categories = await Promise.all(response.data.categoriesAssigned.map(category => getCategoryById(category)))
        setCategoriesAssigned(categories)
        
        setLabsAssigned(response.data.labs)

      });
    }
    getUserData();
  }, [id]);

  const getCategoryById = async (id) => {
    const response = await axios.get(`${BACK_APP_URI}/categories/${id}`)

    return response.data
  }

  const getLabById = async (id) => {
    const response = await axios.get(`${BACK_APP_URI}/laboratory/${id}`)

    return response.data
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = () => {
    updateUser(fname, lname, position, role, roleName, username, email, labsAssigned, extraOrder, categoriesAssigned, startDate, endDate, biochemicalsNotAllowed);
  };

  const handleRoleChange = (e) => {
    setRoleName(e.target.value);
    console.log(roleName)
  };

  const handleExtraOrderChange = (e) => {
    setExtraOrder(!extraOrder);

  }

  const handleDateRangeChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  return (
    <>
      <div className="container-user">
        <div className="header-user-add">
          <h1 className="text-user">Editar Usuario</h1>
          <div className="divider-user" />
          <div className="content-user-add ">
            <h1 className="title-user">Cuenta</h1>
            <Form className="user-form" onSubmit={handleSubmit(onSubmit)}>
              <FloatingLabel label="Nombre" className="mb-3">
                <Form.Control
                  className="input--primary"
                  placeholder="fname"
                  value={fname}
                  name="fname"
                  type="text"
                  {...register("fname", {
                    required: true,
                    maxLength: 40,
                    pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
                  })}
                  onChange={(e) => {
                    setFname(e.target.value);
                  }}
                />
                {errors.fname?.type === "required" && (
                  <p class="error">Campo requerido </p>
                )}
                {errors.fname?.type === "maxLength" && (
                  <p class="error">Cantidad minima de caracteres es de 40</p>
                )}
                {errors.fname?.type === "pattern" && (
                  <p class="error">Solo puede contener letras</p>
                )}
              </FloatingLabel>

              <FloatingLabel label="Apellido" className="mb-3">
                <Form.Control
                  placeholder="Apellido"
                  value={lname}
                  className="input--primary"
                  name="lname"
                  type="textarea"
                  {...register("lname", {
                    required: true,
                    maxLength: 40,
                    pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
                  })}
                  onChange={(e) => {
                    setLname(e.target.value);
                  }}
                />
                {errors.lname?.type === "required" && (
                  <p class="error">Campo requerido </p>
                )}
                {errors.lname?.type === "maxLength" && (
                  <p class="error">Cantidad max. de caracteres es de 40</p>
                )}
                {errors.lname?.type === "pattern" && (
                  <p class="error">Solo puede contener letras</p>
                )}
              </FloatingLabel>

              <FloatingLabel label="Cargo" className="mb-3">
                <Form.Control
                  className="input--primary"
                  placeholder="Cargo"
                  value={position}
                  name="position"
                  type="text"
                  {...register("position", {
                    required: false,
                    maxLength: 40,
                    pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
                  })}
                  onChange={(e) => {
                    setPosition(e.target.value);
                  }}
                />
                {errors.position?.type === "maxLength" && (
                  <p class="error">Cantidad max. de caracteres es de 40</p>
                )}
                {errors.position?.type === "pattern" && (
                  <p class="error">Solo puede contener letras</p>
                )}
              </FloatingLabel>

              <FloatingLabel label="Nombre de usuario" className="mb-3">
                <Form.Control
                  placeholder="Nombre de usuario"
                  className="input--primary"
                  name="username"
                  type="text"
                  value={username}
                  {...register("username", {
                    required: true,
                    pattern: /^[a-zA-Z0-9_-]+$/,
                    minLength: 3,
                  })}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
                {errors.username?.type === "required" && (
                  <p class="error">Campo requerido </p>
                )}
                {errors.username?.type === "pattern" && (
                  <p class="error">Caracteres inválidos</p>
                )}
                  {errors.username?.type === "minLength" && (
                  <p class="error">Cantidad minima de caracteres de 3</p>
                )}
              </FloatingLabel>



              <EditPasswordModal userId={id}/>

              <div>
                <h3 className="register-user-text">Tipo de usuario</h3>
              </div>
              <div className="register-user">
              <Form.Group className="">
                <div className="register-user">
                  <Form.Check
                    type="radio"
                    label="Administrador"
                    id="admin"
                    name="role"
                    value="admin"
                    {...register("role", {
                      required: true,
                    })}
                    onChange={handleRoleChange}
                    checked={roleName === "admin"}
                  />

                  <Form.Check
                    type="radio"
                    label="Usuario"
                    id="user"
                    name="role"
                    value="user"
                    {...register("role", {
                      required: true,
                    })}
                    onChange={handleRoleChange}
                    checked={roleName === "user"}
                  />

                  <Form.Check
                    type="radio"
                    label="Sede"
                    id="sede"
                    name="role"
                    value="sede"
                    {...register("role", {
                      required: true,
                    })}
                    onChange={handleRoleChange}
                    checked={roleName === "sede"}
                  />
                </div>
                {errors.role?.type === "required" && (
                  <p className="error">Campo requerido </p>
                )}
              </Form.Group>

              <Form.Group className="register-user" name="role">
                  <Form.Check
                    type="radio"
                    label="Logistica"
                    id="logistic"
                    name="role"
                    value="logistic"
                    {...register("role", {
                      required: true,
                    })}
                    onChange={handleRoleChange}
                    checked={roleName === "logistic"}

                  />
                  {errors.role?.type === "required" && (
                    <p class="error">Campo requerido </p>
                  )}
                </Form.Group>
                </div>
              {/* Date picker */}
              {(role === "sede" || roleName == "sede") && (
                <>
              <div  style={{marginTop: '2rem'}}
              className="d-flex justify-content-between">
                <p>Período de pedido</p>
                <DateRangePicker
                  startDate={startDate}
                  startDateId="your_unique_start_date_id"
                  endDate={endDate}
                  endDateId="your_unique_end_date_id"
                  onDatesChange={handleDateRangeChange}
                  focusedInput={focusedInput}
                  onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                  small
                  displayFormat="DD/MM/YY" // Define el formato de visualización
                  monthFormat="MMMM YYYY"
                  weekDayFormat="dd"

                />
              </div>

              <Form.Switch
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                  paddingLeft: "0"

                }}>
                <p>Pedido extra</p>
                <Form.Check
                  type="switch"
                  id="extra-order-switch"
                  checked={extraOrder === true}
                  onChange={handleExtraOrderChange}
                  style={{ fontSize: "1.25rem" }}
                >
                </Form.Check>

              </Form.Switch>

              <Form.Switch
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      paddingLeft: "0"

                    }}>
                    <p>Deshabilitar Bioquímicos</p>
                    <Form.Check
                      type="switch"
                      id="extra-order-switch"
                      checked={biochemicalsNotAllowed === true}
                      onChange={() => setBiochemicalsNotAllowed(prev => !prev)}
                      style={{ fontSize: "1.25rem" }}
                    >
                    </Form.Check>
                  </Form.Switch>

              {/*Buscador de categorías */}
              <BoxSelector allItems={categoriesFound} itemsAssigned={categoriesAssigned} title={"Seleccionar Categorías"} setItemsAssigned={setCategoriesAssigned}/>


              </>
              )}
              

              {(role === "sede"|| role === "logistic" || roleName === "sede" || roleName == "logistic") && (
                <>
                  <hr />                  
                  <BoxSelector allItems={labsFound} itemsAssigned={labsAssigned} title={"Seleccionar Laboratorios"} setItemsAssigned={setLabsAssigned}/>
                </>
              )}
              <div className="button-user" >
            <button
                children="Cancelar"
                type="submit"
                className="button-edit-text"
                onClick={() => {
                  navigate("/users");
                }}
              />
              <button
                children="Guardar"
                type="submit"
                className="button-edit"
                
              />
              </div>
            </Form>
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

export default EditUser;
