import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MermaError from './MermaError';
import AddMerma from './AgregarMerma';

const Merma = () => {
    return (
        <Routes>
            <Route exact path='/' element={<MermaError/>}/>
            <Route exact path='/add' element={<AddMerma/>}/>
        </Routes>
    );
}

export default Merma;
