import React, { useEffect, useState } from "react"
import { Button, Form, Pagination, Table, Nav } from "react-bootstrap"
import Cookies from "js-cookie"
import axios from "axios"
import { useParams, useNavigate } from "react-router-dom"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import "./EditOrder.css"
import delyIcon from "./../../../Assets/Img/delivery-truck-4439.svg"
import iconCheck from "./../../../Assets/Img/check-circle-svgrepo-com.svg"
import PdfIcon from "../../../Assets/Img/pdf17.svg"
import { PDFDownloadLink } from "@react-pdf/renderer"
import addIcon from "./../../../Assets/Img/iconAdd2.svg"
import AddProductToOrderModal from "./Components/AddProductToOrderModal"
import OrderPDF from "./SendedProductsPDF"
import ProductsTable from "./Components/ProductsTable"
import PendingProductsTable from "./Components/PendingProductsTable"
import { ALL_ORDER_STATES } from "../const"
import { TextField } from '@mui/material';


const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function EditOrder() {
	const navigate = useNavigate()
	const { id } = useParams()
	const [role, setRole] = useState("")
	const [orderData, setOrderData] = useState([])
	const [orderProducts, setOrderProducts] = useState([])
	const [allProducts, setAllProducts] = useState([])
	const [orderOtherProducts, setOrderOtherProducts] = useState([])
	const [allOtherProducts, setAllOtherProducts] = useState([])
	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")
	const [tabSection, setTabSection] = useState("") // biochemicals various
	const [search, setSearch] = useState("")
	const [pendingProducts, setPendingProducts] = useState([])
	const [pendingOtherProducts, setPendingOtherProducts] = useState([])
	const [startDateOrder, setStartDateOrder] = useState("")
	const [endDateOrder, setEndDateOrder] = useState("")
	const [offDate, setOffDate] = useState(true)
	const [comment, setComment] = useState("")
	const [texto, setTexto] = useState("")
	const maxCaracteres = 300 // Cambia la cantidad de caracteres max de Observaciones
	const [verifyComment, setVerifyComment] = useState(false)
	const [allLots, setAllLots] = useState()

	const [showAddProductModal, setShowAddProductModal] = useState(false)

	// Paginación de productos
	const [productPageNumber, setProductPageNumber] = useState(0)
	const [otherProductsPageNumber, setOtherProductsPageNumber] = useState(0)
	const productsPerPage = 10

	const productsStartIndex = productPageNumber * productsPerPage
	const productsEndIndex = productsStartIndex + productsPerPage

	const otherProductsStartIndex = otherProductsPageNumber * productsPerPage
	const otherProductsEndIndex = otherProductsStartIndex + productsPerPage

	const productsPageCount = orderProducts ? Math.ceil(orderProducts?.length / productsPerPage) : 0
	const otherProductsPageCount = orderOtherProducts ? Math.ceil(orderOtherProducts?.length / productsPerPage) : 0

	const currentDate = new Date().getTime()

	const startDateTime = new Date(startDateOrder).getTime()
	const endDateTime = new Date(endDateOrder).getTime()

	// Condición para fecha de pedido
	useEffect(() => {
		// eslint-disable-next-line no-mixed-operators
		if (currentDate >= startDateTime && currentDate <= endDateTime) {
			setOffDate(false)
		}

		//console.log("onDate? ", onDate);
		//console.log("Sede? ", role)
	}, [currentDate, startDateTime, endDateTime])

	function productAndTagInArray(productArr, tagArr, isProducts) {
		return [
			...productArr,
			...tagArr
				.filter(tag => (isProducts ? tag.data.isProducts : !tag.data.isProducts))
				.map(tag => {
					return { ...tag, isTag: true }
				}),
		]
	}

	// Información de orden y usuario
	useEffect(() => {
		; (async () => {
			try {
				const tokenCookies = Cookies.get("token")
				const roleCookies = Cookies.get("role")

				if (roleCookies === "logistic") {
					setRole("admin")
				} else {
					setRole(roleCookies)
				}

				// Información de la orden
				const orderResponse = await axios.get(`${BACK_APP_URI}/pedidos/${id}`, {
					headers: {
						Authorization: `${tokenCookies}`,
					},
				})
				setOrderData(orderResponse.data)

				setPendingProducts(productAndTagInArray(orderResponse.data.pendingProducts, orderResponse.data.pendingTags, true))

				setPendingOtherProducts(productAndTagInArray(orderResponse.data.pendingOtherProducts, orderResponse.data.pendingTags, false))

				// Ver que tipo de productos hay en la orden y configurar la navegación
				if (productAndTagInArray(orderResponse.data.products, orderResponse.data.tags, true).length) {
					setTabSection("biochemicals")
				} else {
					setTabSection("various")
				}

				// Información del usuario
				if (roleCookies === "sede" && orderResponse.data.status !== undefined) {
					const userResponse = await axios.get(`${BACK_APP_URI}/users/myProducts`, {
						headers: {
							Authorization: `${tokenCookies}`,
						},
					})
					setStartDateOrder(userResponse.data.user.startDateOrder)
					setEndDateOrder(userResponse.data.user.endDateOrder)
				}
			} catch (error) {
				console.log("Error al obtener información de la orden:", error)
			}
		})()
	}, [])

	// Buscador
	useEffect(() => {
		if (!orderData) return

		const products = orderData.products || []
		const otherProducts = orderData.otherProducts || []
		const tags = orderData.tags || []

		if (search === "" || !search) {
			setOrderProducts(productAndTagInArray(products, tags, true))
			setOrderOtherProducts(productAndTagInArray(otherProducts, tags, false))
			return
		}

		const filteredProducts = productAndTagInArray(products, tags, true).filter(product => product.data.name.toLowerCase().includes(search.toLowerCase()))

		const filteredOtherProducts = productAndTagInArray(otherProducts, tags, false).filter(product =>
			product.data.name.toLowerCase().includes(search.toLowerCase())
		)

		setOrderProducts(filteredProducts)

		setOrderOtherProducts(filteredOtherProducts)
	}, [search, orderData])

	//Manejar productos seleccionados
	const handlePendingProductsCheckbox = id => {
		if (role !== "sede") {
			if (orderProducts.some(product => product.data._id === id)) {
				setPendingProducts(prev => [...prev, orderProducts.find(product => product.data._id === id)])
				setOrderProducts(prev => prev.filter(product => product.data._id !== id))
			} else {
				setOrderProducts(prev => [...prev, pendingProducts.find(product => product.data._id === id)])
				setPendingProducts(prev => prev.filter(product => product.data._id !== id))
			}
		}
	}
	const handlePendingOtherProductsCheckbox = id => {
		if (role !== "sede") {
			if (orderOtherProducts.some(product => product.data._id === id)) {
				setPendingOtherProducts(prev => [...prev, orderOtherProducts.find(product => product.data._id === id)])
				setOrderOtherProducts(prev => prev.filter(product => product.data._id !== id))
			} else {
				setOrderOtherProducts(prev => [...prev, pendingOtherProducts.find(product => product.data._id === id)])
				setPendingOtherProducts(prev => prev.filter(product => product.data._id !== id))
			}
		}
	}

	const handleUpdateOrder = async () => {
		try {
			await axios.put(`${BACK_APP_URI}/pedidos/edit/${orderData._id}`, {
				products: orderProducts.filter(product => !product.isTag),
				otherProducts: orderOtherProducts.filter(product => !product.isTag),
				tags: [...orderProducts, orderOtherProducts].filter(product => product.isTag),
			})
			setVariable(true)
			setErrorBool(false)
			setAvisomsg("¡Pedido editado correctamente!")
		} catch (error) {
			setErrorBool(true)
			setVariable(true)
			setAvisomsg(error?.response?.data?.msg)

			setTimeout(() => {
				setVariable(false)
			}, 3200)

			console.log(".:Mensaje de error:.")
			console.log(error)
		}
	}

	// Rechazar el pedido
	const handleRejection = () => {
		if (verifyComment) {
			const orderId = id
			const codigo = orderData.codigoPedido + " -R"
			axios({
				method: "put",
				url: `${BACK_APP_URI}/pedidos/edit/${orderId}`,
				data: {
					email: orderData.userLabType,
					state: "Rechazado",
					codigoPedido: codigo,
					comment: texto,
				},
			})
				.then(data => {
					console.log(data)
					setVariable(true)
					setErrorBool(false)
					// setAvisomsg(data.data.msg);
					setTimeout(() => {
						setVariable(false)
						navigate("/orders")
					}, 1200)
				})
				.catch(error => {
					setErrorBool(true)
					setVariable(true)
					setAvisomsg(error?.response?.data?.msg)
					setTimeout(() => {
						setVariable(false)
					}, 3200)
					console.log(".:Mensaje de error:.")
					console.log(error.response.data.msg) // Mostrar el mensaje de error en la consola
				})
		} else {
			setAvisomsg("Debe agregar una observación")
		}
	}

	// Aprobar el pedido
	const handleApproved = async () => {
		try {
			const orderId = id
			const acceptedDateOrder = new Date()
			let orderCode
			let orderState

			let productsToApprove = []
			let otherProductsToApprove = []

			orderProducts.forEach(product => {
				if (!product?.quantityToSend || product?.quantityToSend === "") return
				if (product.isTag) {
					product.quantityToSend.forEach(item => {
						if (!item.quantityToSend || item.quantityToSend === "") return
						productsToApprove.push({
							data: item.id,
							quantityToSend: item.quantityToSend,
							currentQuantity: item.currentQuantity,
						})
					})
				} else {
					productsToApprove.push({
						data: product.data._id,
						currentQuantity: product.data.quantity,
						quantityToSend: product.quantityToSend,
					})
				}
			})

			orderOtherProducts.forEach(product => {
				if (!product?.quantityToSend || product?.quantityToSend === "") return
				if (product.isTag) {
					product.quantityToSend.forEach(item => {
						if (!item.quantityToSend || item.quantityToSend === "") return
						otherProductsToApprove.push({
							data: item.id,
							quantityToSend: item.quantityToSend,
							currentQuantity: item.currentQuantity,
						})
					})
				} else {
					otherProductsToApprove.push({
						data: product.data._id,
						quantityToSend: product.quantityToSend,
						currentQuantity: product.data.quantity,
					})
				}
			})

			if (
				(productsToApprove.length && productsToApprove.some(product => product.currentQuantity - product.quantityToSend < 0)) ||
				(otherProductsToApprove.length && otherProductsToApprove.some(product => product.currentQuantity - product.quantityToSend < 0))
			) {
				orderState = "Aceptado sin Stock"
				orderCode = orderData.nroPedido + " -AS"
			} else if (pendingOtherProducts.length > 0 || pendingProducts.length > 0) {
				orderCode = orderData.nroPedido + " -AP"
				orderState = "Aceptado"
			} else {
				orderCode = orderData.nroPedido + " -A"
				orderState = "Aceptado"
			}

			let pendingProductsToApprove = []
			let pendingOtherProductsToApprove = []
			let pendingTagsToApprove = []

			pendingProducts.forEach(product => {
				if (product?.isTag) {
					pendingTagsToApprove.push(product)
				} else {
					pendingProductsToApprove.push(product)
				}
			})

			pendingOtherProducts.forEach(product => {
				if (product?.isTag) {
					pendingTagsToApprove.push(product)
				} else {
					pendingOtherProductsToApprove.push(product)
				}
			})

			// Editar la orden
			const orderResponse = await axios.put(`${BACK_APP_URI}/pedidos/edit/${orderId}`, {
				state: orderState,
				products: productsToApprove,
				otherProducts: otherProductsToApprove,
				tags: [],
				acceptedAt: acceptedDateOrder,
				pendingProducts: pendingProductsToApprove,
				pendingOtherProducts: pendingOtherProductsToApprove,
				pendingTags: pendingTagsToApprove,
				codigoPedido: orderCode,
				comment: texto,
			})

			console.log(orderResponse.data)
			setVariable(true)
			setErrorBool(false)
			setAvisomsg("¡Pedido aceptado!")
		} catch (error) {
			setErrorBool(true)
			setVariable(true)
			setAvisomsg(error?.response?.data?.msg)

			setTimeout(() => {
				setVariable(false)
			}, 3200)

			console.log(".:Mensaje de error:.")
			console.log(error)
		}
	}

	const handleDeliveredOrder = async () => {
		const products = orderProducts
		const otherProducts = orderOtherProducts
		try {
			//Descuento de Cantidades en Stock
			if (products.length) {
				for (const product of products) {
					// Actualizar el stock total de los productos que seran enviados
					const productLots = product.data.lots.sort((a, b) => new Date(a.expireDate) - new Date(b.expireDate))
					let indice = 0
					do {
						if (product.quantityToSend >= productLots[indice].quantity) {
							product.quantityToSend -= productLots[indice].quantity
							await axios.delete(`${BACK_APP_URI}/lotes/delete/${productLots[indice]._id}`)
							if (product.quantityToSend === 0) {
								break
							} else {
								indice++
							}
						} else {
							const updatedQuantity = productLots[indice].quantity - product.quantityToSend
							await axios.put(`${BACK_APP_URI}/lotes/edit/${productLots[indice]._id}`, {
								quantity: updatedQuantity,
								product: product.data._id,
							})
							product.quantityToSend = 0
						}
					} while (product.quantityToSend > 0 && indice < productLots.length)
				}
			} else {
				console.log("No hay productos bioquímicos seleccionados")
			}

			if (otherProducts.length) {
				// Lógica para descontar el stock de los productos varios
				for (const otherProduct of otherProducts) {
					const otherProductData = otherProduct.data

					otherProductData.quantity -= otherProduct.quantityToSend

					await axios.put(`${BACK_APP_URI}/otros_productos/edit/${otherProduct.data._id}`, otherProductData)
				}
			} else {
				console.log("No hay productos varios seleccionados")
			}

			//Creacion de nueva Orden si hay Productos Pendientes
			const codigoSplit = orderData.codigoPedido.split(" -")

			if (pendingProducts.length > 0 || pendingOtherProducts.length > 0) {
				function formatData(arr, isForTags) {
					return arr
						.filter(product => (isForTags ? product.isTag : !product.isTag))
						.map(item => {
							return { id: item.data._id, quantityRequested: item.quantityRequested }
						})
				}

				axios({
					method: "post",
					url: `${BACK_APP_URI}/pedidos/add`,
					data: {
						labOrigin: orderData.labOrigin._id,
						username: orderData.userLabType.username,
						products: formatData(pendingProducts, false),
						otherProducts: formatData(pendingOtherProducts, false),
						productTags: formatData(pendingProducts, true),
						otherProductTags: formatData(pendingOtherProducts, true),
						isFromAdmin: true,
						comment: "Esta orden ha sido generada a partir de los productos pendientes de la orden número: " + codigoSplit[0],
					},
				})
			}

			// //Actualizacion de Estado y Codigo de Orden
			const orderId = id
			const orderStatus = "Enviado"

			let codigo
			if (codigoSplit[1] === "A") {
				codigo = codigoSplit[0] + " -E"
			} else if (codigoSplit[1] === "AS") {
				codigo = codigoSplit[0] + " -ES"
			} else if (codigoSplit[1] === "AP") {
				codigo = codigoSplit[0] + " -E"
			}

			const orderResponse = await axios.put(`${BACK_APP_URI}/pedidos/edit/${orderId}`, {
				state: orderStatus,
				deliveredAt: new Date(),
				codigoPedido: codigo,
				pendingProducts: [],
				pendingOtherProducts: [],
			})
			console.log(`Su pedido ha sido ${orderStatus}!`, orderResponse.data)
			setVariable(true)
			setErrorBool(false)
			setAvisomsg("¡Pedido enviado!")
		} catch (error) {
			setErrorBool(true)
			setVariable(true)
			setAvisomsg(error?.response?.data?.msg)
			setTimeout(() => {
				setVariable(false)
			}, 3200)
			console.log(".:Mensaje de error:.")
			console.log(error)
		}
	}

	const handleReceivedOrder = async () => {
		const receivedDate = new Date()

		try {
			const orderId = id
			const orderStatus = "Recibido"
			const codigoSplit = orderData.codigoPedido.split(" -")
			let codigo
			if (codigoSplit[1] === "E") {
				codigo = codigoSplit[0] + " -RC"
			} else if (codigoSplit[1] === "ES") {
				codigo = codigoSplit[0] + " -RI"
			} else if (codigoSplit[1] === "EP") {
				codigo = codigoSplit[0] + " -RP"
			}

			const orderResponse = await axios.put(`${BACK_APP_URI}/pedidos/edit/${orderId}`, {
				state: orderStatus,
				receivedAt: receivedDate,
			})

			console.log(`Su pedido ha sido ${orderStatus}!`, orderResponse.data)
			setVariable(true)
			setErrorBool(false)
			setAvisomsg("¡Pedido recibido!")
			/* setTimeout(() => {
		  setVariable(false);
		  navigate('/orders');
		}, 1200); */
		} catch (error) {
			setErrorBool(true)
			setVariable(true)
			setAvisomsg(error?.response?.data?.msg)

			setTimeout(() => {
				setVariable(false)
			}, 3200)

			console.log(".:Mensaje de error:.")
			console.log(error.response.data.msg)
		}
	}

	const handleRefreshPage = () => {
		setVariable(false)
		navigate("/orders")
		console.log("hello!")
	}

	const handleInputChange = event => {
		const inputValue = event.target.value

		// Limitar la longitud del texto
		if (inputValue.length <= maxCaracteres) {
			setTexto(inputValue)
		}

		if (inputValue.length !== 0) {
			setVerifyComment(true)
		}
		console.log(verifyComment)
	}

	return (
		<>
			<div className="main-container">
				<div className="header--container">

					<div className="order--header-cont">
						<div className="order--header-row">
							<h1 className="order--title">Carga pedido</h1>

							{/* Tab */}
							<div className="provider-cont">
								{offDate && tabSection === "various" && role === "sede" && <p className="text-danger">Fuera del rango de pedido</p>}
								<Form
									className="all-tag-form"
									onSubmit={e => e.preventDefault()}
								>
									<Form.Group controlId="exampleForm.ControlInput1">
										<Form.Control
											className="all-tag-search"
											type="text"
											value={search}
											onChange={e => {
												setSearch(e.target.value)
												setProductPageNumber(0)
												setOtherProductsPageNumber(0)
											}}
											placeholder="Buscar..."
										/>
									</Form.Group>
								</Form>
							</div>
						</div>
					</div>
					<div className="header-edit-order">
						<div className="order-title-edit">
							<b>Sede:</b> {`${orderData?.labOrigin?.name || ""}`}
						</div>

						<div className="order-title-edit">
							<b>Número de Orden: </b> {`${orderData?.codigoPedido || ""}`}
						</div>

					</div>

				</div>

				<div className="card--container">
					<div className="table-container-new">
						<div className="table-striped table-bordered table-hover">
							{/* Bioquímicos */}
							{tabSection === "biochemicals" &&
								(orderProducts?.length ? (
									<ProductsTable
										orderItems={orderProducts}
										role={role}
										setOrderItems={setOrderProducts}
										itemsStartIndex={productsStartIndex}
										itemsEndIndex={productsEndIndex}
										disabled={orderData.state}
										handleItems={handlePendingProductsCheckbox}
										orderState={orderData?.state}
										isProductTable
									/>
								) : (
									<div>Productos bioquímicos no encontrados.</div>
								))}

							{/* Varios */}
							{tabSection === "various" &&
								(orderOtherProducts?.length > 0 ? (
									<ProductsTable
										orderItems={orderOtherProducts}
										role={role}
										setOrderItems={setOrderOtherProducts}
										itemsStartIndex={otherProductsStartIndex}
										itemsEndIndex={otherProductsEndIndex}
										disabled={orderData.state}
										handleItems={handlePendingOtherProductsCheckbox}
										orderState={orderData?.state}
									/>
								) : (
									<div>Productos varios no encontrados</div>
								))}
						</div>

						{tabSection === "biochemicals" && role !== "sede" && pendingProducts.length > 0 && (
							<PendingProductsTable
								pendingItems={pendingProducts}
								disabled={orderData.state}
								handleItems={handlePendingProductsCheckbox}
							/>
						)}
						{tabSection === "various" && role !== "sede" && pendingOtherProducts.length > 0 && (
							<PendingProductsTable
								pendingItems={pendingOtherProducts}
								disabled={orderData.state}
								handleItems={handlePendingOtherProductsCheckbox}
							/>
						)}
					</div>
					{role !== "sede" && !orderData?.state && (
						<div className="button-carga">
							<Button
								onClick={() => setShowAddProductModal(true)}
								className="button-carga-order"
							>
								Añadir Productos
							</Button>
						</div>
					)}

					{/* Botones de paginación depende de la sección */}
					{tabSection === "biochemicals" ? (
						<div className="pagination-order pagination-all-tag">
							<Pagination>
								<Pagination.Prev
									onClick={() => setProductPageNumber(productPageNumber > 0 ? productPageNumber - 1 : 0)}
									disabled={productPageNumber === 0}
								/>
								{Array.from({ length: productsPageCount }).map((_, index) => (
									<Pagination.Item
										className="pagination-item"
										key={index}
										active={index === productPageNumber}
										onClick={() => setProductPageNumber(index)}
									>
										{index + 1}
									</Pagination.Item>
								))}
								<Pagination.Next
									onClick={() => setProductPageNumber(productPageNumber < productsPageCount - 1 ? productPageNumber + 1 : productsPageCount - 1)}
									disabled={productPageNumber === productsPageCount - 1}
								/>
							</Pagination>
						</div>
					) : (
						<div className="pagination-order pagination-all-tag">
							<Pagination>
								<Pagination.Prev
									onClick={() => setOtherProductsPageNumber(otherProductsPageNumber > 0 ? otherProductsPageNumber - 1 : 0)}
									disabled={otherProductsPageNumber === 0}
								/>
								{Array.from({ length: otherProductsPageCount }).map((_, index) => (
									<Pagination.Item
										className="pagination-item"
										key={index}
										active={index === otherProductsPageNumber}
										onClick={() => setOtherProductsPageNumber(index)}
									>
										{index + 1}
									</Pagination.Item>
								))}
								<Pagination.Next
									onClick={() => setOtherProductsPageNumber(otherProductsPageNumber < otherProductsPageCount - 1 ? otherProductsPageNumber + 1 : otherProductsPageCount - 1)}
									disabled={otherProductsPageNumber === otherProductsPageCount - 1}
								/>
							</Pagination>
						</div>
					)}
					<div className="order--header">
						{/* Observaciones */}
						{role === "sede" ? (
							orderData?.state ? (
								orderData.comment ? (
									<div className="cont-observaciones">
										<h3>
											Observaciones: <small>{role}</small>
										</h3>
										<p>{orderData.comment}</p>
									</div>
								) : null
							) : null
						) : orderData?.state ? (
							orderData.comment ? (
								<div className="cont-observaciones">
									<h3>
										Observaciones: <small>{role}</small>
									</h3>
									<p>{orderData.comment}</p>
								</div>
							) : null
						) : (
							<div className="cont-observaciones">
								<h4>Observaciones: </h4>
								<TextField
									name="comments"
									id="comment"
									multiline
									rows={4}
									fullWidth
									value={texto}
									onChange={e => handleInputChange(e)}
									placeholder="Para rechazar un pedido, la observación es obligatoria..."
									inputProps={{ maxLength: maxCaracteres }}
									helperText={`Caracteres restantes: ${maxCaracteres - texto.length}/${maxCaracteres}`}
								/>
							</div>
						)}
						<div className="edit-order-btn">
							{/* ORDER BUTTONS */}
							<div className="content-card-order">
								{role === "sede" ? (
									<>
										<Button
											className="btn btn--primary"
											children="Actualizar pedido"
											onClick={handleUpdateOrder}
											disabled={ALL_ORDER_STATES.includes(orderData?.state)}
										/>
									</>
								) : (
									/* ADMIN */
									<div className="admin-button-cont">
										<div className="flex-gap-2">
											<Button
												className="btn btn-primary"
												children="Aceptar pedido"
												onClick={handleApproved}
												disabled={ALL_ORDER_STATES.includes(orderData?.state) || (!orderProducts?.length && !orderOtherProducts?.length)}
											/>
											<Button
												className="btn btn-danger"
												children="Rechazar pedido"
												onClick={handleRejection}
												disabled={ALL_ORDER_STATES.includes(orderData?.state)}
											/>
										</div>
									</div>
								)}
								{/* Envío el pedido */}
								{role !== "sede" ? (
									<Button
										className="btn send-order-button ml-2"
										onClick={handleDeliveredOrder}
										disabled={
											["Enviado", "Recibido", "Rechazado", undefined].includes(orderData?.state) ||
											(orderProducts.length && orderProducts.some(product => product.data.quantity - product.quantityToSend < 0)) ||
											(orderOtherProducts.length && orderOtherProducts.some(product => product.data.quantity - product.quantityToSend < 0))
										}
									>
										<span style={{ paddingRight: "5px" }}>Enviar Pedido</span>
										<img
											style={{ width: "25px", height: "25px" }}
											src={delyIcon}
											alt="enviar pedido"
										/>
									</Button>
								) : (
									<>
										{orderData?.state !== undefined && (
											<Button
												className="btn send-order-button"
												onClick={handleReceivedOrder}
												disabled={["Aceptado", "Aceptado sin Stock", "Rechazado", "Recibido"].includes(orderData?.state)}
											>
												<span style={{ paddingRight: "5px" }}>Orden Recibida</span>
												<img
													style={{ width: "20px", height: "20px" }}
													src={iconCheck}
													alt="pedido recibido"
												/>
											</Button>
										)}
									</>
								)}


								{role !== "sede" && (
									<div className="flex-center mt-18">
										<Button
											className="btn--primary--abm btn btn--primary width-auto height-auto flex py-2"
											disabled={false}
										>
											<PDFDownloadLink
												document={
													<OrderPDF
														orderInfo={{ nroPedido: orderData?.nroPedido, lab: orderData?.labOrigin?.name }}
														orderProduct={orderProducts}
														orderOtherProducts={orderOtherProducts}
														allProducts={allProducts}
														allOtherProducts={allOtherProducts}
														orderState={orderData?.state ? orderData?.state.toUpperCase() : "PENDIENTE"}
														tabSection={tabSection}
													/>
												}
												fileName={`Pedido ${orderData?.state ? orderData?.state : "Pendiente"} N° ${orderData?.nroPedido}`}
											>
												<div className="flex-gap-2 color-white no-underline">
													Descargar PDF
													<img
														className="icon_buttons"
														src={PdfIcon}
														alt="camera capture"
													/>
												</div>
											</PDFDownloadLink>
										</Button>

									</div>

								)}
							</div>
						</div>

					</div>
				</div>
				<AddProductToOrderModal
					show={showAddProductModal}
					setShow={setShowAddProductModal}
					tabSection={tabSection}
					lab={orderData?.labOrigin}
					productsFromOrder={orderProducts}
					otherProductsFromOrder={orderOtherProducts}
					orderId={id}
				/>

				{/* Animación de Éxito */}
				{variable ? (
					<AnimacionSuccessful
						errorBool={errorBool}
						avisomsg={avisomsg}
						refreshPage={handleRefreshPage}
					/>
				) : (
					<></>
				)}

			</div >
		</>
	)
}
