import React, { useState, useEffect } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import Cookies from "js-cookie"
import LabsForm from "../Components/LabsForm"

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

const EditLabs = () => {
	const { id } = useParams()

	const [name, setName] = useState("")
	const [officeNumber, setOfficeNumber] = useState("")
	const [address, setAddress] = useState("")
	const [productsAdded, setProductsAdded] = useState([]) // Productos elegidos para agregar
	const [otherProductsAdded, setOtherProductsAdded] = useState([]) // Productos elegidos para agregar
	const [productTagsAdded, setProductTagsAdded] = useState([]) // Tags elegidos para agregar
	const [otherProductTagsAdded, setOtherProductTagsAdded] = useState([]) // Tags elegidos para agregar

	useEffect(() => {
		const token = Cookies.get("token")
		;(async () => {
			try {
				const response = await axios.get(`${BACK_APP_URI}/laboratory/${id}`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				const { data } = response

				setName(data.name)
				setOfficeNumber(data.officeNumber)
				setAddress(data.address)
				setProductsAdded(data.products)
				setOtherProductsAdded(data.otherProducts)
				setProductTagsAdded(data.tags.filter(tag => tag.isProducts))
				setOtherProductTagsAdded(data.tags.filter(tag => !tag.isProducts))
			} catch (error) {
				console.error("Error al obtener los datos del laboratorio:", error)
			}
		})()

	}, [])

	return <LabsForm type="editLabs" id={id} name={name} officeNumber={officeNumber} address={address} productsAdded={productsAdded} otherProductsAdded={otherProductsAdded} productTagsAdded={productTagsAdded} otherProductTagsAdded={otherProductTagsAdded}/>
}

export default EditLabs
