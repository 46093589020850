import React, { useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import "./NewProviderProduct.css"
import IconAdd from "../../../Assets/Img/icon_add_c.svg";
import { Button } from "react-bootstrap"
import { Tooltip } from "@mui/material";


const NewProviderProduct = ({ onAddData, isBiochemicalsSection, allUnits }) => {
	const [code, setCode] = useState("")
	const [ubn, setUbn] = useState("")
	const [product, setProduct] = useState("")
	const [brand, setBrand] = useState("")
	const [qty, setQty] = useState("")
	const [measure, setMeasure] = useState("")

	const [showFillDataAdvice, setShowFillDAtaAdvice] = useState(false)

	//---------------------Handlers-------------------------------------------

	const handleCode = e => setCode(e.target.value)
	const handleUbn = e => setUbn(e.target.value)
	const handleProduct = e => setProduct(e.target.value)
	const handleBrand = e => setBrand(e.target.value)
	const handleQty = e => setQty(e.target.value)

	//Función Agregar
	const handleAdd = () => {
		if (!code || (isBiochemicalsSection && !ubn) || !product || (isBiochemicalsSection && !brand) || !qty || !measure) {
			setShowFillDAtaAdvice(true)
			return
		}

		onAddData(Math.random() + 1, code, ubn, product, brand, qty, measure)
		setShowFillDAtaAdvice(false)
		setCode("")
		setUbn("")
		setProduct("")
		setBrand("")
		setQty("")
		setMeasure("")
	}

	return (
		<div className="mx-auto">
			<Form className="d-flex flex-row flex-wrap bg-light rounded shadow align-items-center">
				<Form.Group
					className="number-input my-3 mx-3"
					controlId="Code"
				>
					<Form.Control
						type="text"
						placeholder="Código"
						onChange={handleCode}
						value={code}
					/>
				</Form.Group>

				<Form.Group
					className="text-input my-3 mx-3"
					controlId="Name"
				>
					<Form.Control
						type="text"
						placeholder="Nombre del producto"
						onChange={handleProduct}
						value={product}
					/>
				</Form.Group>
				{isBiochemicalsSection && (
					<>
						<Form.Group
							className="number-input my-3 mx-3"
							controlId="Nbu"
						>
							<Form.Control
								type="number"
								placeholder="Nbu"
								onChange={handleUbn}
								value={ubn}
							/>
						</Form.Group>
						<Form.Group
							className="text-input my-3 mx-3"
							controlId="Brand"
						>
							<Form.Control
								type="text"
								placeholder="Marca del producto"
								onChange={handleBrand}
								value={brand}
							/>
						</Form.Group>
					</>
				)}

				<Form.Group
					className=" number-input my-3 mx-3"
					controlId="Quantity"
				>
					<Form.Control
						type="number"
						placeholder="Cantidad"
						onChange={handleQty}
						value={qty}
					/>
				</Form.Group>

				<Form.Select
					className=" number-input my-3 mx-3 w-25"
					onChange={e => setMeasure(e.target.value)}
				>
					<option value="">Seleccionar Medida</option>
					{allUnits.map(unit => (
						<option
							key={unit._id}
							value={unit.name}
						>
							{unit.name}
						</option>
					))}
				</Form.Select>
				<Form.Group
					className="my-3 mx-3"
					controlId="Add"
				>
					<Tooltip title="Agregar" arrow>
					<Button
						className="btn-new-provider"
						onClick={handleAdd}
					>
						<img
							className="icon-new-provider"
							src={IconAdd}
							alt="agregar"
						></img>
					</Button>
					</Tooltip>
				</Form.Group>
			</Form>
			{showFillDataAdvice && (
				<div
					className="mt-2"
					style={{
						color: "red",
					}}
				>
					Completar campos vacíos primero
				</div>
			)}
		</div>
	)
}

export default NewProviderProduct
