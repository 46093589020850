import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

import Users from "../Users/Users";
import Products from "../Products/Products";
import Providers from "../Providers/Providers";
import Main from "../Main/Main";
import NewUser from "../Users/NewUser";
import Merma from "../MermaError/merma";
import Units from "../Units/Units";
import Upload from "../camera/camera";
import Laboratories from "../Laboratories/Laboratories";
import Orders from "../Order/Orders";

const PrivateRoutes = () => {
  const token = Cookies.get("token") || "";
  const role = Cookies.get("role")
  if (token) {
    if(role === "sede"){
      console.log(role)
      return (
        <Outlet>
          <Orders />
        </Outlet>
      );
    }else{
      return (
        <Outlet>
          <Users />
          <Products />
          <Providers />
          <Main />
          <NewUser />
          <Merma />
          <Units />
          <Laboratories />
          <Upload />
        </Outlet>
      );
    }
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoutes;
