import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "./Navbar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Admin from "../../Assets/Img/admin.png";
import User from "../../Assets/Img/user ico.png";
import { jwtDecode } from 'jwt-decode';
import isoLogo from "../../Assets/Img/Innovis-iso-white.png"
import logoTipo from "../../Assets/Img/Innovis-logo-white.png"
import Logout from "../../Assets/Img/icon-logout.svg"


const decodeToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (error) {
    console.error("Error al decodificar el token:", error);
    return null;
  }
};

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userRole, setUserRole] = useState(null);
  const [userName, setUserName] = useState(null);
  const token = Cookies.get("token");


  useEffect(() => {
    if (token) {
      const decodedToken = decodeToken(token);
      if (decodedToken) {
        const role = decodedToken.role;
        const name = decodedToken.user;
        setUserRole(role);
        setUserName(name);
      } else {
        setUserRole(null);
        setUserName(null);
      }
    } else {
      setUserRole(null);
      setUserName(null);
    }
  }, [token]);

  const handleLogout = () => {
    Cookies.remove("token");
    setUserRole(null);
    setUserName(null);
    navigate("/");
  };



  const renderUserCircle = () => {
    if (userRole && userName) {
      const isAdmin = userRole === "admin";
      const imageUrl = isAdmin ? Admin : User;

      return (
        <>
          
          <Dropdown className="dropdown">
            <Dropdown.Toggle className="custom-dropdown-toggle"
              id="dropdown-basic"
              as="span"
            >
              <img src={imageUrl} alt="User" className="user-image" />
              
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-navbar" >
              <Dropdown.Item >
                <p>{userName}</p>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item  onClick={handleLogout}>
              <img src={Logout} alt="Logout" className="icon-navbar" /> Cerrar sesión
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
        
      );
    }
    return null;
  };

  return (
    <div className="Navbar">
      <div >
        <img src={isoLogo} alt="User" className="iso-logo"></img>
        <img src={logoTipo} alt="User" className="logo-size"></img>
      </div>

      <div className="navbar-content">{renderUserCircle()}</div>
    </div>
  );
};

export default Navbar; 
