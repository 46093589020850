import React, { useEffect, useState } from "react"
import "./AllTags.css"
import axios from "axios"
import Table from "react-bootstrap/Table"
import AbmButtons from "../../ABMbotones/abmButtons"
import Cookies from "js-cookie"
import { Form, Nav, Pagination } from "react-bootstrap"
import IconAdd from "../../../Assets/Img/icon_add.svg";
import { Link } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import ProductDeleteHandlerModal from "../../Products/AllProducts/Components/ProductDeleteHandlerModal"





const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI


export const AllTags = () => {
    const [allTags, setAllTags] = useState([])
    const [tagsFilteredByName, setTagsFilteredByName] = useState([])
    const [tagSelected, setTagSelected] = useState(null)
    const [isAdmin, setIsAdmin] = useState("")
    const [tabSection, setTabSection] = useState("Bioquimicos")
    const [search, setSearch] = useState("")
    const [pageCountBio, setPageCountBio] = useState(0)
    const [pageCountVarios, setPageCountVarios] = useState(0)


    // Pagination
    const [pageNumber, setPageNumber] = useState(0)
    const tagsPerPage = 10
    const pagesVisited = pageNumber * tagsPerPage

	// Modal de manejo de eliminacion
	const [showProductDeleteHandlerModal, setShowProductDeleteHandlerModal] = useState(false)
	const [ordersThatContainsProduct, setOrdersThatContainsProduct] = useState([])

    const changePage = selected => {
        setPageNumber(selected)
        setTagSelected(null)
    }


    const select = unit => {
        setTagSelected(unit._id)
    }
    const deselect = () => {
        setTagSelected(null)
    }


    function refreshPage() {
        window.location.reload(false)
    }


    useEffect(() => {
        async function getData() {
            const token = Cookies.get("token")
            await axios
                .get(`${BACK_APP_URI}/tags`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                })
                .then(result => {
                    setAllTags(result.data.tags)
                    setTagsFilteredByName(result.data.tags)
                    setPageCountBio(Math.ceil(result.data.tags.filter(tag => tag?.isProducts === true).length / tagsPerPage))
                    setPageCountVarios(Math.ceil(result.data.tags.filter(tag => tag?.isProducts === false).length / tagsPerPage))
                })
        }
        getData()
    }, [])


    useEffect(() => {
        const filteredTagsByName = allTags.filter(tag => {
            return tag.name.toLowerCase().includes(search.toLowerCase())
        })
        setTagsFilteredByName(filteredTagsByName)
        setPageCountBio(Math.ceil(filteredTagsByName.filter(tag => tag?.isProducts === true).length / tagsPerPage))
        setPageCountVarios(Math.ceil(filteredTagsByName.filter(tag => tag?.isProducts === false).length / tagsPerPage))


    }, [search])


    const deleteTag = async () => {
        try {
            const res = await axios({
                method: "get",
                url: `${BACK_APP_URI}/pedidos/containsProduct/${tagSelected}?typeOfProduct=tag`,
            })
    
            if (res.data.containsProduct) {
                setOrdersThatContainsProduct(res.data.orders)
                setShowProductDeleteHandlerModal(true)
            } else {

                axios({
                    method: "delete",
                    url: `${BACK_APP_URI}/tags/delete/${tagSelected}`,
                })
                refreshPage()
            }

        } catch (error) {
            alert("No se pudo eliminar la etiqueta")
            console.log(error)
        }
    }


    const handleTabChange = (newTab) => {
        if (newTab !== null) {
            setTabSection(newTab);
            changePage(0);
        }
    };


    return (
        <div className="main-container">
            <div className="header-all-tag">
                <div className="all-tag-row">
                    <h1 className="title--header">Etiquetas</h1>

                    <Form className="all-tag-form" onSubmit={(e) => e.preventDefault()}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control
                                className="all-tag-search"
                                type="text"
                                value={search}
                                onChange={e => {
                                    setSearch(e.target.value)
                                    setPageNumber(0)
                                }}
                                placeholder="Buscar..."
                            />
                        </Form.Group>
                    </Form>
                </div>
                <div className="card--container">
                    <div className="card--header--row">
                        <ToggleButtonGroup
                            value={tabSection}
                            exclusive
                            onChange={(event, newTab) => handleTabChange(newTab)}
                            aria-label="text alignment"
                        >
                            <ToggleButton
                                value="Bioquimicos"
                                aria-label="Bioquimicos"
                                className={tabSection === "Bioquimicos" ? "active" : ""}
                                sx={{
                                    width: '149px',
                                    height: '40px',
                                    flexShrink: 0,
                                }}
                            >
                                Bioquímicos
                            </ToggleButton>
                            <ToggleButton
                                value="Varios"
                                aria-label="Varios"
                                className={tabSection === "Varios" ? "active" : ""}
                                sx={{
                                    width: '149px',
                                    height: '40px',
                                    flexShrink: 0,
                                }}
                            >
                                Varios
                            </ToggleButton>
                        </ToggleButtonGroup>


                       <div className="hidden-mobile">
                        <div className="btn-filter ">
                            <h3 className="text-filter">Nueva etiqueta</h3>
                            <Link to={`add`}>
                                <button className="icon-filter">
                                    <img
                                        src={IconAdd}
                                        alt="icono"
                                    />
                                </button>
                            </Link>
                        </div>
                        </div>
                    </div>
                    <div className="table-container">

                        <Table className="table-striped table-bordered table-hover ">
                            <thead responsive onClick={deselect}>
                                <tr>
                                    <th className="title-th">Nombre</th>
                                    <th className="unitcode-th title-th">Medida</th>
                                    

                                </tr>
                            </thead>
                            <tbody>
                                {tagsFilteredByName
                                    .filter(tag => {
                                        if (tabSection === "Bioquimicos") {
                                            return tag.isProducts === true
                                        } else {
                                            return tag.isProducts === false
                                        }
                                    })
                                    .slice(pagesVisited, pagesVisited + tagsPerPage)
                                    .map(tag => (
                                        <tr
                                            key={tag._id}
                                            onClick={() => select(tag)}
                                            className={tag._id === tagSelected ? "selected-row tr-table" : "tr-table"}
                                        >
                                            <td >{tag.name}</td>
                                            <td className="unitcode-th">{tag.measure.name}</td>
                                           
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>

                </div>
            </div>
            
            <Pagination className="pagination-all-tag">
                <Pagination.Prev
                    onClick={() => changePage(pageNumber > 0 ? pageNumber - 1 : 0)}
                    disabled={pageNumber === 0}
                />
                {Array.from({ length: tabSection === "Bioquimicos" ? pageCountBio : pageCountVarios }).map((_, index) => (
                    <Pagination.Item
                        className="pag-item-all-tag"
                        key={index}
                        active={index === pageNumber}
                        onClick={() => changePage(index)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next
                    onClick={() => changePage(pageNumber < (tabSection === "Bioquimicos" ? pageCountBio : pageCountVarios) - 1 ? pageNumber + 1 : pageNumber)}
                    disabled={pageNumber === (tabSection === "Bioquimicos" ? pageCountBio : pageCountVarios) - 1}
                />
            </Pagination>
            <AbmButtons
                item={tagSelected}
                abm={"hola"}
                funcion={deleteTag}
                role={isAdmin}
            />{" "}
            {/* Modal de manejo de eliminacion de producto */}
			<ProductDeleteHandlerModal
				ordersThatContainsProduct={ordersThatContainsProduct}
				showProductDeleteHandlerModal={showProductDeleteHandlerModal}
				closeProductDeleteHandlerModal={() => setShowProductDeleteHandlerModal(false)}
			/>
        </div>
    )
}


