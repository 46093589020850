import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import IconAdd from "../../Assets/Img/icon-add-b.png"

import FloatingLabel from "react-bootstrap/FloatingLabel"
import Form from "react-bootstrap/Form"
import Table from "react-bootstrap/Table"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Cookies from "js-cookie"
import { Tooltip } from "@mui/material"
import { useForm } from "react-hook-form"
import CategoryRow from "./Components/CategoryRow"
import AnimacionSuccessful from "../AnimacionSuccessful/animacionSuccessful"

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export const AllCategories = () => {
	const [AllCat, setAllCat] = useState([])
	const [catSelected, setCatSelected] = useState(null)
	const [isAdmin, setIsAdmin] = useState("")
	const [name, setName] = useState("")
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)

  const [showAnimacionSuccessful, setShowAnimacionSuccessful] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")

	const handleCloseModal = () => setShowModal(false)
	const handleShowModal = () => setShowModal(true)

	const select = unit => {
		setCatSelected(unit._id)
	}
	const deselect = () => {
		setCatSelected(null)
	}

	function refreshPage() {
		window.location.reload(false)
	}

	useEffect(() => {
		async function getDataCategories() {
			const token = Cookies.get("token")
			try {
				const response = await axios.get(`${BACK_APP_URI}/categories`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				setAllCat(response.data.categories)
				setIsAdmin(response.data.userdata)
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}

		getDataCategories()
	}, [])

	const deleteCategories = id => {
		handleCloseModal()
		axios({
			method: "delete",
			url: `${BACK_APP_URI}/categories/delete/${catSelected}`,
		}).then(refreshPage())
	}

	const addCategory = name => {
		axios({
			method: "post",
			url: `${BACK_APP_URI}/categories/add`,
			data: {
				name: name,
			},
		}).then(refreshPage())
	}

  const handleEditCategory = async (id, newName) => {
    try {
      const response = await axios({
        method: "put",
        url: `${BACK_APP_URI}/categories/edit/${id}`,
        data: {
          name: newName
        }
      })

      setErrorBool(false)
      setShowAnimacionSuccessful(true)
      setAvisomsg("Categoría editada correctamente")
      setTimeout(() => [
        setShowAnimacionSuccessful(false)
      ], 2000)
      return response.data
    } catch (error) {
      console.log(error)
      setErrorBool(true)
      setShowAnimacionSuccessful(true)
      setAvisomsg("Ocurrió un error al editar la categoría")
      setTimeout(() => [
        setShowAnimacionSuccessful(false)
      ], 2000)
    }

  }

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const onSubmit = () => {
		addCategory(name)
	}

	return (
		<div className="main-container">
			<div className="header--container">
				<div className="header-row">
					<h1 className="title--header">Categorías</h1>
					<Form
						className="category-form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<FloatingLabel
							label="Añadir Categoría"
							className="category"
						>
							<Form.Control
								className="category"
								placeholder="name"
								name="name"
								type="text"
								{...register("name", {
									required: true,
									maxLength: 40,
									pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevar acentos
								})}
								onChange={e => {
									setName(e.target.value)
								}}
							/>
							{errors.name?.type === "required" && <p className="error">Campo requerido</p>}
							{errors.name?.type === "maxLength" && <p className="error">Cantidad mínima de caracteres es de 40</p>}
							{errors.name?.type === "pattern" && <p className="error">Solo puede contener letras</p>}
						</FloatingLabel>
						<Tooltip
							title="Agregar"
							arrow
						>
							<div className="btn-filter">
								<button
									className="icon-filter"
									type="submit"
									onClick={() => {}}
								>
									<img
										src={IconAdd}
										alt="Agregar"
									/>
								</button>
							</div>
						</Tooltip>
					</Form>
				</div>
			</div>
			<div className="card--container">
				<div className="table-container">
					<Table className="table-striped table-bordered table-hover ">
						<thead responsive onClick={deselect}>
							<tr>
								<th className="title-th">Nombre de categoría</th>
								<th className="title-th">Editar</th>
								<th className="title-th">Eliminar</th>
							</tr>
						</thead>
						<tbody>
							{AllCat.map(category => (
								<CategoryRow
                  key={category._id}
									category={category}
									catSelected={catSelected}
									handleShowModal={handleShowModal}
                  setCatSelected={setCatSelected}
                  handleEditCategory={handleEditCategory}
								/>
							))}
						</tbody>
					</Table>{" "}
				</div>
			</div>
			<Modal
				show={showModal}
				onHide={handleCloseModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Confirmación</Modal.Title>
				</Modal.Header>
				<Modal.Body>¿Está seguro de que desea eliminar esta categoría?</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={handleCloseModal}
					>
						No
					</Button>
					<Button
						variant="danger"
						onClick={() => deleteCategories(catSelected)}
					>
						Sí
					</Button>
				</Modal.Footer>
			</Modal>
      {showAnimacionSuccessful && (
					<AnimacionSuccessful
						errorBool={errorBool}
						avisomsg={avisomsg}
					/>
				)}
		</div>
	)
}
