import React, { useEffect, useState } from "react"
import Table from "react-bootstrap/Table"
import ProductRow from "./ProductRow"
import axios from "axios"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import { Button } from "react-bootstrap"

export default function ProductsTable({ data, productType, closeOperation }) {
	const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI
	const [productsFromScan, setProductsFromScan] = useState([])
	const [productsToAdd, setProductsToAdd] = useState([])

	const [showEmptyFieldsMsg, setShowEmptyFieldsMsg] = useState(false)

	const [showStatusModal, setShowStatusModal] = useState(false)
	const [isError, setIsError] = useState(false)
	const [statusModalMsg, setStatusModalMsg] = useState("")

	useEffect(() => {
		setProductsFromScan(data)
	}, [data])

	const handleRemoveProductRow = rowToRemove => {
		setProductsFromScan(prev => prev.filter(productData => productData.row !== rowToRemove))
		setProductsToAdd(prev => prev.filter(productData => productData.row !== rowToRemove))
	}

	const handleCloseOperation = () => {
		closeOperation()
		setProductsToAdd([])
		setShowEmptyFieldsMsg(false)
	}

	const addProducts = () => {
		;(async () => {
			try {
				if (productsToAdd.length !== productsFromScan.length) {
					setShowEmptyFieldsMsg(true)
					return
				}

				for (const { product, row } of productsToAdd) {
					if (productType === "bioquimicos") {
						await axios({
							method: "post",
							url: `${BACK_APP_URI}/lotes/add`,
							data: {
								lotNumber: product.lotNumber,
								expireDate: product.expireDate,
								acquireDate: product.acquireDate,
								quantity: product.quantity,
								product: product.productId,
								lotQuantity: product.lotQuantity,
							},
						})
					} else {
						await axios({
							method: "put",
							url: `${BACK_APP_URI}/otros_productos/moreQuantity`,
							data: {
								id: product.id,
								quantityToAdd: product.quantity,
							},
						})
					}
				}

				setShowEmptyFieldsMsg(false)
				setShowStatusModal(true)
				setIsError(false)
				setStatusModalMsg("Productos añadidos correctamente")
				setTimeout(() => {
					handleCloseOperation()
					setShowStatusModal(false)
				}, 2000)
			} catch (error) {
				console.log(error)
				setShowStatusModal(true)
				setIsError(true)
				setStatusModalMsg("Error al añadir productos")
				setTimeout(() => {
					setShowStatusModal(false)
					setIsError(false)
				}, 1000)
			}
		})()
	}

	if (!productsFromScan.length) return

	return (
		<>
			<div className="margin-30px">
				<Table responsive
					bordered
					striped
				>
					<thead>
						<tr>
							{/* <th>Etiqueta</th> */}
							<th>Fila</th>
							<th>Producto</th>
							<th>Cantidad</th>
							{productType === "bioquimicos" && (
								<>
									<th>Nro Lote</th>
									<th>C/ Lotes</th>
									<th>Vencimiento</th>
								</>
							)}
							<th></th>
						</tr>
					</thead>
					<tbody>
						{productsFromScan.map(productData => {
							return (
								<ProductRow
									key={productData.row}
									data={productData}
									isSelection={productData.needsSelection}
									handleRemoveProductRow={handleRemoveProductRow}
									productType={productType}
									setProductsToAdd={setProductsToAdd}
								/>
							)
						})}
					</tbody>
				</Table>
				<div className="flex gap-2">
					<Button onClick={handleCloseOperation}>Cancelar</Button>
					<Button onClick={addProducts}>Sumar Productos</Button>
					{showEmptyFieldsMsg && <div className="text-red">Se encontraros campos vacíos</div>}
				</div>
			</div>
			{showStatusModal && (
				<AnimacionSuccessful
					errorBool={isError}
					avisomsg={statusModalMsg}
					refreshPage={() => {
						setShowStatusModal(false)
						handleCloseOperation()
					}}
				/>
			)}
		</>
	)
}
