import React from "react"
import { Form } from "react-bootstrap"

export default function LabSelectionDropbox({labSelected, labsFromUser, handleLabSelectedChange}) {
	return (
		<div className="ml-3 mb-3 ">
			<div className="no-wrap text-28">
				Laboratorio:{" "}
				{labSelected ? (
					<b className="text-32">{labsFromUser.find(lab => lab._id === labSelected)?.name}</b>
				) : (
					<i className="opacity ">Seleccionar Laboratorio</i>
				)}
			</div>
			<div className="text-32">
				<Form.Select onChange={e => handleLabSelectedChange(e.target.value)}>
					<option value="">Seleccionar Laboratorio</option>
					{labsFromUser.map(lab => {
						return (
							<option
								key={lab._id}
								value={lab._id}
							>
								{lab.name}
							</option>
						)
					})}
				</Form.Select>
			</div>
		</div>
	)
}
