import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./Login.css";
import Logo from "../../Assets/Img/innovis_adobe_express.png";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const TEST_MODE = process.env.REACT_APP_TEST === "true";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordShown, setIsPasswordShown] = useState(false) //Toggle password visibility
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {

      if(username == "admin@admin.com" || username == "user@user.com") {
        // Validar que el correo electrónico tenga un formato válido antes de enviarlo al servidor
        if (!/\S+@\S+\.\S+/.test(username)) {
          throw new Error("El nombre de usuario no es válido");
        }

      } else {
        // Validar que el nombre de usuario tenga un formato válido antes de enviarlo al servidor
        if (!/^[a-zA-Z0-9_-]+$/.test(username)) {
          throw new Error("El nombre de usuario no es válido");
        }
      }
      
      // Validar que la contraseña cumpla con los requisitos de complejidad antes de enviarla al servidor
      if (password.length < 8) {
        throw new Error("La contraseña debe tener al menos 8 caracteres");
      }

      const response = await axios.post(`${BACK_APP_URI}/login`, {
        username,
        password,
      });

      const { token, role } = response.data; // Obtener el token y el rol de la respuesta
      console.log(role)
      Cookies.set("token", token); // Guardar el token en la cookie
      Cookies.set("role", role.name); // Guardar el rol en la cookie
      Cookies.set("username", username);
      Cookies.set("password", password);
        if (role.name === "sede") {
          navigate("/main"); // Redireccionar al usuario a la página principal de Sedes
        } else if(role.name === "logistic"){
          navigate("/orders") //Redireccionar al usuario a la página principal de Logística
        } else {
          navigate("/home"); // Redireccionar al usuario a la página principal
        }
        
      

      
    } catch (error) {
      console.error(error.message);
      // Proporcionar un mensaje de error más específico para informar al usuario sobre lo que ha sucedido
      alert(
        "Ha ocurrido un error al intentar iniciar sesión. Por favor, revise los datos ingresados e intente nuevamente."
      );
    }
  };

  return (
    <div className="login">
      <div className="login--content">
        <img src={Logo} alt="logo" className="logo" />
        <Form className="register--form-container">
          <Form.Group className="mb-3 input--primary" controlId="formBasicName">
            <Form.Control className="custom-placeholder"
              type="text"
              placeholder="Nombre de usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 input--primary"
            controlId="formBasicSurname"
          >
            <Form.Control className="custom-placeholder"
              type={isPasswordShown ? "text" : "password"}
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Check className="custom-form-check"
            inline
            label="Mostrar contraseña"
            checked={isPasswordShown}
            onChange={() => { setIsPasswordShown(prev => !prev) }}
          />
        </Form>
        <div className="btn--container-login">
          <button className="btn-login btn--primary-login" onClick={handleLogin}>
            Iniciar sesión
          </button>
        </div>
        {TEST_MODE && <p>test</p>}
      </div>
    </div>
  );
};

export default Login;
