import { Tooltip } from "@mui/material"
import React, { useEffect, useState } from "react"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EditOutlineIcon from "@mui/icons-material/EditOutlined"
import CheckOutlineIcon from "@mui/icons-material/CheckOutlined"

export default function CategoryRow({ category, catSelected, setCatSelected, handleShowModal, handleEditCategory }) {
	const [isEditing, setIsEditing] = useState(false)
	const [name, setName] = useState("")

	useEffect(() => {
		if (category) {
			setName(category.name)
		}
	}, [])


	return (
		<tr className={category._id === catSelected ? "selected-row tr-table" : "tr-table"}>
			{isEditing ? (
				<>
					<td>
						<input
							onChange={e => setName(e.target.value)}
							value={name}
							type="text"
						/>
					</td>
					<td>
						<Tooltip
							title="Aceptar cambios"
							arrow
						>
							<button
								className="btn-delete"
								onClick={async () => {
									const categoryEdited = await handleEditCategory(category._id, name)
                  if(categoryEdited) {
                    setName(categoryEdited.name)
                    setIsEditing(false)
                  }
								}}
							>
								<CheckOutlineIcon className="icon-delete" />
							</button>
						</Tooltip>
					</td>
				</>
			) : (
				<>
					<td>{name}</td>
					<td>
						<Tooltip
							title="Editar categoría"
							arrow
						>
							<button
								className="btn-delete"
								onClick={() => {
									setCatSelected(category._id)
									setIsEditing(true)
								}}
							>
								<EditOutlineIcon className="icon-delete" />
							</button>
						</Tooltip>
					</td>
				</>
			)}

			<td>
				<Tooltip
					title="Eliminar categoría"
					arrow
				>
					<button
						className="btn-delete"
						onClick={() => {
							setCatSelected(category._id)
							handleShowModal()
						}}
					>
						<DeleteOutlineIcon className="icon-delete" />
					</button>
				</Tooltip>
			</td>
		</tr>
	)
}
