import React, { useEffect, useState } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import closeIcon from "../../../Assets/Img/icon_close_.png"

export default function ProductRow({ data, isSelection, handleRemoveProductRow, productType, setProductsToAdd }) {
	const [productSelectedId, setProductSelectedId] = useState("")
	const [expireDate, setExpireDate] = useState("")
	const [quantity, setQuantity] = useState("")
	const [lotNumber, setLotNumber] = useState("")
	const [lotQuantity, setLotQuantity] = useState("")

	const handleQuantity = text => {
		setQuantity(text.replace(/\D/g, ""))
	}

	useEffect(() => {
		if (data.product) {
			setProductSelectedId(data.product._id)
		}
	}, [data])

	useEffect(() => {
		if (productType === "bioquimicos" && (productSelectedId === "" || expireDate === "" || quantity === "" || lotNumber === "" || lotQuantity === "")) return

		if (productType === "varios" && (productSelectedId === "" || quantity === "")) return

		const productData =
			productType === "bioquimicos"
				? {
						row: data.row,
						product: {
							lotNumber: lotNumber,
							expireDate: expireDate,
							acquireDate: Date.now(),
							quantity: quantity,
							lotQuantity: lotQuantity,
							productId: productSelectedId,
						},
				  }
				: {
						row: data.row,
						product: {
							id: productSelectedId,
							quantity: quantity,
						},
				  }

		setProductsToAdd(prevProducts => {
			const existingProductIndex = prevProducts.findIndex(product => product.row === productData.row)

			if (existingProductIndex !== -1) {
				const updatedProducts = [...prevProducts]
				updatedProducts[existingProductIndex] = productData
				return updatedProducts
			} else {
				return [...prevProducts, productData]
			}
		})
	}, [productSelectedId, expireDate, quantity, lotNumber, lotQuantity])

	return (
		<tr>
			<td>"{data.row}"</td>
			{isSelection ? (
				<td>
					<FloatingLabel label="Producto">
						<Form.Select
							value={productSelectedId}
							onChange={e => {
								setProductSelectedId(e.target.value)
							}}
						>
							<option
								value=""
								disabled
							>
								Seleccionar
							</option>
							{data.products.map(product => (
								<option
									key={product.name + data.row}
									value={product._id}
								>
									{product.name}
								</option>
							))}
						</Form.Select>
					</FloatingLabel>
				</td>
			) : (
				<td>{data.product.name}</td>
			)}
			<td>
				<input
					type="text"
					onChange={e => handleQuantity(e.target.value)}
					value={quantity}
					className="form-control"
					disabled={productSelectedId === ""}
				/>
			</td>
			{productType === "bioquimicos" && (
				<>
					<td>
						<input
							type="text"
							onChange={e => setLotNumber(e.target.value)}
							value={lotNumber}
							className="form-control"
							disabled={productSelectedId === ""}
						/>
					</td>
					<td>
						<input
							type="text"
							onChange={e => setLotQuantity(e.target.value)}
							value={lotQuantity}
							className="form-control"
							disabled={productSelectedId === ""}
						/>
					</td>
					<td>
						<ReactDatePicker
							placeholderText=""
							className="form-control"
							name="expirationDate"
							dateFormat="dd/MM/yyyy"
							input
							type="date"
							selected={expireDate}
							onChange={date => setExpireDate(date)}
							autoComplete="off"
							disabled={productSelectedId === ""}
						/>
					</td>
				</>
			)}

			<td>
				<button
					className="remove-button"
					onClick={() => handleRemoveProductRow(data.row)}
				>
					<img
						style={{
							width: "15px",
						}}
						src={closeIcon}
						alt="Eliminar"
					/>
				</button>
			</td>
		</tr>
	)
}
