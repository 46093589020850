import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import "./Users.css";
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/es';
import { DateRangePicker } from 'react-dates';
import { Card, InputGroup } from "react-bootstrap";
import BoxSelector from "./Components/BoxSelector";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const NewUser = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState("");
  const [roleName, setRoleName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordShown, setIsPasswordShown] = useState(false) //Toggle password visibility
  const [extraOrder, setExtraOrder] = React.useState(false);
  const [biochemicalsNotAllowed, setBiochemicalsNotAllowed] = useState(false);

  // Buscador de categorías de varios
  const [categoriesFound, setCategoriesFound] = useState([]);
  const [categoriesAssigned, setCategoriesAssigned] = useState([]);

  // Buscador de categorías de varios
  const [labsFound, setLabsFound] = useState([]);
  const [labsAssigned, setLabsAssigned] = useState([]);

  // Date Range Picker
  moment.locale('es'); // Establece el idioma para Moment.js
  const year = moment().format('YYYY')
  const month = moment().format('M')
  const [startDate, setStartDate] = useState(moment(year + '-' + month + '-' + 20, 'YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(startDate).add(15, 'days'));

  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    async function getLabs() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/laboratory`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          console.log("Data", res);
          setLabsFound(res.data.laboratories);
          setIsAdmin(res.data.userdata);
        });
    }
    getLabs();
  }, []);

  useEffect(() => {
    async function getDataCategories() {
      const token = Cookies.get("token");
      try {
        const response = await axios.get(`${BACK_APP_URI}/categories`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setCategoriesFound(response.data.categories);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    getDataCategories();
  }, []);

  const createUser = (fname, lname, position, role, roleName, username, email, password, labsAssigned, extraOrder, categoriesAssigned, startDateOrder, endDateOrder, biochemicalsNotAllowed) => {
    axios({
      method: "post",
      url: `${BACK_APP_URI}/users/add`,
      data: {
        fname: fname,
        lname: lname,
        position: position,
        role: role,
        roleName: roleName,
        username: username,
        email: email,
        password: password,
        labs: labsAssigned,
        extraOrder: extraOrder,
        categoriesAssigned: categoriesAssigned,
        startDateOrder: startDateOrder,
        endDateOrder: endDateOrder,
        biochemicalsNotAllowed: biochemicalsNotAllowed
      },
    }).then(function (response) {
      //alert("Usuario creado correctamente");
      navigate("/users");
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    createUser(fname, lname, position, role, roleName, username, email, password, labsAssigned, extraOrder, categoriesAssigned, startDate, endDate, biochemicalsNotAllowed);
  };

  const isOutsideRange = (day) => {
    // Permitir seleccionar un rango desde el día 20 hasta el final del mes actual
    const currentDate = moment();
    var fifthDayOfNextMonth = moment(currentDate).add(1, 'month').date(5);
    if (fifthDayOfNextMonth.format('dddd') === 'sabado') {
      fifthDayOfNextMonth = moment(currentDate).add(1, 'month').date(7);
    } else if (fifthDayOfNextMonth.format('dddd') === 'domingo') {
      fifthDayOfNextMonth = moment(currentDate).add(1, 'month').date(6);
    }
    return day.isBefore(currentDate) || day.isAfter(fifthDayOfNextMonth);
  };

  const handleExtraOrderChange = (e) => {
    setExtraOrder(!extraOrder);
    console.log(extraOrder)

  }

  const handleDateRangeChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
  }

  return (
    <>
      <div className="container-user">
        <div className="header-user-add">
          <h1 className="text-user">Registrar Usuario</h1>
          <div className="divider-user" />
          <div className="content-user-add ">
            <h1 className="title-user">Cuenta</h1>
            <Form className="user-form" onSubmit={handleSubmit(onSubmit)}>
              <FloatingLabel label="Nombre" className="mb-3">
                <Form.Control
                  className="input--primary"
                  placeholder="name"
                  name="fname"
                  type="text"
                  {...register("fname", {
                    required: true,
                    maxLength: 40,
                    pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
                  })}
                  onChange={(e) => {
                    setFname(e.target.value);
                  }}
                />
                {errors.fname?.type === "required" && (
                  <p class="error">Campo requerido </p>
                )}
                {errors.fname?.type === "maxLength" && (
                  <p class="error">Cantidad minima de caracteres es de 40</p>
                )}
                {errors.fname?.type === "pattern" && (
                  <p class="error">Solo puede contener letras</p>
                )}
              </FloatingLabel>

              <FloatingLabel label="Apellido" className="mb-3">
                <Form.Control
                  placeholder="Apellido"
                  className="input--primary"
                  name="lname"
                  type="textarea"
                  {...register("lname", {
                    required: true,
                    maxLength: 40,
                    pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
                  })}
                  onChange={(e) => {
                    setLname(e.target.value);
                  }}
                />
                {errors.lname?.type === "required" && (
                  <p class="error">Campo requerido </p>
                )}
                {errors.lname?.type === "maxLength" && (
                  <p class="error">Cantidad max. de caracteres es de 40</p>
                )}
                {errors.lname?.type === "pattern" && (
                  <p class="error">Solo puede contener letras</p>
                )}
              </FloatingLabel>

              <FloatingLabel label="Cargo" className="mb-3">
                <Form.Control
                  className="input--primary"
                  placeholder="Cargo"
                  name="position"
                  type="text"
                  {...register("position", {
                    required: false,
                    maxLength: 40,
                    pattern: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, //letras y espacios, pueden llevas acentos
                  })}
                  onChange={(e) => {
                    setPosition(e.target.value);
                  }}
                />
                {errors.position?.type === "maxLength" && (
                  <p class="error">Cantidad max. de caracteres es de 40</p>
                )}
                {errors.position?.type === "pattern" && (
                  <p class="error">Solo puede contener letras</p>
                )}
              </FloatingLabel>

              <FloatingLabel label="Nombre de usuario" className="mb-3">
                <Form.Control
                  placeholder="Nombre de usuario"
                  className="input--primary"
                  name="username"
                  type="text"
                  {...register("username", {
                    required: true,
                    pattern: /^[a-zA-Z0-9_-]+$/,
                    minLength: 3,
                  })}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
                {errors.username?.type === "required" && (
                  <p class="error">Campo requerido </p>
                )}
                {errors.username?.type === "pattern" && (
                  <p class="error">Caracteres inválidos</p>
                )}
                {errors.password?.type === "minLength" && (
                  <p class="error">Cantidad minima de caracteres de 3</p>
                )}
              </FloatingLabel>

              <FloatingLabel label="Contraseña" className="mb-3">
                <Form.Control
                  className="input--primary"
                  placeholder="Contraseña"
                  name="password"
                  type={isPasswordShown ? "text" : "password"}
                  {...register("password", {
                    required: true,
                    minLength: 8,
                  })}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                {errors.password?.type === "required" && (
                  <p class="error">Campo requerido </p>
                )}
                {errors.password?.type === "minLength" && (
                  <p class="error">Cantidad minima de caracteres de 8</p>
                )}
              </FloatingLabel>

              <Form.Check style={{display: 'flex', gap: '10px'}}
                inline
                label="Mostrar"
                checked={isPasswordShown}
                onChange={() => { setIsPasswordShown(prev => !prev) }}
              />
              <div>
                <h3 className="register-user-text">Tipo de usuario</h3>
              </div>
              <div className="register-user">

                <Form.Group name="role">
                  <Form.Check
                    type="radio"
                    label="Administrador"
                    {...register("role", {
                      required: true,
                    })}
                    onChange={(e) => {
                      setRole("admin");
                      setRoleName("admin");
                    }}
                  />
                  {errors.role?.type === "required" && (
                    <p class="error">Campo requerido </p>
                  )}
                </Form.Group>
                <Form.Group name="role">
                  <Form.Check
                    type="radio"
                    label="Usuario"
                    {...register("role", {
                      required: true,
                    })}
                    onChange={(e) => {
                      setRole("user");
                      setRoleName("user");
                    }}
                  />
                  {errors.role?.type === "required" && (
                    <p class="error">Campo requerido </p>
                  )}
                </Form.Group>

                {/* USUARIO SEDE */}

                <Form.Group name="role">
                  <Form.Check
                    type="radio"
                    label="Sede"
                    {...register("role", {
                      required: true,
                    })}
                    onChange={(e) => {
                      setRole("sede");
                      setRoleName("sede");
                    }}
                  />
                  {errors.role?.type === "required" && (
                    <p class="error">Campo requerido </p>
                  )}
                </Form.Group>
                {/* USUARIO LOGÍSTICA */}
                <Form.Group name="role">
                  <Form.Check
                    type="radio"
                    label="Logistica"
                    {...register("role", {
                      required: true,
                    })}
                    onChange={(e) => {
                      setRole("logistic");
                      setRoleName("logistic");
                    }}
                  />
                  {errors.role?.type === "required" && (
                    <p class="error">Campo requerido </p>
                  )}
                </Form.Group>
              </div>

              {/* Date picker */}

              {role === "sede" && (
                <>
                  <hr />

                  <div className="d-flex justify-content-between">
                    <p>Período de pedido</p>
                    <DateRangePicker
                      startDate={startDate}
                      startDateId="your_unique_start_date_id"
                      endDate={endDate}
                      endDateId="your_unique_end_date_id"
                      onDatesChange={handleDateRangeChange}
                      focusedInput={focusedInput}
                      onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                      small
                      displayFormat="DD/MM/YY" // Define el formato de visualización
                      monthFormat="MMMM YYYY"
                      weekDayFormat="dd"
                      isOutsideRange={isOutsideRange}
                    />
                  </div>

                  <Form.Switch
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      paddingLeft: "0"

                    }}>
                    <p>Pedido extra</p>
                    <Form.Check
                      type="switch"
                      id="extra-order-switch"
                      checked={extraOrder === true}
                      onChange={handleExtraOrderChange}
                      style={{ fontSize: "1.25rem" }}
                    >
                    </Form.Check>
                  </Form.Switch>

                  <Form.Switch
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                      paddingLeft: "0"

                    }}>
                    <p>Deshabilitar Bioquímicos</p>
                    <Form.Check
                      type="switch"
                      id="extra-order-switch"
                      checked={biochemicalsNotAllowed === true}
                      onChange={() => setBiochemicalsNotAllowed(prev => !prev)}
                      style={{ fontSize: "1.25rem" }}
                    >
                    </Form.Check>
                  </Form.Switch>

                  {/* Buscador de categorías */}
                  <BoxSelector allItems={categoriesFound} itemsAssigned={categoriesAssigned} title={"Seleccionar Categorías"} setItemsAssigned={setCategoriesAssigned} />


                </>
              )}



              {(role === "sede" || role === "logistic") && (
                <>
                  <hr />
                  <BoxSelector allItems={labsFound} itemsAssigned={labsAssigned} title={"Seleccionar Laboratorios"} setItemsAssigned={setLabsAssigned} />
                </>

              )}
              
              <div className="button-user" >
                <button
                  children="Cancelar"
                  type="submit"
                  className="button-edit-text"
                  onClick={() => {
                    navigate("/users");
                  }}
                />
                <button
                  children="Guardar"
                  type="submit"
                  className="button-edit"

                />
              </div>
            </Form>
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

export default NewUser;
