import React from "react";
import "./AllUnits.css"
import axios from "axios";
import Table from "react-bootstrap/Table";
import AbmButtons from "../../ABMbotones/abmButtons";
import Cookies from "js-cookie";

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

export const AllUnits = () => {
  const [AllUnits, setAllUnits] = React.useState([]);
  const [unitSelected, setUnitSelected] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState("");

  const select = (unit) => {
    setUnitSelected(unit._id);
  };
  const deselect = () => {
    setUnitSelected(null);
  };

  function refreshPage() {
    window.location.reload(false);
  }

  React.useEffect(() => {
    async function getDataUnits() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/unidades`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((result) => {
          setAllUnits(result.data.units);
          setIsAdmin(result.data.userdata);
        });
    }
    getDataUnits();
  }, []);

  const deleteUnit = (id) => {
    axios({
      method: "delete",
      url: `${BACK_APP_URI}/unidades/delete/${unitSelected}`,
    }).then(refreshPage());
  };

  return (
    <div className="main-container">
    <div className="header--container">
      <h1 className="title--header">Unidades</h1>
      </div>
      <div className="card--container ">
      <div className="table-container">
      
      <Table className="table-striped table-bordered table-hover" >
        <thead  responsive onClick={deselect}>
          <tr>
            <th className="title-th">Nombre</th>
            <th className="unitcode-th title-th">Codigo</th>
            <th className="relationcode title-th">Tipo de Unidad</th>
          </tr> 
        </thead>
        <tbody>
          {AllUnits.slice(1).map((unit) => (
            <tr
              key={unit._id}
              onClick={() => select(unit)}
              className={
                unit._id === unitSelected ? "selected-row tr-table" : "tr-table"
              }
            >
              <td>{unit.name}</td>
              <td className="unitcode-th">{unit.unitcode}</td>
              <td className="relationcode-th">{unit.relationcode.name}</td>{" "}
            </tr>
          ))}
        </tbody>
      </Table>
      
      </div>
      </div>
      <AbmButtons
        item={unitSelected}
        abm={"hola"}
        funcion={deleteUnit}
        role={isAdmin}
      />{" "}
      {/* Cada uno de estos props(proveedor, abm, funcion, role) estan explicados en el componente AbmBotones*/}
    
    </div>
  );
};
