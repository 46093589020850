import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Camera from '../../../Components/camera/camera.jsx';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 250,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '8px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function NestedModal({ open, handleOpen, handleClose }) {
  const [showOnlyCamera, setShowOnlyCamera] = useState(false);
  const [selectedCameraAction, setSelectedCameraAction] = useState(null);

  const handleCameraAction = (action) => {
    setSelectedCameraAction(action);
    setShowOnlyCamera(true);
    handleClose(); // Cierra el modal principal después de configurar el estado
  };

  const handleModalClose = () => {
    handleClose();
    setSelectedCameraAction(null);
    setShowOnlyCamera(false);
  };

  const handleCameraClose = () => {
    setShowOnlyCamera(false);
    setSelectedCameraAction(null);
    handleOpen(); // Reabre el modal principal
  };

  if (showOnlyCamera && selectedCameraAction) {
    return (
      <Camera
      onButtonClick={(action) => handleCameraAction(action)}
      onSelection={(action) => handleCameraAction(action)}
      initialAction={selectedCameraAction}
    />
    );
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <h2 id="parent-modal-title">Carga de Productos</h2>
          <Camera
            onButtonClick={(action) => handleCameraAction(action)}
            onSelection={(action) => handleCameraAction(action)}
          />
          <Button onClick={handleModalClose}>Cerrar Modal</Button>
        </Box>
      </Modal>
    </div>
  );
}