import React from 'react';
import StockProducts from "../Products/StockProducts/StockProducts"
import Camera from '../../Components/camera/camera'
const Main = () => {
    return (
      <div className='main-container'>
        <StockProducts resumen={true} />
      </div>
    );
}

export default Main;
