import React from "react";
import { Route, Routes } from 'react-router-dom';
import { AllCategories } from "./AllCategories";

const Category = () => {
    return (
        <Routes>
            <Route exact path='/' element={<AllCategories/>} />
        </Routes>
    );
    }

export default Category;