import React, { useState, useEffect } from "react";
import './Styles/bootstrap-overrides.css';
import './Styles/material-overrides.css';
import './Styles/variables.css';
import './Styles/utilities.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  HashRouter
} from "react-router-dom";
import Login from "./Components/Login/Login";
import Users from "./Components/Users/Users";
import Products from "./Components/Products/Products";
import Providers from "./Components/Providers/Providers";
import Main from "./Components/Main/Main";
import Sidebar from "./Components/SideBar/SideBar";
import Navbar from "./Components/Navbar/Navbar";
import "./App.css";
import NewUser from "./Components/Users/NewUser";
import Units from "./Components/Units/Units";
import Tags from "./Components/Tags/Tags";
import Laboratories from "./Components/Laboratories/Laboratories";
import Upload from "./Components/camera/camera";
import Cookies from "js-cookie";
import Orders from "./Components/Order/Orders";
import SedeMain from "./Components/Main/SedeMain/SedeMain";
import ProviderOrder from "./Components/ProviderOrder/ProviderOrder"
import ProviderOrderHistory from "./Components/ProviderOrder/ProviderOrderHistory";
import Category from "./Components/Categories/Category";
import AppLayout from "./Styles/AppLayout";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const PrivateRoutes = () => {
    const token = Cookies.get("token") || "";
    const role = Cookies.get("role") || "";
    const handleLogout = () => {
      setIsLoggedIn(false);
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.href = "/";
    };
  
    useEffect(() => {
      const logoutTimer = setTimeout(() => {
        handleLogout();
      }, 240 * 60 * 1000); 
      return () => {
        clearTimeout(logoutTimer);
      };
    }, []);

    if (token) {
      if(role === "sede" || role === "logistic"){
        return (
          <>
          
            <Navbar />
            <Sidebar />
            <AppLayout>
            <Routes>
            <Route path="/main" element={<SedeMain />} />
              <Route path="/orders/*" element={<Orders />} />
            </Routes>
            </AppLayout>
          </>
        );
      }else{
        return (
          <>
            <Navbar />
            <Sidebar />
            <AppLayout>
            <Routes>
              <Route path="/home" element={<Main />} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/register" element={<NewUser />} />
              <Route path="/productos/*" element={<Products />} />
              <Route path="/users/*" element={<Users />} />
              <Route path="/provider/*" element={<Providers />} />
              <Route path="/unidades/*" element={<Units />} />
              <Route path="/tags/*" element={<Tags />} />
              <Route path="/laboratory/*" element={<Laboratories />} />
              <Route path="/orders/*" element={<Orders />} />
              <Route path="/provider-order/*" >
                <Route index element={<ProviderOrder />}/>
                <Route path="history" element={<ProviderOrderHistory />} />
              </Route>
              <Route path="/categories/" element={<Category/> }/> 
            </Routes>
            </AppLayout>
          </>
        );
      }
      
    } else {
      return <Navigate to="/" />;
    }
  };

  return (
    <div className="App">
      <HashRouter> 
        <Routes>
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/*" element={<PrivateRoutes />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;