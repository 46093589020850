import React from "react";
import { Route, Routes } from 'react-router-dom';
import { AllUnits } from "./AllUnits/AllUnits";
import AddUnits from "./AddUnits/AddUnits";
import EditUnits from "./EditUnits/EditUnits";

const Units = () => {
    return (
        <Routes>
            <Route exact path='/' element={<AllUnits/>} />
            <Route exact path='/add' element={<AddUnits/>} />
            <Route exact path='/edit/:id' element={<EditUnits/>}/>
        </Routes>
    );
    }

export default Units;