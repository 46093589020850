import React, { useState } from "react"
import editIcon from "../../../../Assets/Img/iconEdit.svg"
import DatePicker from "react-datepicker"
import axios from "axios"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function LotRow({ lot }) {
	const [lotNumber, setLotNumber] = useState(lot.lotNumber)
	const [acquireDate, setAcquireDate] = useState(new Date(lot.acquireDate))
	const [expireDate, setExpireDate] = useState(new Date(lot.expireDate))
	const [quantity, setQuantity] = useState(lot.quantity)
	const [lotQuantity, setLotQuantity] = useState(lot.lotQuantity)

	const [isEditing, setIsEditing] = useState(false)

	const handleIsEditing = () => {
		setIsEditing(prev => !prev)
	}

	const submitLotEdition = async () => {

    await axios({
      method: "put",
      url: `${BACK_APP_URI}/lotes/edit/${lot._id}`,
      data: {
        lotNumber: lotNumber,
        expireDate: expireDate,
        acquireDate: acquireDate,
        quantity: quantity,
        product: lot.product,
        lotQuantity: lotQuantity,
      },
    })

    window.location.reload()
	}

	function formatedDate(date) {
		const dateParts = date.split('-')
		const dayParts = dateParts[2].split('T')
		const day = dayParts[0]
		const month = dateParts[1]
		const year = dateParts[0]
		return(` ${day}/${month}/${year}`)
	}

	if (isEditing) {
		return (
			<tr>
				<td>
					<input
						className="input-LotRow no-arrows"
						onChange={e => setLotNumber(e.target.value)}
						value={lotNumber}
            type="text"
					/>
				</td>
				<td className="relative">
					<DatePicker
						className="input-LotRow"
						placeholderText="Seleccionar"
						name="acquireDate"
						dateFormat="dd/MM/yyyy"
						input
						type="date"
						selected={acquireDate}
						onChange={date => setAcquireDate(date)}
						autoComplete="off"
					/>
				</td>
				<td className="relative">
					<DatePicker
						placeholderText="Seleccionar"
						className="input-LotRow"
						name="acquireDate"
						dateFormat="dd/MM/yyyy"
						input
						type="date"
						selected={new Date(expireDate)}
						onChange={date => setExpireDate(date)}
						autoComplete="off"
					/>
				</td>
				<td>
					<input
						className="input-LotRow no-arrows"
						onChange={e => {
							setQuantity(e.target.value)
						}}
						value={quantity}
            type="number"
					/>
				</td>
				<td>
					<input
						className="input-LotRow no-arrows"
						onChange={e => {
							setLotQuantity(e.target.value)
						}}
						value={lotQuantity}
            type="number"
					/>
				</td>
				<td>
					<button
						className="lotButton"
						onClick={submitLotEdition}
					>
						<span className="color-green">✔</span>
					</button>
				</td>
			</tr>
		)
	} else {
		return (
			<tr>
				<td>{lot.lotNumber}</td>
				<td>{`${new Date(lot.acquireDate).getDate()}/${new Date(lot.acquireDate).getMonth() + 1}/${new Date(lot.acquireDate).getFullYear()}`}</td>
				<td>{formatedDate(lot.expireDate)}</td>
				<td>{lot.quantity}</td>
				<td>{lot.lotQuantity}</td>
				<td>
					{" "}
					<button
						className="lotButton"
						onClick={handleIsEditing}
					>
						<img
							src={editIcon}
							alt="editar lote"
						/>
					</button>
				</td>
			</tr>
		)
	}
}
