import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import "./Users.css";
import Cookies from "js-cookie";
import AbmButtons from "../ABMbotones/abmButtons";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "react-bootstrap/Pagination";
import { Badge, Button, Modal } from "react-bootstrap";
import AnimacionSuccessful from "../AnimacionSuccessful/animacionSuccessful";
import { useNavigate } from "react-router-dom";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const UsersList = () => {
  function refreshPage() {
    window.location.reload(false);
  }

  const navigate = useNavigate();
  const [UserSelected, setUserSelected] = useState(null);
  const [isAdmin, setIsAdmin] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [filteredData, setFilteredData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState("");
  const [labs, setLaboratorios] = React.useState([]);

  // Estados para AnimacionSuccessful
  const [errorBool, setErrorBool] = React.useState(false);
  const [avisomsg, setAvisomsg] = React.useState('');
  const [variable, setVariable] = React.useState(false);

  const seleccionar = (provider) => {
    if (provider.email === "admin@admin.com" || provider.email === "user@user.com") {
      return;
    }
    console.log("Provider ID:", provider._id);
    setUserSelected(provider._id);
  };

  const deseleccionar = () => {
    setUserSelected(null);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [filter, activePage]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BACK_APP_URI}/users`, {
        headers: {
          Authorization: `${Cookies.get("token")}`,
        },
      });

      const data = response.data.users;
      setIsAdmin(response.data.userdata);

      const filtered = data.filter(
        (item) =>
          item.lname.toLowerCase().includes(filter.toLowerCase()) ||
          item.fname.toLowerCase().includes(filter.toLowerCase()) ||
          item.position.toLowerCase().includes(filter.toLowerCase()) ||
          item.email.toLowerCase().includes(filter.toLowerCase()) ||
          item.role.name.toLowerCase().includes(filter.toLowerCase())
      );
      setFilteredData(filtered);

      const totalPages = Math.ceil(filtered.length / itemsPerPage);
      setTotalPages(totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function getLabs() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/laboratory`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          setLaboratorios(res.data.laboratories);
          setIsAdmin(res.data.userdata);
        });
    }
    getLabs();
  }, []);

  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setActivePage(1);
  };

  const deleteCustomer = () => {
    axios({
      method: "delete",
      url: `${BACK_APP_URI}/users/delete/${UserSelected}`,
    })
      .then((data) => {
        setVariable(true);
        setErrorBool(false);
        setAvisomsg('Usuario eliminado correctamente');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="main-container">
        <div className="header--container">
          <div className="header-row-user">
            <h1 className="title--header">Usuarios</h1>
            
            <Form className="all-tag-form">
              <InputGroup>
                <Form.Control
                  className="all-tag-search"
                  value={filter}
                  onChange={handleFilterChange}
                  placeholder="Buscar usuarios"
                />
              </InputGroup>
            </Form>
          </div>
        </div>
        <div className="card--container">
          <div className="table-container">
            <Table className="table-striped table-bordered table-hover">
              <thead responsive > 
                <tr onClick={() => deseleccionar()}>
                  <th className="title-th">Apellido Nombre</th>
                  <th className="title-th">Cargo</th>
                  <th className="title-th">Usuario</th>
                  <th className="title-th">Rol</th>
                  <th className="title-th">Laboratorios</th>
                  <th className="title-th">Pedido extra</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map((user) => (
                  <tr
                    key={user._id}
                    onClick={() => seleccionar(user)}
                    className={user._id === UserSelected ? "selected-row tr-table" : "tr-table"}
                  >
                    <td>{`${user.lname || "N/A"} ${user.fname || "N/A"}`}</td>
                    <td>{user.position || "N/A"}</td>
                    <td>{user.username || "N/A"}</td>
                    <td>{user.roleName || "N/A"}</td>
                    <td>
                      {user.labs?.length > 0 ? user.labs.map((lab, index) => (
                        <Badge key={index} className="ml-2">
                          {lab?.name || "N/A"}
                        </Badge>
                      )) : "N/A"}
                    </td>
                    {user.extraOrder === true && user.role?.name === "sede" ? (
                      <td className="text-success">Habilitado</td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          
          {variable && (
            <AnimacionSuccessful 
              errorBool={errorBool} 
              avisomsg={avisomsg}
              refreshPage={refreshPage}
            />
          )}
        </div>
        
        <Pagination className="pagination-all-tag">
          <Pagination.Prev 
            onClick={() => handlePageChange(activePage > 1 ? activePage - 1 : 1)} 
            disabled={activePage === 1}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              className="pag-item-all-tag"
              key={index + 1}
              active={index + 1 === activePage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next 
            onClick={() => handlePageChange(activePage < totalPages ? activePage + 1 : totalPages)} 
            disabled={activePage === totalPages}
          />
        </Pagination>
        
        <AbmButtons
          item={UserSelected}
          abm={"user"}
          funcion={deleteCustomer}
          role={isAdmin}
        />
      </div>
    </>
  );
};

export default UsersList;
