import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import "./animacionSuccessful.css";
import errorAlert from "../../Assets/Img/error-alert.svg"
import iconSuccess from "./successful.svg";
import iconError from "./erroricon.svg";

export const AnimacionSuccessful = (props) => {
    const errorBoolean = props.errorBool;
    const [showPopup, setShowPopup] = React.useState(false);
    const [variable, setVariable] = React.useState(false);
    const avisomsg = props.avisomsg;
    const refresh = props.refreshPage;
    // Función para mostrar el pop-up

    const showSuccessPopup = () => {
        setShowPopup(true);

        setTimeout(() => {
            setVariable(true);
        }, 300);
        // Ocultar el pop-up después de 3 segundos
        // setTimeout(() => {
        //     setVariable(false);
        //     setShowPopup(false);
        // }, 1200);
    };

    React.useEffect(() => {
        showSuccessPopup();
    }, []);

    return (
        <>
            {errorBoolean ? (
                <>
                    {/* Si errorBoolean es TRUE se renderiza el modal Error */}
                    {showPopup && (
                        <Modal
                            show={showPopup}
                            onHide={() => setShowPopup(false)}
                            className="success-popup-modal "
                            centered
                            size="sm"
                        >
                            <div className="success-popup">
                                <Modal.Header className="modal-header-success">
                                    <Modal.Title>
                                        Error en la Operacion
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p style={{ textAlign: "center" }}>
                                        {avisomsg}
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    {variable ? (
                                        <img src={errorAlert} alt="Error" />
                                    ) : (
                                        <Spinner
                                            animation="border"
                                            variant="Danger"
                                        />
                                    )}
                                </Modal.Footer>
                            </div>
                        </Modal>
                    )}
                </>
            ) : (
                <>
                    {showPopup && (
                        <Modal
                            show={showPopup}
                            onHide={() => setShowPopup(true)}
                            className="success-popup-modal "
                            centered
                            size="sm"
                        >
                            <div className="success-popup">
                                {variable ? (
                                    <img src={iconSuccess}
                                        alt="Success"
                                        style={{ width: "5rem", marginTop: "3rem" }} />
                                ) : (
                                    <Spinner
                                        animation="border"
                                        variant="succes"
                                    />
                                )}
                                <Modal.Header
                                    style={{ borderBottom: "none" }}
                                >
                                    <Modal.Title><h1>{avisomsg}</h1></Modal.Title>
                                </Modal.Header>
                                {/* <Modal.Body>
                                    <p style={{ textAlign: "center" }}>
                                        {avisomsg}
                                    </p>
                                </Modal.Body> */}
                                <Modal.Footer
                                    style={{
                                        borderTop: "none",
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Button
                                        className="btn btn--primary btn btn-primary btn-accept"
                                        style={{ width: "140px", display: "flex", justifyContent: "center" }}
                                        onClick={refresh}
                                    >Aceptar
                                    </Button>

                                </Modal.Footer>
                            </div>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default AnimacionSuccessful;
