import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ProviderProductsToPdf from "./../../ProviderProducts/ProviderProductsToPdf.jsx";
import Cookies from "js-cookie";
import axios from "axios";

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

export default function PdfExportModal({ show, onClose, providers, isLoaded }) {
    const [selectedProvider, setSelectedProvider] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        if (selectedProvider) {
            async function getDataProducts() {
                const token = Cookies.get("token");
                const result = await axios.get(`${BACK_APP_URI}/productos`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                });

                const products = result.data.products;

                const otherProductsResponse = await axios.get(`${BACK_APP_URI}/otros_productos`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                });

                const otherProducts = otherProductsResponse.data.products;

                const combinedProducts = [...products, ...otherProducts];

                const filteredProductsByProviderId = combinedProducts.filter(
                    (product) => product.provider._id === selectedProvider
                );

                setFilteredProducts(filteredProductsByProviderId);
            }
            getDataProducts();
        } else {
            setFilteredProducts([]);
        }
    }, [selectedProvider]);

    // manejo de  cierre del modal
    useEffect(() => {
        if (!show) {
            // Limpia los datos filtrados y resetea el proveedor seleccionado
            setFilteredProducts([]);
            setSelectedProvider("");
        }
    }, [show]); // Dependencia show para ejecutar cuando el modal se cierre

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title>Productos a PDF</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Selecciona un proveedor
                <Form style={{ marginTop: "10px" }}>
                    {isLoaded && (
                        <Form.Select
                            className="select-provider"
                            onChange={(e) => setSelectedProvider(e.target.value)}
                            value={selectedProvider}
                        >
                            <option value="">Proveedores</option>
                            {providers.map((provider) => (
                                <option key={provider._id} value={provider._id}>
                                    {provider.name}
                                </option>
                            ))}
                        </Form.Select>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancelar
                </Button>
                {filteredProducts.length > 0 && (
                    <PDFDownloadLink
                        document={<ProviderProductsToPdf products={filteredProducts} />}
                        fileName={`Productos-Proveedor-${selectedProvider}`}
                    >
                        <Button variant="success">
                            Generar
                        </Button>
                    </PDFDownloadLink>
                )}
            </Modal.Footer>
        </Modal>
    );
}
