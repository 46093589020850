import React from 'react';
import { Route,Routes } from 'react-router-dom';
import PrivateRoutes from '../PrivateRoutes/PrivateRoutes';
import { AllLabs } from './AllLabs/AllLabs';
import AddLabs from './AddLabs/AddLabs';
import EditLabs from './EditLabs/EditLabs';



const Laboratories = () => {
    return (
        <Routes>
            <Route element={<PrivateRoutes/>}>
                <Route exact path='/' element={<AllLabs/>}/>
                <Route exact path='/add' element={<AddLabs/>}/>
                <Route exact path='/edit/:id' element={<EditLabs/>}/>
            </Route>
        </Routes>
    );
}

export default Laboratories;