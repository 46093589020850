import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddProducts from './AddProducts/AddProducts';
import { AllProducts } from './AllProducts/AllProducts';
import EditProducts from './EditProducts/EditProducts';
import { StockProducts } from './StockProducts/StockProducts';
import './Products.css'
import EditOtherProducts from './EditProducts/EditOtherProducts';

const Products = () => {
    return (
        <Routes>
            {/* Ruta exacta para mostrar todos los productos */}
            <Route exact path='/' element={<AllProducts/>}/>

            {/* Ruta exacta para agregar productos */}
            <Route exact path='/add' element={<AddProducts/>}/>

            {/* Ruta exacta para editar un producto específico */}
            <Route exact path='/edit/:id' element={<EditProducts/>}/>
            
            {/* Ruta exacta para editar un producto varios específico */}
            <Route exact path='/editVarios/:id' element={<EditOtherProducts/>}/>

            {/* Ruta exacta para mostrar el stock de productos */}
            <Route exact path='/stock' element={<StockProducts/>}/>

        </Routes>
    );
}

export default Products;
