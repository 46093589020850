import React, { useEffect, useState } from "react";
import "./AllOrders.css";
import delyIcon from "./../../../Assets/Img/delivery-truck-4439.svg"
import { Link } from "react-router-dom";
import { Button, Card, Form, Pagination } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import AbmButtons from "../../ABMbotones/abmButtons";
import { DateRangePicker } from "react-dates";
import moment from 'moment';
import iconCheck from "./../../../Assets/Img/check-circle-svgrepo-com.svg"
import fileExport from "../../../Assets/Img/file-export-white.svg"
import ExportOrderModal from "../ExportOrderModal";


const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;




export default function AllOrders() {


  const [isAdmin, setIsAdmin] = useState("");
  const [allOrders, setAllOrders] = useState([]);
  const [filterUserLabType, setFilterUserLabType] = useState("");
  const [id, setId] = useState(null);
  const [AllLabs, setAllLabs] = useState([]);
  const [filteredLab, setFilteredLab] = useState("");
  const [filteredState, setFilteredState] = useState("");
  const [allProviders, setAllProviders] = useState([]);
  const [orderSelected, setOrderSelected] = useState(null);
  const [orderToPdfModal, setOrderToPdfModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const ordersPerPage = 10;
  const [allProducts, setAllProducts] = useState([]);
  const [allOrderStates, setAllOrderStates] = useState([]);
  const [labsAssigned, setLabsAssigned] = useState([]);


  const pagesVisited = pageNumber * ordersPerPage;
  const pageCount = Math.ceil((allOrders.length - 1) / ordersPerPage);


  const changePage = (selected) => {
    setPageNumber(selected);
  };


  //---------------Date Range Picker------------------------------------------------


  moment.locale('es'); // Establece el idioma para Moment.js
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);
  const [pageCountForButton, setPageCountForButton] = useState(0);


  const startDateTime = new Date(startDate).getTime();
  const endDateTime = new Date(endDate).getTime();


  useEffect(() => {
    const filteredOrdersCount = allOrders.filter(order =>
      (new Date(order.createdAt).getTime() >= startDateTime && new Date(order.createdAt).getTime() <= endDateTime)
    ).length;
    const calculatedPageCount = Math.ceil(filteredOrdersCount / ordersPerPage);
    setPageCountForButton(calculatedPageCount)
  }, [startDateTime, endDateTime, allOrders])


  //Refresh
  function refreshPage() {
    window.location.reload(false);
  }


  // Registro de campos y validación
  const selected = (order) => {
    setOrderSelected(order._id);
  };
  const deselected = () => {
    setOrderSelected(null);
  };


  // Obtengo todos los pedidos
  useEffect(() => {
    async function getAllOrders() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/pedidos`, {
          headers: {
            Authorization: `${token}`,
          },
        })


        .then(async (result) => {
          setAllOrders(result.data.orders);
          setFilterUserLabType(result.data.username)
          setIsAdmin(result.data.userdata)
          setAllOrderStates(removeDuplicates(result.data.orders.map(order => order.state || "Pendiente")))


          const filteredByDate = result.data.orders.filter((order) => (new Date(order.createdAt).getTime() >= startDateTime) && (new Date(order.createdAt).getTime() <= endDateTime));


          let filteredOrders = filteredByDate


          if (filteredLab) {
            filteredOrders = filteredOrders.filter(order => order.labOrigin._id === filteredLab)


          }


          if (filteredState) {
            filteredOrders = filteredOrders.filter(order => {


              if (!order.hasOwnProperty("state") && filteredState === "Pendiente") return true


              return order.state === filteredState
            })
          }


          setAllOrders(filteredOrders)



          if (isAdmin !== "admin") {


            const response = await axios.get(`${BACK_APP_URI}/users/findByUsername/${result.data.username}`, {
              headers: {
                Authorization: `${token}`,

              },
            });

            setLabsAssigned(response.data.labs)
          }
        });
    }
    getAllOrders();
  }, [filteredLab, startDateTime, endDateTime, isAdmin, filteredState]);


  // Obtengo Laboratorios para filtrar
  useEffect(() => {
    async function getLabs() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/laboratory`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          setAllLabs(res.data.laboratories);
          setIsAdmin(res.data.userdata);
        });
    }
    async function getProviders() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/proveedor`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          //console.log("Providers", res.data.provider)
          setAllProviders(res.data.provider);
        });
    }
    getLabs();
    getProviders();
  }, []);


  const select = (order) => {
    setId(order._id);
  };


  //Borrar pedido
  const deleteOrder = (id) => {
    axios({
      method: "delete",
      url: `${BACK_APP_URI}/pedidos/delete/${orderSelected}`,
    }).then(refreshPage());
  };


  //--------------------Handlers--------------------------
  const handleDateRangeChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    /*     console.log("Start Date:", startDate);
        console.log("End Date:", endDate); */
  }


  const isOutsideRange = (day) => {
    return false; // Habilita todos los días
  };


  function removeDuplicates(array) {
    return [...new Set(array)];
  }


  const handleClearFilteredData = () => {
    setFilteredState("")
    setFilteredLab("")
    setStartDate(moment().subtract(7, 'days'))
    setEndDate(moment())
    changePage(0)
  }


  return (



    <div className="main-container" >
      <div className="header--container">
        <h1 className="title--header">Pedidos</h1>


        {isAdmin !== "sede"
          ?
          <div className="select-container-order"
            onClick={deselected}>
            <Form.Select
              value={filteredState}
              className="form-order"
              onChange={(e) => {
                setFilteredState(e.target.value);
                changePage(0);
              }}>
              <option value="" disabled>Seleccionar Estado</option>
              <option value="">Todos los Estados</option>
              {allOrderStates.map(state => {
                return <option
                  key={state}
                  value={state}
                >
                  {state}
                </option>
              })}
            </Form.Select>


            <Form.Select
              value={filteredLab}
              className="form-order"
              onChange={(e) => {
                setFilteredLab(e.target.value);
                changePage(0);
              }}>
              <option value="" disabled>Seleccionar Laboratorio</option>
              <option value="">Todos los Laboratorios</option>
              {isAdmin === "logistic" ? (labsAssigned.map((lab, i) => {
                return <option
                  key={lab._id}
                  value={lab._id}
                >
                  {lab.name}
                </option>
              })) : (
                AllLabs.map((lab, i) => {
                  return <option
                    key={lab._id}
                    value={lab._id}
                  >
                    {lab.name}
                  </option>
                })
              )}
            </Form.Select>
            {/* Date picker */}
            <DateRangePicker
              startDate={startDate}
              startDateId="your_unique_start_date_id"
              endDate={endDate}
              endDateId="your_unique_end_date_id"
              onDatesChange={handleDateRangeChange}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => setFocusedInput(focusedInput)}
              small
              displayFormat="DD/MM/YY" // Define el formato de visualización
              monthFormat="MMMM YYYY"
              weekDayFormat="dd"
              isOutsideRange={isOutsideRange}
            />


            <Button className="btn-order" onClick={() => setOrderToPdfModal(true)}>
              <img src={fileExport} alt="" className="icon-order-btn" />
              Exportar
            </Button>
          </div>

          :
          <div className=" mb-3 d-flex"
            style={{ gap: '3rem', width: '100%', justifyContent: 'space-between' }}
            onClick={deselected}>
            <DateRangePicker
              startDate={startDate}
              startDateId="your_unique_start_date_id"
              endDate={endDate}
              endDateId="your_unique_end_date_id"
              onDatesChange={handleDateRangeChange}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => setFocusedInput(focusedInput)}
              small
              displayFormat="DD/MM/YY" // Define el formato de visualización
              monthFormat="MMMM YYYY"
              weekDayFormat="dd"
              isOutsideRange={isOutsideRange}
            />
            <Form.Select
              value={filteredState}
              className=" w-25"
              onChange={(e) => {
                setFilteredState(e.target.value);
                changePage(0);
              }}>
              <option value="" disabled>Seleccionar Estado</option>
              <option value="">Todos los Estados</option>
              {allOrderStates.map(state => {
                return <option
                  key={state}
                  value={state}
                >
                  {state}
                </option>
              })}
            </Form.Select>

          </div>

        }
      </div>
      <div className="card--container">
        <div className="card-new-order">

          {/* Si es usuario sede */}


          {isAdmin === "sede" ? (
            <>
              {allOrders.filter((order) => {


                return labsAssigned?.some(lab => lab._id === order.labOrigin._id)
              }).filter((order) => {
                return (new Date(order.createdAt).getTime() >= startDateTime && new Date(order.createdAt).getTime() <= endDateTime)
              }).slice(pagesVisited, pagesVisited + ordersPerPage)
                .map((order => {
                  return <Card
                    key={order._id}
                    style={{ height: '167px' }}
                    className={
                      order._id === orderSelected
                        ? "bg-secondary selected-order mx-auto order--header-card order-card"
                        : "bg-light mx-auto order-card "

                    }
                  // onClick={() => selected(order)}
                  >


                    <Card.Body className={`
                  ${order.comment && order.status === undefined ? 'order-new-pending' : ''}
                  d-flex justify-content-between
                  ${order.state === 'Aceptado sin Stock' ? 'order-status-approved'
                        : order.state === 'Aceptado'
                          ? 'order-status-approved'
                          : order.state === 'Rechazado'
                            ? 'order-status-rejected'
                            : ''
                      }
                    ${(order.pendingProducts.length > 0 || order.pendingOtherProducts.length || order.pendingTags.length) && 'order-status-pending'}
                    ${order.state === 'Enviado' && 'order-status-sent'}
                    ${(order.state === 'Recibido' && "order-status-received")}
                    `

                    }>
                      <div>
                        <Card.Text>
                          <strong>Sede: </strong>{order.labOrigin.name}
                          {order.state === 'Enviado' &&

                            <img src={delyIcon} alt="delivered" className="ms-2"></img>

                          }
                          {order.state === 'Recibido' &&

                            <img src={iconCheck} alt="received" className="ms-2" style={{ width: "1.5rem" }}></img>
                          }
                        </Card.Text>
                        {(order.products.length > 0 || order.tags.filter(tag => tag.data.isProducts).length > 0) && (
                          <Card.Text>
                            <strong>Productos bioquímicos: </strong> {order.products.length + order.tags.length}
                          </Card.Text>
                        )}
                        {(order.otherProducts.length > 0 || order.tags.filter(tag => !tag.data.isProducts).length > 0) && (
                          <Card.Text>
                            <strong>Productos Varios: </strong> {order.otherProducts.length + order.tags.length}
                          </Card.Text>
                        )}
                      </div>
                      <div>
                        <Card.Text>
                          <strong>Creado:</strong> {new Date(order.createdAt).toLocaleDateString("es-ar")}
                        </Card.Text>
                        {/*Si acepte el pedido*/}
                        {order.state === "Aceptado" &&
                          <Card.Text>
                            <strong>Aceptado:</strong> {new Date(order.acceptedAt).toLocaleDateString("es-ar")}
                          </Card.Text>
                        }
                        {order.state === "Aceptado sin Stock" &&
                          <Card.Text>
                            <strong>Aceptado sin Stock:</strong> {new Date(order.acceptedAt).toLocaleDateString("es-ar")}
                          </Card.Text>
                        }
                        {order.receivedAt &&
                          <Card.Text>
                            <strong>Recibido:</strong> {new Date(order.receivedAt).toLocaleDateString("es-ar")}
                          </Card.Text>
                        }
                        <Link to={`/orders/edit/${id}`}>
                          <Button
                            className="btn btn--primary"
                            children="Ver pedido"
                            onMouseOver={() => select(order)}
                          />
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                }
                ))}
            </>) : (
            <>
              {/* Si es administrador o logística */}


              {allOrders.length > 0 ? allOrders.filter(order => {
                if (isAdmin !== "logistic") return true


                // Filtra laboratorios solo del usuario logística
                return labsAssigned?.some(lab => lab._id === order.labOrigin._id)


              }).filter((order) => {
                return (new Date(order.createdAt).getTime() >= startDateTime && new Date(order.createdAt).getTime() <= endDateTime)
              }).slice(pagesVisited, pagesVisited + ordersPerPage)
                .map((order => {
                  return <Card
                    key={order._id}
                    className="mx-auto order-card" /* {
                    order._id === orderSelected
                      ? "bg-secondary selected-order mx-auto order-card"
                      : "bg-light mx-auto order-card"
                  } */
                    style={{ height: '167px' }}
                    onClick={() => selected(order)}
                  >
                    <Card.Body className={`
                  ${order.comment && order.status === undefined ? 'order-new-pending' : ''}
                  d-flex justify-content-between
                  ${order.state === 'Aceptado sin Stock' ?
                        'order-status-without-stock' : order.state === 'Aceptado' ?
                          'order-status-approved' : order.state === 'Rechazado' ?
                            'order-status-rejected' : ''
                      }
                    ${(order.pendingProducts.length > 0 || order.pendingOtherProducts.length || order.pendingTags.length) && 'order-status-pending'}
                    ${order.state === 'Enviado' && 'order-status-sent'}
                    ${(order.state === 'Recibido' && "order-status-received")}
                    `}>
                      <div className="number-card">
                        <Card.Text >
                          <strong> Nº: </strong>{order.codigoPedido}
                        </Card.Text>
                      </div>
                      <div>
                        <Card.Text>
                          <strong>Sede: </strong>{order.labOrigin?.name || "Nombre no disponible"}
                          {order.state === 'Enviado' &&

                            <img src={delyIcon} alt="delivered" className="ms-2"></img>

                          }
                          {order.state === 'Recibido' &&

                            <img src={iconCheck} alt="received" className="ms-2" style={{ width: "1.5rem" }}></img>
                          }
                        </Card.Text>
                        <Card.Text>
                          {(order.products.length > 0 || order.tags.filter(tag => tag.data.isProducts).length > 0) ? (
                            <strong>Productos bioquímicos: {order.products.length + order.tags.length}</strong>
                          ) :
                            ((order.otherProducts.length > 0 || order.tags.filter(tag => !tag.data.isProducts).length > 0) ? (
                              <strong>Productos Varios: {order.otherProducts.length + order.tags.length}</strong>
                            ) : "")}</Card.Text>
                        {order.state === undefined && order.comment ?
                          (<Card.Text>
                            <span>{order.comment}</span>
                          </Card.Text>) : ""
                        }
                        {order.state === "Aceptado sin Stock" &&
                          <Card.Text>
                            <strong>Sin Stock para completar</strong>
                          </Card.Text>
                        }
                        {(order.pendingProducts.length > 0 || order.pendingOtherProducts.length) ?
                          (<Card.Text>
                            <strong>Aceptado con productos pendientes</strong>
                          </Card.Text>) : ""
                        }


                      </div>
                      <div>
                        <Card.Text>
                          <strong>Creado:</strong> {new Date(order.createdAt).toLocaleDateString("es-ar")}
                        </Card.Text>
                        {/*Si acepte el pedido*/}
                        {order.state === "Aceptado" &&
                          <Card.Text>
                            <strong>Aceptado:</strong> {new Date(order.acceptedAt).toLocaleDateString("es-ar")}
                          </Card.Text>
                        }
                        {order.state === "Aceptado sin Stock" &&
                          <Card.Text>
                            <strong>Aceptado:</strong> {new Date(order.acceptedAt).toLocaleDateString("es-ar")}

                          </Card.Text>
                        }
                        {order.receivedAt &&
                          <Card.Text>
                            <strong>Recibido:</strong> {new Date(order.receivedAt).toLocaleDateString("es-ar")}
                          </Card.Text>
                        }
                        <Link to={`/orders/edit/${id}`}>
                          <Button
                            className="btn btn--primary"
                            children="Ver pedido"
                            onMouseOver={() => select(order)}
                          />
                        </Link>

                      </div>
                    </Card.Body>

                  </Card>

                }

                )) : (<div className="text-order">No se encontraron pedidos. <b><button onClick={handleClearFilteredData} className="btn-text-order">Limpiar filtro</button></b></div>)}
              <ExportOrderModal
                show={orderToPdfModal}
                onHide={() => setOrderToPdfModal(false)}
                labs={isAdmin === "logistic" ? labsAssigned : AllLabs}
              />
            </>

          )}
        </div>
        <AbmButtons
          item={orderSelected}
          role={isAdmin}
          funcion={deleteOrder}
          abm={"pedidos"}
        />
      </div>
      <Pagination className="pagination-all-tag">
        <Pagination.Prev
          onClick={() => changePage(pageNumber > 0 ? pageNumber - 1 : 0)}
          disabled={pageNumber === 0}
        />
        {Array.from({ length: pageCountForButton }).map((_, index) => (
          <Pagination.Item
            key={index}
            active={index === pageNumber}
            onClick={() => changePage(index)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => changePage(pageNumber < pageCountForButton - 1 ? pageNumber + 1 : pageCountForButton - 1)}
          disabled={pageNumber === pageCountForButton - 1}
        />
      </Pagination>
     
    </div >);
}
