import React, { useState, useRef, useCallback, useEffect } from "react"
import Webcam from "react-webcam"
import axios from "axios"
import "./camera.css"
import errorAlert from "../../Assets/Img/error-alert.svg"
import CargaViaExcel from "../CargaViaExcel/CargaViaExcel"
import { Button, Modal, Spinner } from "react-bootstrap"
import ProductsTable from "./Components/ProductsTable"
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import { Link } from 'react-router-dom';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { Tooltip } from "@mui/material";


const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

const Camera = () => {
	const [showCamera, setShowCamera] = useState(false)
	const [showUploadButton, setShowUploadButton] = useState(true)
	const [productsFromScan, setProductsFromScan] = useState([])
	const [noTextDetected, setNoTextDetected] = useState(false)
	const [noProductsScanned, setNoProductsScanned] = useState(false)
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [hasCamera, setHasCamera] = useState(true)

	const [productTypeSelected, setProductTypeSelected] = useState("")
	const [show, setShow] = useState(false)
	const [showPdf, setShowPdf] = useState(false)

	const webcamRef = useRef(null)
	const fileInputRef = useRef(null)

	useEffect(() => {
		checkCameraConnected()
		// Agregar el evento para escuchar cambios en los dispositivos multimedia
		navigator.mediaDevices.addEventListener("devicechange", checkCameraConnected)

		// Limpiar el evento al desmontar el componente
		return () => {
			navigator.mediaDevices.removeEventListener("devicechange", checkCameraConnected)
		}
	}, [])

	const isVirtualCamera = label => {
		// Definir tus criterios para identificar cámaras virtuales
		return label.toLowerCase().includes("virtual") || label.toLowerCase().includes("obs")
	}

	const checkCameraConnected = () => {
		navigator.mediaDevices
			.enumerateDevices()
			.then(devices => {
				const physicalVideoDevices = devices.filter(device => device.kind === "videoinput" && !isVirtualCamera(device.label))
				const hasVideoDevice = physicalVideoDevices.length > 0
				setHasCamera(hasVideoDevice)
			})
			.catch(error => {
				console.log("Error al obtener la lista de dispositivos multimedia:", error)
			})
	}

	const capture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot()
		performOCR(imageSrc, false)
		handleCameraClose()
	}, [webcamRef, productTypeSelected])

	const handleFileUpload = () => {
		const file = fileInputRef.current.files[0]
		const reader = new FileReader()

		reader.onload = event => {
			const fileData = event.target.result

			performOCR(fileData, true)
		}

		reader.readAsDataURL(file)
		fileInputRef.current.value = ""
	}

	const performOCR = (imageSrc, isPDF) => {
		setIsLoadingData(true)

		const request = isPDF
			? [
				{
					inputConfig: {
						mimeType: "application/pdf",
						content: imageSrc.split(",")[1],
					},
					features: [
						{
							type: "DOCUMENT_TEXT_DETECTION",
						},
					],
				},
			] 
			: [
				{
					image: {
						content: imageSrc.split(",")[1],
					},
					features: [
						{
							type: "TEXT_DETECTION",
						},
					],
				},
			]

		axios
			.post(`https://vision.googleapis.com/v1/${isPDF ? "files" : "images"}:annotate?key=AIzaSyA9LwvOuUVYMVcEoGsy8DR4q2o45hVqerE`, {
				requests: request,
			})
			.then(response => {
				setIsLoadingData(false)

				const scannedText = isPDF ? response.data.responses[0].responses[0].fullTextAnnotation.text : response.data.responses[0].fullTextAnnotation.text

				if (scannedText) {
					axios
						.post(`${BACK_APP_URI}/documentScanner/findProducts`, {
							data: {
								scannedText: scannedText,
								typeSelected: productTypeSelected,
							},
						})

						.then(response => {
							console.log(response.data.products)
							if (!response.data.products.length) {
								setNoProductsScanned(true)
							}
							setProductsFromScan(response.data.products)
						})
						.catch(error => {
							console.log(error)
						})
				} else {
					setNoTextDetected(true)
					setShowCamera(false)
				}
			})
			.catch(error => {
				console.log(error)
				setShowCamera(false)
			})
	}

	const handleSelectTypeOfProduct = () => {
		setShow(true)
		setProductTypeSelected("")
	}

	// Elijo el tipo de producto
	const handleSelectTypeOfProductForFileUpload = () => {
		setShowPdf(true)
		setProductTypeSelected("")
	}

	// Manejo el botón bioquimicos - PDF
	const handleSelectProductsPdf = () => {
		setProductTypeSelected("bioquimicos")
		setNoProductsScanned(false)
		handleClose()
		setProductsFromScan([])
		fileInputRef.current.click()
	}

	// Manejo el botón varios - PDF
	const handleSelectOtherProductsPdf = () => {
		setProductTypeSelected("varios")
		setNoProductsScanned(false)
		handleClose()
		setProductsFromScan([])
		fileInputRef.current.click()
	}

	const handleSelectProducts = () => {
		setProductTypeSelected("bioquimicos")
		setShowCamera(true)
		setShowUploadButton(false)
		setNoTextDetected(false)
		setProductsFromScan([])
		setNoProductsScanned(false)
		handleClose()
	}

	const handleSelectOtherProducts = () => {
		setShowCamera(true)
		setShowUploadButton(false)
		setNoTextDetected(false)
		setNoProductsScanned(false)
		setProductsFromScan([])
		setProductTypeSelected("varios")
		handleClose()
	}

	const handleClose = () => {
		setShow(false)
		setShowPdf(false)

	}
	const handleCameraClose = () => {
		setShowCamera(false)
		setShowUploadButton(true)
		setNoTextDetected(false)
	}

	return (
		<>


			<div className="main-container">
				<div className="camera-btn-cont">
					<div className="camera-btn-row">
					{!showCamera && ( /* para ocultar titulo y boton de retroceso cuando la camara esta activa*/ 
					<div className="camera-btn-header">
						<h3 className="title-camera"> Carga de productos</h3>
						
						<Tooltip title="Volver" arrow>
						<Link 
							className="btn-back-camera"
							component={Link}
								to="/productos"
						> 
							<KeyboardBackspaceRoundedIcon className="btn-back-icon"/>
							
						</Link>
						</Tooltip>
						</div>
                        )}
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
								'& > :not(style)': {
									m: 1,
									width: 285,
									height: 70,
								},
							}}
						>
							{showUploadButton && (
							<Paper elevation={1} sx={{ padding: 2, textAlign: 'center' }}>
								
									<div className="content-card-camera">
										<input
											ref={fileInputRef}
											type="file"
											accept=".pdf, image/*"
											style={{ display: "none" }}
											onChange={handleFileUpload}
										/>
										<h3 className="text-card-camera">Cargar por pdf</h3>
										<Button
											className="btn-camera"
											onClick={handleSelectTypeOfProductForFileUpload}
										>

											<UploadFileOutlinedIcon
											/>
										</Button>
									</div>
								
							</Paper>
							)}
							<Paper elevation={1} sx={{ padding: 2, textAlign: 'center' }}>
								{!showCamera && hasCamera && (
									<div className="content-card-camera">
										<h3 className="text-card-camera">Cargar por cámara</h3>
										<Button
											onClick={handleSelectTypeOfProduct}
											className="btn-camera"
										>
											<AddAPhotoOutlinedIcon
											/>
										</Button>
									</div>
								)}
								{showCamera && (
									<div className="camera-btn">
										<Tooltip title="Aceptar" arrow>
										<Button
											onClick={capture}
											disabled={!showCamera}
											className="btn-camera"
										>
											<CheckCircleOutlinedIcon
											/>
										</Button>
										</Tooltip>
										<Tooltip title="Cancelar" arrow>
										<Button
											onClick={handleCameraClose}
											className="btn-camera"
										>
											<NoPhotographyOutlinedIcon
											/>
										</Button>
										</Tooltip>
									</div>
								)}
								
							</Paper>
							{!showCamera && (
							<Paper elevation={1} sx={{ padding: 2, textAlign: 'center' }}>
								<div className="content-card-camera">
									<h3 className="text-card-camera">Cargar vía excel</h3>
									<CargaViaExcel />
								</div>
							</Paper>
	                           )}

						</Box>
					</div>
				</div>
				{noTextDetected && (
					<div className="alert-cont">
						<div className="alert-pop">
							<img
								src={errorAlert}
								className="alert-icon"
								alt=""
							/>
							<p>No se encontró texto en la imagen capturada.</p>
						</div>
					</div>
				)}

				{!hasCamera && (
					<div className="alert-cont">
						<div className="alert-pop">
							<img
								src={errorAlert}
								className="alert-icon"
								alt=""
							/>
							<p>No se encontró ninguna cámara conectada.</p>
						</div>
					</div>
				)}

				{noProductsScanned && (
					<div className="alert-cont">
						<div className="alert-pop">
							<img
								src={errorAlert}
								className="alert-icon"
								alt=""
							/>
							<div className="divider-alert"></div>
							<p>No se encontraron productos en el archivo.</p>
							<div className="divider-alert"></div>
						</div>
					</div>
				)}

				{isLoadingData && (
					<div className="alert-cont">
						<div className="alert-pop">
							<Spinner
								animation="border"
								role="status"
								variant="dark"
							>
								<span className="visually-hidden">Loading...</span>
							</Spinner>
							<p>Cargando productos</p>
						</div>
					</div>
				)}

				<div className="camera-cont">
					{showCamera && hasCamera && (
						<div className="camera">
							<Webcam
								audio={false}
								height={490}
								ref={webcamRef}
								screenshotFormat="image/jpeg"
								width={1324}
								videoConstraints={{
									width: 1280,
									height: 660,
									facingMode: "environment",
								}}
								style={{ marginTop: '30%',  paddingBottom: '2rem' }}
							/>
						</div>
					)}
				</div>

				{!showCamera && hasCamera && (
					<Modal style={{ zIndex: '1100' , marginTop: '10%'}}
						show={show}
						onHide={handleClose}
					>
						<Modal.Header closeButton>
							<Modal.Title>Carga por cámara</Modal.Title>
						</Modal.Header>
						<Modal.Body>Selecciona el tipo de producto</Modal.Body>
						<Modal.Footer className="d-flex justify-content-center">
							<Button
								className="btn btn--primary mb-1"
								style={{ width: "10em" }}
								onClick={handleSelectProducts}
							>
								Bioquímicos
							</Button>
							<Button
								className="btn btn--primary mb-1"
								style={{ width: "10em" }}
								onClick={handleSelectOtherProducts}
							>
								Varios
							</Button>
						</Modal.Footer>
					</Modal>
				)}

				{/* Modal para subir por PDF */}
				<Modal style={{ marginTop: '10%'}}
					className="camara-pdf"
					show={showPdf}
					onHide={handleClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>Carga por PDF</Modal.Title>
					</Modal.Header>
					<Modal.Body>Selecciona el tipo de producto</Modal.Body>
					<Modal.Footer className="d-flex justify-content-center">
						<Button
							className="btn btn--primary mb-1"
							style={{ width: "10em" }}
							onClick={handleSelectProductsPdf}
						>
							Bioquímicos
						</Button>
						<Button
							className="btn btn--primary mb-1"
							style={{ width: "10em" }}
							onClick={handleSelectOtherProductsPdf}
						>
							Varios
						</Button>
					</Modal.Footer>
				</Modal>

				<ProductsTable
					data={productsFromScan}
					closeOperation={() => setProductsFromScan([])}
					productType={productTypeSelected}
				/>
			</div >
		</>

	)
}

export default Camera
