import React, { useEffect, useState } from "react"
import { Button, Modal, Table } from "react-bootstrap"
import editIcon from "./edit.png"
import AddIcon from "./../../../../Assets/Img/iconAddBlack.svg"

export default function ItemRow({ item, setItemToEdit, setUsoSmnalToEdit, setShowModal, isOtherProducts, isNotEditable, backgroundColorClass }) {
	const [showLots, setShowLots] = useState(false)
	const [showProductsFromTag, setShowProductsFromTag] = useState(false)

	const [hasStock, setHasStock] = useState(true)
	const [isLotsUpToDate, setIsLotsUpToDate] = useState(true)

	useEffect(() => {
		setHasStock(() => (item.quantity < item.usoSmnal ? false : true))
		if (isOtherProducts) return
		setIsLotsUpToDate(() => !item?.lots?.some(lot => new Date(lot.expireDate) < new Date()))
	})

	return (
		<>
			<tr
				key={item._id}
				className={`tr-table ${backgroundColorClass}`}
			>
				<td>{item.name}</td>

				<td>{item.provider.name}</td> 
				<td>{`${item.quantity} (${isNotEditable ? "ud." : item?.unitType?.name || item?.measure?.name })`}</td>
				<td>{!isNotEditable ?  `${item.usoSmnal} (${isNotEditable ? "ud." : item?.unitType?.name || item?.measure?.name })` : <div>-</div>}</td>
				<td className="quantity-th">
					{!isNotEditable && hasStock && isLotsUpToDate && <div className="correcto">EN STOCK</div>}
					{!isNotEditable && !hasStock && <div className="error mb-1">SIN STOCK</div>}
					{!isLotsUpToDate && <div className="error">LOTES VENCIDOS</div>}
					{isNotEditable && isLotsUpToDate && <div>-</div>}
				</td>
				<td>
					{!isNotEditable ? (
						<Button
							variant="outline-light"
							children="Editar"
							onClick={() => {
								setShowModal(true)
								setItemToEdit(item)
								setUsoSmnalToEdit(item.usoSmnal)
							}}
						>
							<img
								src={editIcon}
								alt="Editar"
							/>
						</Button>
					) : <div>-</div>}
				</td>
				<td>
					{item.lots || item.hasOwnProperty("isProducts") ? (
						<Button
							variant="outline-light"
							children="Ver Lotes"
							onClick={() => {
								if (item.lots) {
									setShowLots(prev => !prev)
								} else {
									setShowProductsFromTag(prev => !prev)
								}
							}}
						>
							<img
								className="w-3"
								src={AddIcon}
								alt="Ver Lotes"
							/>
						</Button>
					) : (
						<div>-</div>
					)}
				</td>
			</tr>

			{showLots && (
				<tr className={backgroundColorClass} >
					<td
						colSpan={8}
						style={{
							padding: 0,
						}}
					>
						<Table
							size="sm"
							style={{
								margin: 0,
								border: "gray",
							}}
							bordered
						>
							<tbody>
								<tr>
									<td>Numero de Lote</td>
									<td>Fecha de Ingreso</td>
									<td>Fecha de Vencimiento</td>
									<td>Cantidad</td>
									<td>Cantidad de lotes</td>
								</tr>
								{item?.lots.map(lot => {
									return (
										<tr key={lot._id}>
											<td>{lot.lotNumber}</td>
											<td>{`${new Date(lot.acquireDate).getDate()}/${new Date(lot.acquireDate).getMonth() + 1}/${new Date(lot.acquireDate).getFullYear()}`}</td>
											<td className={new Date(lot.expireDate) < new Date() ? "color-red" : ""}>{`${new Date(lot.expireDate).getDate()}/${
												new Date(lot.expireDate).getMonth() + 1
											}/${new Date(lot.expireDate).getFullYear()}`}</td>
											<td>{lot.quantity}</td>
											<td>{lot.lotQuantity}</td>
										</tr>
									)
								})}
							</tbody>
						</Table>
					</td>
				</tr>
			)}

			{showProductsFromTag &&
				(item.products.length
					? item.products.map(product => {
							return (
								<ItemRow
								  key={product._id}
									backgroundColorClass={backgroundColorClass}
									isOtherProducts={false}
									item={product}
									isNotEditable
								/>
							)
					  })
					: item.otherProducts.map(product => {
							return (
								<ItemRow
									key={product._id}
									backgroundColorClass={backgroundColorClass}
									isOtherProducts={true}
									item={product}
									isNotEditable
								/>
							)
					  }))}
		</>
	)
}
