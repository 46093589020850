import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import axios from "axios"
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Button from "react-bootstrap/Button"
import "./EditTags.css"
import Cookies from "js-cookie"


import { useForm } from "react-hook-form"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI


const EditTags = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [name, setName] = useState("")
    const [measure, setMeasure] = useState("")
    const [category, setCategory] = useState()
    const [allProviders, setAllProviders] = useState([])
    const [provider, setProvider] = useState("")
    const [isProducts, setIsProducts] = useState()
    const [allUnits, setAllUnits] = useState([])
    const [allCategories, setAllCategories] = useState([])


    const [variable, setVariable] = useState(false)
    const [errorBool, setErrorBool] = useState(false)
    const [avisomsg, setAvisomsg] = useState("")


    const [isLoading, setIsLoading] = useState(false)


    const token = Cookies.get("token")


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
    } = useForm()


    const onSubmit = async () => {
        try {
            setIsLoading(true)
            await axios({
                method: "put",
                url: `${BACK_APP_URI}/tags/edit/${id}`,
                data: {
                    name: name,
                    measure: measure,
                    isProducts: isProducts,
                    category: category,
                    provider: provider,
                },
            })


            setVariable(true)
            setErrorBool(false)
            setAvisomsg("Etiqueta editada exitosamente")
            setTimeout(() => {
                setVariable(false)
                navigate("/tags")
            }, 1800)
        } catch (error) {
            console.log(error)
            setErrorBool(true)
            setVariable(true)
            setAvisomsg(error.response.data.msg)
            setTimeout(() => {
                setVariable(false)
                setIsLoading(false)
            }, 3200)
        }
    }


    useEffect(() => {
        ;(async () => {
            await axios
                .get(`${BACK_APP_URI}/tags/${id}`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                })
                .then(result => {
                    const { tag } = result.data
                    setName(tag.name)
                    setMeasure(tag.measure)
                    setCategory(tag.category)
                    setIsProducts(tag.isProducts)
                    setProvider(tag.provider)
                    setValue("name", tag.name)
                    setValue("unit", tag.measure._id)
                    setValue("isProducts", tag.isProducts)
                    setValue("provider", tag.provider)
                    setValue("category", tag.category)
                })
        })()
    }, [id, setValue])


    useEffect(() => {
        ;(async () => {
            await axios
                .get(`${BACK_APP_URI}/unidades`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                })
                .then(result => {
                    setAllUnits(result.data.units)
                })
            await axios
                .get(`${BACK_APP_URI}/categories`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                })
                .then(result => {
                    setAllCategories(result.data.categories)
                })
            await axios
                .get(`${BACK_APP_URI}/proveedor`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                })
                .then(result => {
                    setAllProviders(result.data.provider)
                })
        })()
    }, [])


    const validateName = value => {
        const regex = /^[a-zA-Z0-9]*$/ // Matches only letters and numbers
        if (!regex.test(value)) {
            setError("name", { type: "invalid" })
            return false
        }
        return true
    }


    return (
        <div className="main-container">
            <div className="edit-tag-header">
                <h1>Editar Etiqueta</h1>
                <div className="divider-tags"></div>
                <div className="edit-tag-tarjet">
                    <h2 className="h1-unidad">Etiqueta</h2>
                    <Form
                        className="edit-tag-form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="edit-tag-floating">
                            <FloatingLabel label="Nombre">
                                <Form.Control
                                    value={name}
                                    className="edit-tag-form-control"
                                    placeholder="name"
                                    name="name"
                                    type="text"
                                    {...register("name", {
                                        required: true,
                                        maxLength: 40,
                                        validate: validateName,
                                    })}
                                    onChange={e => {
                                        setName(e.target.value)
                                    }}
                                />
                                {errors.name?.type === "required" && <p class="error">Campo requerido </p>}
                                {errors.name?.type === "maxLength" && <p class="error">Cantidad minima de caracteres es de 40</p>}
                                {errors.name?.type === "validate" && <p className="error">Nombre Invalido</p>}
                            </FloatingLabel>


                            <FloatingLabel label="Tipo de unidad">
                                <Form.Select
                                    value={measure._id}
                                    className="select-new"
                                    {...register("unit", {
                                        required: true,
                                    })}
                                    onChange={e => {
                                        setMeasure(e.target.value)
                                    }}
                                >
                                    {allUnits.map(unit => {
                                        return (
                                            <option
                                                key={unit._id}
                                                value={unit._id}
                                            >
                                                {unit.name}
                                            </option>
                                        )
                                    })}
                                </Form.Select>
                                {errors.unitType?.type === "required" && <p class="error">Campo requerido </p>}
                            </FloatingLabel>


                            <FloatingLabel label="Proveedor">
                                <Form.Select
                                    className="select-new"
                                    {...register("provider", {
                                        required: true,
                                    })}
                                    value={provider}
                                    onChange={e => {
                                        if (e.target.value === "add") {
                                            navigate("/provider/add")
                                        }
                                        setProvider(e.target.value)
                                    }}
                                >
                                    <option
                                        value=""
                                        disabled
                                    >
                                        Seleccionar
                                    </option>


                                    {allProviders.map(provider => (
                                        <option
                                            key={provider._id}
                                            value={provider._id}
                                        >
                                            {provider.name}
                                        </option>
                                    ))}
                                    <option value="add">Agregar proveedor</option>
                                </Form.Select>
                                {errors.provider?.type === "required" && <p className="error">Campo requerido</p>}
                            </FloatingLabel>


                            <div className="check-tag">
                            <h3 className="text-check">Tipo de etiqueta</h3>
                            <div className="checkbox">
                                <Form.Group name="isProducts">
                                    <Form.Check
                                        checked={isProducts}
                                        type="radio"
                                        label="Bioquímicos"
                                        {...register("isProducts", {
                                            required: true,
                                        })}
                                        onChange={e => {
                                            setIsProducts(true)
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group name="isProducts">
                                    <Form.Check
                                        checked={!isProducts}
                                        type="radio"
                                        label="Varios"
                                        {...register("isProducts", {
                                            required: true,
                                        })}
                                        onChange={e => {
                                            setIsProducts(false)
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            </div>
                            {errors.isProducts?.type === "required" && <p class="error">Campo requerido </p>}


                            {isProducts === false && (
                                <FloatingLabel
                                    label="Categoría"
                                    className="mb-3"
                                >
                                    <Form.Select
                                        className="edit-tag-form-select"
                                        {...register("category", {
                                            required: true,
                                        })}
                                        onChange={e => {
                                            setCategory(e.target.value)
                                        }}
                                        value={category}
                                    >
                                        <option value="">Seleccionar</option>
                                        {allCategories.map(cat => {
                                            return (
                                                <option
                                                    key={cat._id}
                                                    value={cat._id}
                                                >
                                                    {cat.name}
                                                </option>
                                            )
                                        })}
                                    </Form.Select>
                                    {errors.category?.type === "required" && <p class="error">Campo requerido </p>}
                                </FloatingLabel>
                            )}
                        </div>


                        <div className="edit-tag-btn">
                            <div className="btn-edit">
                                <button
                                    children="Cancelar"
                                    type="submit"
                                    className="button-edit-text"
                                    onClick={() => {
                                        navigate("/tags")
                                    }}
                                />
                                <button
                                    children="Guardar"
                                    type="submit"
                                    className="button-edit"
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                    </Form>
                </div>


                <div />
            </div>
            {variable && (
                <AnimacionSuccessful
                    errorBool={errorBool}
                    avisomsg={avisomsg}
                />
            )}
        </div>
    )
}


export default EditTags



