import React, { useEffect, useState } from "react"
import axios, { all } from "axios"
import Table from "react-bootstrap/Table"
import Button from "react-bootstrap/esm/Button"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Card from "react-bootstrap/Card"
import Pagination from "react-bootstrap/Pagination"
import "./StockProducts.css"
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import Cookies from "js-cookie"
import { useLocalStorage } from "../../../CustomHook/useLocalStorage"
import { writeFile, utils } from "xlsx"
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PdfIcon from "../../../Assets/Img/pdf17.svg"
import warningIcon from "../../../Assets/Img/warning.svg"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { Link } from "react-router-dom"
import StockoutPDF from "./Components/StockoutPdf"
import { Nav } from "react-bootstrap"
import ItemRow from "./Components/ItemRow"

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export const StockProducts = props => {
	const [tagsAndProductsWithoutTag, setTagsAndProductsWithoutTag] = useState([])
	const [productSelected, setProductSelected] = useState(null)
	const [actualizador, setActualizador] = useState(0)
	const [searchTerm, setSearchTerm] = useState("")
	const [currentPage, setCurrentPage] = useState(1)
	const [productsPerPage] = useState(8)
	const [warningCaption, setWarningCaption] = useState(false)
	const [expiredCaption, setExpiredCaption] = useState(false)
	const [expiredProductsToPDF, setExpiredProductsToPDF] = useState([])
	const [zeroStockProducts, setZeroStockProducts] = useState([])
	const [selectedProvider, setSelectedProvider] = useState("")
	const [filteredProducts, setFilteredProducts] = useState([])
	const [filteredExpiredProducts, setFilteredExpiredProducts] = useState([])
	const [filteredZeroStock, setFilteredZeroStock] = useState([])
	const [allProviders, setAllProviders] = useState([])
	const [tabSection, setTabSection] = useState("Bioquimicos")

	const [productsToOrder, setProductToOrder] = useState([])

	const [productToEdit, setProductToEdit] = useState("")
	const [usoSmnalToEdit, setUsoSmnalToEdit] = useState(0)

	//----------------------------------------------------------------

	const currentDate = new Date().getTime()
	const thirtyDays = 30 * 24 * 60 * 60 * 1000
	const periodToExpireDate = currentDate + thirtyDays

	const thirtyDaysDate = new Date(periodToExpireDate)

	useEffect(() => {
		async function getDataProducts() {
			sessionStorage.setItem("QuiebreStock", [])
			const token = Cookies.get("token")

			const {
				data: { tags: allTags },
			} = await axios.get(`${BACK_APP_URI}/tags`, {
				headers: {
					Authorization: `${token}`,
				},
			})

			let tagsAndProductsWithoutTagArr = []

			if (tabSection === "Bioquimicos") {
				let products = []

				allTags.find(tag => tag.name === "-").products.forEach(product => products.push(product))
				allTags.filter(tag => tag.isProducts).forEach(tag => tag.products.forEach(product => products.push(product)))

				const productToExpire = products.some(product => {
					return product?.lots?.some(lot => new Date(lot.expireDate).getTime() <= periodToExpireDate)
				})

				const productExpired = products.some(product => {
					return product?.lots?.some(lot => new Date(lot.expireDate).getTime() <= currentDate)
				})

				const productsExpiredToPdf = products.filter(product => {
					return product?.quantity > 0 && new Date(product.expireDate).getTime() <= currentDate
				})

				setExpiredProductsToPDF(productsExpiredToPdf)
				setWarningCaption(productToExpire)
				setExpiredCaption(productExpired)

				tagsAndProductsWithoutTagArr = [...allTags.filter(tag => tag.name !== "-" && tag.isProducts), ...allTags.find(tag => tag.name === "-").products]
			} else {
				tagsAndProductsWithoutTagArr = [...allTags.filter(tag => tag.name !== "-" && !tag.isProducts), ...allTags.find(tag => tag.name === "-").otherProducts]

				setWarningCaption(false)
				setExpiredCaption(false)
			}

			setTagsAndProductsWithoutTag(tagsAndProductsWithoutTagArr)
			// const sinStockProducts = dataFetched.products.filter(
			//   (product) => product.quantity < product.usoSmnal
			// );

			// setZeroStockProducts(sinStockProducts);

			// if (sinStockProducts.length > 0) {
			//   window.sessionStorage.setItem(
			//     "QuiebreStock",
			//     JSON.stringify(sinStockProducts)
			//   );
			// }
		}

		getDataProducts()
	}, [actualizador])

	// Obtener todos los proveedores disponibles
	useEffect(() => {
		async function getDataProviders() {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/proveedor`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setAllProviders(result.data.provider)
				})
		}
		getDataProviders()
	}, [])

	useEffect(() => {
		const productsToOrderData = []

		tagsAndProductsWithoutTag.forEach(product => {
			if (product.provider._id !== selectedProvider) return

			if (tabSection === "Bioquimicos") {
				if (
					product.quantity < product.usoSmnal ||
					product?.products?.some(subProduct => subProduct?.lots?.some(lot => new Date(lot.expireDate) < new Date())) ||
					product?.lots?.some(lot => new Date(lot.expireDate) < new Date())
				) {

					function formatProduct(subProduct, lot, tag) {
						const quantity = tag?.quantity || subProduct?.quantity
						const usoSmnal = tag?.usoSmnal || subProduct?.usoSmnal
						return {
							product: subProduct,
							lot: {
								...lot,
								acquireDate: `${new Date(lot.acquireDate).getDate()}/${new Date(lot.acquireDate).getMonth() + 1}/${new Date(
									lot.acquireDate
								).getFullYear()}`,
								expireDate: `${new Date(lot.expireDate).getDate()}/${new Date(lot.expireDate).getMonth() + 1}/${new Date(lot.expireDate).getFullYear()}`,
								state: quantity < usoSmnal ? "SIN STOCK" : new Date(lot.expireDate) < new Date() ? "VENCIDO" : "-",
							},
						}
					}

					if (product.hasOwnProperty("isProducts")) {
						// Es tag
						product.products.forEach(subProduct =>
							subProduct.lots.forEach(lot =>
								productsToOrderData.push(formatProduct(subProduct, lot, product))
							)
						)
					} else {
						// Es producto bioquímico
						product.lots.forEach(lot =>
							productsToOrderData.push(formatProduct(product, lot))
						)
					}

				}
			} else {
				if (product.quantity < product.usoSmnal) {
					productsToOrderData.push({
						product: {
							...product,
							state: "SIN STOCK",
						},
					})
				}
			}
		})

		setProductToOrder(productsToOrderData)
	}, [tagsAndProductsWithoutTag, selectedProvider])

	const handleProviderChange = event => {
		const provider = event.target.value
		setSelectedProvider(provider)
	}

	useEffect(() => {
		// Filtro para PDF
		const filteredProviderExpiredProducts = expiredProductsToPDF.filter(product => product.provider._id === selectedProvider)

		setFilteredExpiredProducts(filteredProviderExpiredProducts)

		const filteredProviderZeroStockProducts = zeroStockProducts.filter(product => product.provider._id === selectedProvider)

		setFilteredZeroStock(filteredProviderZeroStockProducts)
	}, [selectedProvider])

	// ------------ MODAL ----------------

	const [showModal, setShowModal] = useState(false)
	const handleClose = () => setShowModal(false)
	const handleShow = () => setShowModal(true)

	//------------ BUSQUEDA Y PAGINACION-----------

	useEffect(() => {
		setCurrentPage(1) // Reiniciar la página a 1 cada vez que cambie el término de búsqueda
	}, [searchTerm])
	const handlePageChange = pageNumber => {
		setCurrentPage(pageNumber)
	}

	const debounce = (callback, delay) => {
		let timer
		return function (...args) {
			clearTimeout(timer)
			timer = setTimeout(() => callback(...args), delay)
		}
	}

	const handleFilterChange = event => {
		setSearchTerm(event.target.value)
	}

	const delayedHandleFilterChange = debounce(handleFilterChange, 500)

	const tagsAndProductsWithoutTagFiltered = tagsAndProductsWithoutTag.filter(product => {
		// Aplicar filtro por nombre, fecha de vencimiento, etc.
		return (
			product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			new Date(product.expireDate).toLocaleDateString("es-AR").includes(searchTerm.toLowerCase())
			// Agrega otros criterios de búsqueda si es necesario
		)
	})

	const indexOfLastProduct = currentPage * productsPerPage
	const indexOfFirstProduct = indexOfLastProduct - productsPerPage
	const currentProducts = tagsAndProductsWithoutTagFiltered.slice(indexOfFirstProduct, indexOfLastProduct)
	const totalPages = Math.ceil(tagsAndProductsWithoutTagFiltered.length / productsPerPage)
	const pagesToShow = 5;


	const renderPaginationItems = () => {
		const paginationItems = [];
		let startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
		let endPage = startPage + pagesToShow - 1;
	
		// Asegurarse de que no se muestren páginas fuera de rango
		if (endPage >= totalPages) {
			endPage = totalPages;
			startPage = totalPages - pagesToShow + 1;
		}
	
		for (let page = startPage; page <= endPage; page++) {
			paginationItems.push(
				<Pagination.Item
					key={page}
					active={page === currentPage}
					onClick={() => handlePageChange(page)}
				>
					{page}
				</Pagination.Item>
			);
		}
	
		return paginationItems;
	};
	

	const updateProduct = async (product, usoSmnal) => {
		if (product.hasOwnProperty("tag")) {
			// Si es producto
			if (tabSection === "Bioquimicos") {
				await axios({
					method: "put",
					url: `${BACK_APP_URI}/productos/edit/${product._id}`,
					data: {
						usoSmnal: usoSmnal,
					},
				})
			} else {
				await axios({
					method: "put",
					url: `${BACK_APP_URI}/otros_productos/edit/${product._id}`,
					data: {
						usoSmnal: usoSmnal,
					},
				})
			}
		} else {
			// Si es tag
			await axios({
				method: "put",
				url: `${BACK_APP_URI}/tags/edit/${product._id}`,
				data: {
					usoSmnal: usoSmnal,
				},
			})
		}

		setActualizador(actualizador + 1)
	}

	const handleTabChange = section => {
		setTabSection(section)
		handlePageChange(1)
		setSearchTerm("")
		setActualizador(actualizador + 1)
	}
	// console.log(productsToOrder)
	//-----------------GENERAR-ORDEN-COMPRA---------------

	const generateOrder = () => {
		if (!selectedProvider) {
			window.alert("Por favor, selecciona un proveedor antes de generar la orden de compra")
			return
		}

		if (productsToOrder.length > 0) {
			const productsData = []

			if (tabSection === "Bioquimicos") {
				productsToOrder.map(({ product, lot }) => {
					productsData.push({
						"Código del Producto": product.code,
						Nombre: product.name,
						Proveedor: product.provider.name,
						Marca: product.brand,
						"Tipo de Unidad": product.unitType.name,
						Nbu: product.ubn,
						"Código del Lote": lot.lotNumber,
						"Cantidad de Lotes": lot.lotQuantity,
						"Fecha Ingreso": lot.acquireDate,
						"Fecha Vencimiento": lot.expireDate,
						Cantidad: lot.quantity,
						Estado: lot.state,
					})
				})
			} else {
				productsToOrder.map(({ product }) => {
					productsData.push({
						"Código del Producto": product?.code || "-",
						Nombre: product.name,
						Proveedor: product.provider.name,
						Categoría: product.category,
						Cantidad: product.quantity,
						Estado: product.state,
					})
				})
			}

			// Crear el objeto worksheet con los datos de los productos
			const worksheet = utils.json_to_sheet(productsData)

			// Crear el objeto workbook y asignar el worksheet
			const workbook = utils.book_new()
			utils.book_append_sheet(workbook, worksheet, "Orden de Compra")

			// Guardar el archivo Excel
			writeFile(workbook, `orden_compra_${selectedProvider}.xlsx`)
		} else {
			window.alert("No se requiere generar una orden de compra")
		}
	}

	function isEven(n) {
		return n % 2 == 0
	}

	return (
		<>
			{props.resumen ? (
				<>
					<div
						className="header-stock-new"
						style={{
							maxWidth: "95%",
							flexDirection: "column",
						}}
					>
						<Card>
							<Card.Header className="ch-main-stock" >Quiebre Stock</Card.Header>
							{!sessionStorage.getItem("QuiebreStock") ? (
								<Card.Body>
									<Card.Title className="ct-main-stock">Hay stock</Card.Title>
									<Card.Text></Card.Text>
								</Card.Body>
							) : (
								<Card.Body className="cb-main-stock">
									<Card.Title>Los siguientes Productos no tiene Stock</Card.Title>
									<Card.Subtitle>
										{JSON.parse(sessionStorage.getItem("QuiebreStock")).map((product, index) => (
											<p key={index}>
												{product.name} {product.quantity}
											</p>
										))}
									</Card.Subtitle>
									<Link
										className="btn btn-primary"
										style={{ marginRight: ".5rem" }}
										to={"/productos/stock"}
									>
										Ver Quiebre de Stock
									</Link>
									<Link
										className="btn btn-primary"
										to={"/provider-order"}
									>
										Generar Pedido
									</Link>
								</Card.Body>
							)}
						</Card>
					</div>
				</>
			) : (
				<>
					<div className="main-container">
						<div className="header--container">
							<h1 className="title--header">Quiebre Stock</h1>
							<Form className="stock-form">
								<Form.Group>
									<Form.Control
										className="stock-search"
										type="search"
										placeholder="Buscar..."
										value={searchTerm}
										onChange={e => {
											setSearchTerm(e.target.value)
											delayedHandleFilterChange(e)
										}}
									/>
								</Form.Group>
							</Form>
						</div>
						<div className="card--container" >
							<div className="card--header--row">
								<ToggleButtonGroup
									value={tabSection}
									exclusive
									onChange={(event, newTab) => handleTabChange(newTab)}
									aria-label="text alignment"
								>
									<ToggleButton
										value="Bioquimicos"
										aria-label="Bioquimicos"
										className={tabSection === "Bioquimicos" ? "active" : ""}
										sx={{
											width: '149px',
											height: '40px',
											flexShrink: 0,
										}}
									>
										Bioquímicos
									</ToggleButton>
									<ToggleButton
										value="Varios"
										aria-label="Varios"
										className={tabSection === "Varios" ? "active" : ""}
										sx={{
											width: '149px',
											height: '40px',
											flexShrink: 0,
										}}
									>
										Varios
									</ToggleButton>
								</ToggleButtonGroup>




								<div className="stock-select-warning">
									{warningCaption && (
										<p className="mb-0">
											<img
												className="text-warning ms-1"
												src={warningIcon}
												alt="warning icon"
											/>{" "}
											Hay productos próximos a vencer
										</p>
									)}

									{expiredCaption && (
										<p className="mb-0 text-danger">
											Hay productos <strong>vencidos</strong>
										</p>
									)}
									<Form.Select
										className="select-stock-provider"
										value={selectedProvider}
										onChange={handleProviderChange}
									>
										<option value="">Seleccionar proveedor</option>
										{allProviders.map(provider => (
											<option
												key={provider._id}
												value={provider._id}
											>
												{provider.name}
											</option>
										))}
									</Form.Select>
								</div>
							</div>

							<div className="table-container">
								<Table className="table-striped table-bordered table-hover ">
									<thead responsive > 
										<tr>
											<th>Nombre</th>
											<th>Proveedor</th>
											<th>Cantidad</th>
											<th>Stock Mínimo</th>
											<th>Estado</th>
											<th>Editar</th>
											<th>Ver más</th>
										</tr>
									</thead>
									<tbody>
										{currentProducts.map((product, i) => {
											return (
												<ItemRow
													backgroundColorClass={isEven(i) ? "backgroundColor_gray" : "backgroundColor_LightGray"}
													item={product}
													key={product._id}
													setItemToEdit={setProductToEdit}
													setUsoSmnalToEdit={setUsoSmnalToEdit}
													setShowModal={setShowModal}
													isOtherProducts={tabSection !== "Bioquimicos"}
												/>
											)
										})}
									</tbody>
								</Table>
							</div>
						</div>
						<Pagination className="pagination-all-tag">
							<Pagination.Prev
								onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
								disabled={currentPage === 1}
							/>
							{renderPaginationItems()}
							<Pagination.Next
								onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
								disabled={currentPage === totalPages}
							/>
						</Pagination>
					</div>


					{/*Modal para modificación quiebre para productos*/}
					<Modal
						show={showModal}
						onHide={handleClose}
						backdrop="static"
					>
						<Modal.Header closeButton>
							<Modal.Title>Editar Stock Mínimo</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form>
								<Form.Group
									className="mb-3"
									controlId="exampleForm.ControlInput1"
								>
									<Form.Label>Stock Mínimo</Form.Label>
									<Form.Control
										type="number"
										value={usoSmnalToEdit}
										onChange={e => {
											setUsoSmnalToEdit(e.target.value)
										}}
									/>
								</Form.Group>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={handleClose}
							>
								Cerrar
							</Button>
							<Button
								variant="primary"
								onClick={() => {
									updateProduct(productToEdit, usoSmnalToEdit)
									handleClose()
								}}
							>
								Guardar cambios
							</Button>
						</Modal.Footer>
					</Modal>

					{/* Botonera inferior derecha */}
					<div className="container-btn-footer">
						<div className="button-footer">
						<Tooltip title= 'Generar excel' arrow>
							<Button
								className="btn--primary--abm btn btn--primary"
								onClick={() => {
									if (!selectedProvider) {
										window.alert("Por favor, selecciona un proveedor antes de generar el Excel")
									} else {
										generateOrder()
									}
								}}
							>
								<DescriptionOutlinedIcon
								/>
							</Button>
							</Tooltip>
							<Tooltip title= 'Exportar PDF' arrow>
							<Button
								className="btn--primary--abm btn btn--primary"
								onClick={() => {
									if (!selectedProvider) {
										window.alert("Por favor, selecciona un proveedor antes de generar el PDF")
									}
								}}
								disabled={!selectedProvider}
							>
								<PDFDownloadLink
									document={
										<StockoutPDF
											productsToOrder={productsToOrder}
											isOtherProducts={tabSection !== "Bioquimicos"}
										/>
									}
									fileName="QuiebreStock"
								>
									<img
										className="icon_buttons"
										src={PdfIcon}
										alt="camera capture"
									/>
								</PDFDownloadLink>
							</Button>
							</Tooltip>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default StockProducts
