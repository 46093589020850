import React, { useState, useEffect } from "react"
import { createHashRouter, useNavigate } from "react-router-dom"
import axios from "axios"
import "./AddProducts.css"
import "react-datepicker/dist/react-datepicker.css"
import Cookies from "js-cookie"
import CustomAlert from "../../CustomAlert/customAlert"
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful"
import ProductsForm from "./ProductsForm"
import OtherProductsForm from "./OtherProductsForm"

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

const AddProduct = () => {
 
	const navigate = useNavigate()


	const [unitTypesProduct, setUnitTypesProduct] = useState([])
	const [unitTypesOtherProduct, setUnitTypesOtherProduct] = useState([])
	const [providers, setProviders] = useState([])
	const [categories, setCategories] = useState([])
	const [productsData, setProductData] = useState([])
	const [otherProductsData, setOtherProductData] = useState([])

	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")

	const [option, setOption] = useState("Bioquimico")
	const [showBioquimicoForm, setShowBioquimicoForm] = useState(true)
	const [showVariosForm, setShowVariosForm] = useState(false)

	useEffect(() => {
		// Obtiene categorias
		async function getDataCategories() {
			const token = Cookies.get("token")
			try {
				const response = await axios.get(`${BACK_APP_URI}/categories`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				setCategories(response.data.categories)
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}
		getDataCategories()

		// Obtiene proveedores
		async function getDataProviders() {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/proveedor`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setProviders(result.data.provider)
				})
		}
		getDataProviders()

		// Obtiene unidades de bioquimicos
		async function getDataUnits() {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/unidades`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					console.log(result.data.units)
					setUnitTypesProduct(result.data.units)
				})
		}
		getDataUnits()

		// Obtiene unidades de varios
		async function getUnitOtros() {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/unidades`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setUnitTypesOtherProduct(result.data.units)
				})
		}
		getUnitOtros()

		// Obtiene todos los productos bioquimicos
		async function getProducts() {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/productos`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setProductData(result.data.products)
				})
		}
		getProducts()

		// Obtiene todos los productos varios
		async function getOtherProducts() {
			const token = Cookies.get("token")
			await axios
				.get(`${BACK_APP_URI}/otros_productos`, {
					headers: {
						Authorization: `${token}`,
					},
				})
				.then(result => {
					setOtherProductData(result.data.products)
				})
		}
		getOtherProducts()
	}, [])

	const onOptionChange = e => {
		const value = e.target.value
		setOption(value)

		if (value === "Bioquimico") {
			setShowBioquimicoForm(true)
			setShowVariosForm(false)
		} else if (value === "Varios") {
			setShowBioquimicoForm(false)
			setShowVariosForm(true)
		}
	}

	const handleSuccessfulMessage = msg => {
		setVariable(true)
		setErrorBool(false)
		setAvisomsg(msg)
		setTimeout(() => {
			setVariable(false)
			navigate("/productos")
		}, 1200)
	}

	const handleUnsuccessfulMessage = msg => {
		setErrorBool(true)
		setVariable(true)
		setAvisomsg(msg)
		setTimeout(() => {
			setVariable(false)
		}, 2000)
	}
 
	return (
		<div className="add-product">
			<div className="add-product-target">
				<div className="add-product-target-header">
				<h1 className="target-title">Agregar Producto</h1>
				<div className="header-row">
					<h4 className="target-subtitle" >Tipo de producto:</h4>
					<div className="target-select">
					<input
						type="radio"
						name="option"
						value="Bioquimico"
						id="bioquimico"
						checked={option === "Bioquimico"}
						onChange={onOptionChange}
					/>
					
					<label
						htmlFor="bioquimico"
						className="radio-button"
					>
						{" "}
						Bioquimico{" "}
					</label>
					<input
						type="radio"
						name="option"
						value="Varios"
						id="varios"
						checked={option === "Varios"}
						onChange={onOptionChange}
					/>
					<label
						htmlFor="varios"
						className="radio-button"
					>
						{" "}
						Varios{" "}
					</label>
					</div>
					</div>
					<div className="add-product-target-cont">
					{/*Form especifico para Bioquimicos*/}
					{showBioquimicoForm && (
						<ProductsForm
							providers={providers}
							unitTypesProduct={unitTypesProduct}
							productsData={productsData}
							handleSuccessfulMessage={handleSuccessfulMessage}
							handleUnsuccessfulMessage={handleUnsuccessfulMessage}
						/>
					)}

					{/*Form especifico Varios */}
					{showVariosForm && (
						<OtherProductsForm
							providers={providers}
							unitTypesOtherProduct={unitTypesOtherProduct}
							categories={categories}
							handleSuccessfulMessage={handleSuccessfulMessage}
							handleUnsuccessfulMessage={handleUnsuccessfulMessage}

						/>
					)}
				</div>
				{variable && (
					<AnimacionSuccessful
						errorBool={errorBool}
						avisomsg={avisomsg}
					/>
				)}
				</div>
			</div>
		</div>
	)
}

export default AddProduct
