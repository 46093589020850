import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import "./AllProviders.css";
import { Tooltip } from "@mui/material";

import Cookies from "js-cookie";
import AbmButtons from "../../ABMbotones/abmButtons";
import IconFilter from "../../../Assets/Img/filter_icon.svg";
import IconAdd from "../../../Assets/Img/icon_add.svg";
import DownloadingIcon from '@mui/icons-material/Downloading';
import IconDelete from "../../../Assets/Img/icon_delete.svg";
import IconDesplegable from "../../../Assets/Img/icon_desplegable.svg";
import IconEdit from "../../../Assets/Img/icon_edit.svg";
import { Button } from "react-bootstrap";
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful";
import iconExcel from "./../../../Assets/Img/iconExcel.svg";
import { utils, writeFile } from "xlsx";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

export const AllProviders = () => {
  const [allProviders, setAllProviders] = useState([]);
  const [proveedorSelected, setProveedorSelected] = useState(null);
  const [isAdmin, setIsAdmin] = useState("");
  const [showContactCard, setShowContactCard] = useState(false);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0); // Número de página actual
  const providersPerPage = 5; // Cantidad de proveedores por página

  const pagesVisited = pageNumber * providersPerPage; // Índice del primer proveedor a mostrar en la página actual

  // Estados para AnimacionSuccessful
  const [errorBool, setErrorBool] = React.useState(false);
  const [avisomsg, setAvisomsg] = React.useState('');
  const [variable, setVariable] = React.useState(false);

  function refreshPage() {
    window.location.reload(false);
  }

  const seleccionar = (provider) => {
    setProveedorSelected(provider._id);
  };

  const deseleccionar = () => {
    setProveedorSelected(null);
  };

  const handleDelete = () => {
    axios({
      method: "delete",
      url: `${BACK_APP_URI}/proveedor/delete/${proveedorSelected}`,
    }).then((data) => {
      setVariable(true);
      setErrorBool(false);
      setAvisomsg('Proveedor eliminado correctamente');
    })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let timeoutId;

    const getDataProviders = async () => {
      const token = Cookies.get("token");
      try {
        const result = await axios.get(`${BACK_APP_URI}/proveedor`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setIsAdmin(result.data.userdata); // setea el rol del user logueado
        console.log('Proveedores totales en la B.D: ', result.data.provider);

        const filteredProviders = result.data.provider.filter(
          (provider) =>
            provider.name?.toLowerCase().includes(search.toLowerCase()) ||
            provider.lastName?.toLowerCase().includes(search.toLowerCase()) ||
            provider.deliveryDays.join(",").includes(search.toLowerCase()) ||
            provider.deliveryDelay.includes(search) ||
            provider.cuit.toString().includes(search.toString()) ||
            provider.number.toString().includes(search.toString()) ||
            provider.email.toLowerCase().includes(search.toLowerCase())
        );

        setAllProviders(filteredProviders);
        console.log('Proveedores filtrados: ', filteredProviders);
      } catch (error) {
        console.error(error);
      }
    };

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      getDataProviders();
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  const pageCount = Math.ceil(allProviders.length / providersPerPage);

  const changePage = (event, value) => {
    setPageNumber(value - 1);
  };

  const toggleContactCard = () => {
    setShowContactCard(!showContactCard);
  };

  const displayProviders = allProviders
    .slice(pagesVisited, pagesVisited + providersPerPage)
    .map((provider) => (
      <React.Fragment key={provider._id}>
        <tr
          onClick={() => seleccionar(provider)}
          className={
            provider._id === proveedorSelected
              ? "selected-row tr-table"
              : "tr-table" 
          }
        >
          <td>{provider.name}</td>
          <td className="cuit-th">{provider.cuit}</td>
          <td>{provider.number}</td>
          <td>{provider.email ? provider.email : "No asignado"}</td>
          <td>{provider.deliveryDelay}</td>
          <td>{provider.deliveryDays.join()}</td>

          {/* Botones de acción para tabla */}
          <td className="container-btn-action">
            <Tooltip title="Ver contacto" arrow>
              <Button className="btn-action" onClick={toggleContactCard}>
                <img className="icon-btn-action" src={IconDesplegable} alt="Desplegar" />
              </Button>
            </Tooltip>

          </td>
        </tr>

        {provider._id === proveedorSelected && showContactCard && (
          <tr className="contact-card">
            <td colSpan={7}>
              <div className="card-content">
                {provider.contacts.length > 0 ? (
                  provider.contacts.map((contact, index) => (
                    <div key={index} className="contact-info">
                      <div className="info-label">
                        Nombre: {contact.name} Teléfono: {contact.phoneNumber}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No hay información de contacto disponible.</div>
                )}
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    ));

  //---------------------HANDLERS------------------------------

  const handleExportProvidersSheet = () => {
    const flattenedProvidersData = allProviders.flatMap((provider) => {
      const contactsData = provider.contacts.map((contact) => ({
        Nombre: provider.name,
        Cuit: provider.cuit,
        Telefono: provider.number,
        Email: provider.email,
        Demora: provider.deliveryDelay,
        Direccion: provider.address,
        Entregas: provider.deliveryDays.join(", "),
        Contacto: contact.name || "Sin Nombre",
        TelefonoContacto: contact.phoneNumber || "Sin Teléfono",
      }));
      return contactsData;
    });

    const worksheet = utils.json_to_sheet(flattenedProvidersData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Lista de proveedores");
    writeFile(workbook, `Lista_Proveedores_Prueba.xlsx`);
  };

  const handleSortAZ = () => {
    const sortedProviders = [...allProviders];

    // Ordena los proveedores por el campo "Nombre" de A-Z
    sortedProviders.sort((a, b) => a.name.localeCompare(b.name));

    // Actualiza el estado con los proveedores ordenados
    setAllProviders(sortedProviders);
  };

  return (
    <div className="main-container">
      <div className="header--container">
        <div className="">
          <h1 className="title--header">Proveedores</h1> 
        </div>
        <div className="btn-header-provider">
          <Form className="provider-form">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control
                className="p-2"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPageNumber(0);
                }}
                placeholder="Buscar..."
              />
            </Form.Group>
          </Form>
          <div className="buttons-all-provide">
            
            
            <Tooltip title="Exportar" arrow>
            <button className="btn-footer " onClick={handleExportProvidersSheet}>
            Exportar
					
						<DownloadingIcon className="icon-btn"/>
						
					</button>
					</Tooltip>
            
          </div>
        </div>
      </div>
      <div className="card--container">
        <div className=" btn-filter">
      <h3 className="text-filter ">Filtro A-Z</h3>
            <button className="icon-filter" onClick={handleSortAZ}>
              <img src={IconFilter} alt="icono" />
            </button>
            </div>
        <div className="table-container-new">
          <Table responsive className="table-striped table-bordered table-hover">
            <thead responsive>
              <tr onClick={() => { deseleccionar(); }}>
                <th className="title-th">Nombre</th>
                <th className="cuit-th title-th">Cuit</th>
                <th className="title-th">Telefono</th>
                <th className="title-th">Email</th>
                <th className="title-th">Demora (dias)</th>
                <th className="title-th">Entrega</th>
                <th className="title-th">Acciones</th>
              </tr>
            </thead>
            <tbody className="no-sass">
              {displayProviders}
            </tbody>
          </Table>
        </div>
        <div className="table-footer-provider">

          <Stack spacing={2} className="pagination-all-tag">
            <Pagination
              count={pageCount}
              page={pageNumber + 1}
              onChange={changePage}
              color="primary"
            />
          </Stack>
        </div>
      </div>

      {/* Popup */}
      {variable && (
        <AnimacionSuccessful
          errorBool={errorBool}
          avisomsg={avisomsg}
          refreshPage={refreshPage}
        />
      )}

      <div className="btn-position">
        <AbmButtons
          item={proveedorSelected}
          abm={"provider"}
          funcion={handleDelete}
          role={isAdmin}
        />

      </div>

    </div>
  );
};
