import React, { useState } from "react"
import { read, utils } from "xlsx"
import Table from "react-bootstrap/esm/Table"
import axios from "axios"
import Modal from "react-bootstrap/esm/Modal"
import Button from "react-bootstrap/esm/Button"
import iconExcel from "../ABMbotones/iconButtonAbm/iconExcel.svg"
import Form from "react-bootstrap/esm/Form"
import AnimacionSuccessful from "../AnimacionSuccessful/animacionSuccessful"
import { useNavigate } from "react-router-dom"
import { Nav } from "react-bootstrap"
import "./CargaViaExcel.css"
import AcceptAdditionModal from "./Modals/AcceptAdditionModal";
import AcceptReductionModal from "./Modals/AcceptReductionModal";
import AcceptEditionModal from "./Modals/AcceptEditionModal";
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';



const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const CargaViaExcel = () => {
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const [dataHeader, setDataHeader] = useState([])
	const [handleSelection, setHandleSelection] = useState("Ingresar")
	const [show, setShow] = useState(false)
	const [tabSection, setTabSection] = useState("Bioquimicos")
	const [idType, setIdType] = useState("Code")

	// Si los productos enviados vienen sin lotes
	const [isProductWithoutLotOperation, setIsProductWithoutLotOperation] = useState(false)

	// Suma
	const [showAcceptAdditionModal, setShowAcceptAdditionModal] = useState(false)
	const [productsToAcceptAddition, setProductsToAcceptAddition] = useState({})

	// Descontar
	const [showAcceptReductionModal, setShowAcceptReductionModal] = useState(false)
	const [productsToAcceptReduction, setProductsToAcceptReduction] = useState({})

	// Edicion
	const [showAcceptEditionModal, setShowAcceptEditionModal] = useState(false)
	const [productsToAcceptEdition, setProductsToAcceptEdition] = useState({})

	// Estados para Popup

	const [variable, setVariable] = useState(false)
	const [errorBool, setErrorBool] = useState(false)
	const [avisomsg, setAvisomsg] = useState("")

	// Funcion para ir a "Productos"

	function redirectPage() {
		navigate("/productos")
	}

	const handleClose = () => {
		setShow(false)
		setData([])
	}
	const handleShow = () => setShow(true)

	const handleFileChange = e => {
		const file = e.target.files[0]
		// Aca se crea una instancia de FileReader
		const reader = new FileReader() // FileReader es una interfaz en JavaScript que proporciona la funcionalidad para leer archivos y obtener su contenido

		reader.readAsArrayBuffer(file) //  se utiliza reader.readAsArrayBuffer(file) para leer el archivo seleccionado como un ArrayBuffer

		reader.onload = e => {
			// Leer los datos del archivo como un Uint8Array
			const data = new Uint8Array(e.target.result)

			// Leer el archivo como un libro de Excel utilizando una biblioteca externa (probablemente xlsx.js)
			const workbook = read(data, { type: "array" })

			// Obtener la primera hoja de cálculo del libro de Excel
			const worksheet = workbook.Sheets[workbook.SheetNames[0]]

			// Convertir la hoja de cálculo a un objeto JSON utilizando una biblioteca externa (probablemente xlsx.js)
			const jsonData = utils.sheet_to_json(worksheet, { header: 1 })

			// Filtrar el objeto JSON para eliminar filas vacías o sin datos
			const arrayFiltrado = jsonData
				.filter(array => array.some(element => element !== undefined && element !== null && element !== ""))
				.map(row => {
					const newRow = Array.from(row, item => (item === undefined ? "" : item))

					if (row.length < jsonData[0].length) {
						for (let i = 0; i < jsonData[0].length - row.length; i++) {
							newRow.push("")
						}
					}

					return newRow
				})

			// Asignar los datos filtrados a una variable de estado llamada "data"
			setData(arrayFiltrado)

			// Asignar la segunda fila del conjunto de datos filtrados a una variable de estado llamada "dataHeader"
			setDataHeader(arrayFiltrado[0])
		}
	}
	const generarArregloJSON = async () => {
		const jsonArr = []

		// Obtener los nombres de atributos de la primera fila
		const attributeNames = data[0]

		// Iterar sobre las filas a partir de la segunda fila
		for (let i = 1; i < data.length; i++) {
			const rowData = data[i]
			const jsonItem = {}

			// Iterar sobre las columnas
			for (let j = 0; j < rowData.length; j++) {
				const attributeName = attributeNames[j]
					.replace(/\s/g, "")
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase() // el .replace se pone para quitar espacios en el nombre y tildes
				const attributeValue = rowData[j]
				jsonItem[attributeName] = attributeValue
			}

			jsonArr.push(jsonItem)
		}

		try {
			if (tabSection === "Bioquimicos") {
				if (handleSelection === "Ingresar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/productos/sumarExcel`,
						data: {
							products: jsonArr,
							idType: idType,
							isProductWithoutLotOperation: isProductWithoutLotOperation,
						},
					})

					setProductsToAcceptAddition(response.data)
					setShowAcceptAdditionModal(true)
					handleClose()
				} else if (handleSelection === "Descontar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/productos/descontarExcel`,
						data: {
							products: jsonArr,
							idType: idType,
						},
					})

					setProductsToAcceptReduction(response.data)
					setShowAcceptReductionModal(true)
					handleClose()
				} else if (handleSelection === "Editar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/productos/editarExcel`,
						data: {
							products: jsonArr,
							idType: idType,
						},
					})

					setProductsToAcceptEdition(response.data)
					setShowAcceptEditionModal(true)
					handleClose()
				}
			} else {
				if (handleSelection === "Ingresar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/otros_productos/sumarExcel`,
						data: {
							products: jsonArr,
						},
					})

					setProductsToAcceptAddition(response.data)
					setShowAcceptAdditionModal(true)
					handleClose()
				} else if (handleSelection === "Descontar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/otros_productos/descontarExcel`,
						data: {
							products: jsonArr,
						},
					})

					setProductsToAcceptReduction(response.data)
					setShowAcceptReductionModal(true)
					handleClose()
				} else if (handleSelection === "Editar") {
					const response = await axios({
						method: "post",
						url: `${BACK_APP_URI}/otros_productos/editarExcel`,
						data: {
							products: jsonArr,
						},
					})

					setProductsToAcceptEdition(response.data)
					setShowAcceptEditionModal(true)
					handleClose()
				}
			}
		} catch (error) {
			console.error(`Error updating data:`, error)
		}
	}

	const handleTabChange = section => {
		if (section === "Bioquimicos") {
			setHandleSelection("Ingresar")
		} else {
			setHandleSelection("Nuevos")
		}
		setProductsToAcceptAddition([])
		setProductsToAcceptReduction([])
		setProductsToAcceptEdition([])
		setTabSection(section)
	}

	const handleSuccessfulMessage = msg => {
		setVariable(true)
		setErrorBool(false)
		setAvisomsg(msg)
	}

	const handleErrorMessage = msg => {
		setVariable(true)
		setErrorBool(true)
		setAvisomsg(msg)
	}

    return (
        <>
            <Button className="btn-camera" onClick={handleShow}>
                <PostAddOutlinedIcon
                />
            </Button>

			<AcceptAdditionModal
				isProductWithoutLotOperation={isProductWithoutLotOperation}
				handleClose={() => setShowAcceptAdditionModal(false)}
				show={showAcceptAdditionModal}
				productsToAcceptAddition={productsToAcceptAddition}
				handleSuccessfulMessage={handleSuccessfulMessage}
				handleErrorMessage={handleErrorMessage}
				tabSection={tabSection}
			/>
			<AcceptReductionModal
				handleClose={() => setShowAcceptReductionModal(false)}
				show={showAcceptReductionModal}
				productsToAcceptReduction={productsToAcceptReduction}
				handleSuccessfulMessage={handleSuccessfulMessage}
				handleErrorMessage={handleErrorMessage}
				tabSection={tabSection}
			/>
			<AcceptEditionModal
				handleClose={() => setShowAcceptEditionModal(false)}
				show={showAcceptEditionModal}
				productsToAcceptEdition={productsToAcceptEdition}
				handleSuccessfulMessage={handleSuccessfulMessage}
				handleErrorMessage={handleErrorMessage}
				tabSection={tabSection}
			/>

			<Modal
				size="lg"
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<div className="modal-container">
					<Modal.Title className="modal-title-excel">Carga Mediante Excel</Modal.Title>
					<div className="btn-tab-modal">
						<Nav
							className="nav-cont-stock nav-tabs"
							defaultActiveKey={tabSection}
						>
							<Nav.Item>
								<Nav.Link
									eventKey="biochemicals"
									onClick={() => handleTabChange("Bioquimicos")}
									active={tabSection === "Bioquimicos"}
								>
									Bioquímicos
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									eventKey="Varios"
									onClick={() => handleTabChange("Varios")}
									active={tabSection === "Varios"}
								>
									Varios
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>
					<Modal.Header>
						<Form.Group>
							{tabSection === "Bioquimicos" && (
								<div className="container-check-modal">
									<div className="check-modal-row">
										<Form.Check
											className="check-modal-excel"
											inline
											label="Ingresar"
											checked={handleSelection === "Ingresar"}
											onChange={() => {
												setHandleSelection("Ingresar")
											}}
										/>
										<Form.Check
											className="check-modal-excel"
											inline
											label="Descontar"
											checked={handleSelection === "Descontar"}
											onChange={() => {
												setHandleSelection("Descontar")
											}}
										/>
									</div>
									<div className="check-modal-row">
										<Form.Check
											className="check-modal-excel"
											inline
											label="Editar"
											checked={handleSelection === "Editar"}
											onChange={() => {
												setHandleSelection("Editar")
											}}
										/>

										<Form.Check
											inline
											label="Código"
											checked={idType === "Code"}
											onChange={() => {
												setIdType("Code")
											}}
											className="custom-checkbox"
										/>
									</div>
									<div className="check-modal-row">
										<Form.Check
											inline
											label="Nbu"
											checked={idType === "Nbu"}
											onChange={() => {
												setIdType("Nbu")
											}}
											className="custom-checkbox"
										/>

										{handleSelection === "Ingresar" && (
											<>
												<Form.Check
													inline
													type="switch"
													label="Solo producto base"
													checked={isProductWithoutLotOperation}
													onChange={() => {
														setIsProductWithoutLotOperation(prev => !prev)
													}}
													className="custom-checkbox"
												/>
											</>
										)}
									</div>
								</div>
							)}
							{tabSection === "Varios" && (
								<>
									<Form.Check
										inline
										label="Ingresar"
										checked={handleSelection === "Ingresar"}
										onChange={() => {
											setHandleSelection("Ingresar")
										}}
									/>
									<Form.Check
										inline
										label="Descontar"
										checked={handleSelection === "Descontar"}
										onChange={() => {
											setHandleSelection("Descontar")
										}}
									/>

									<Form.Check
										inline
										label="Editar"
										checked={handleSelection === "Editar"}
										onChange={() => {
											setHandleSelection("Editar")
										}}
									/>
								</>
							)}
						</Form.Group>
					</Modal.Header>
					<Modal.Body>
						<div>
							<Form.Group
								controlId="formFile"
								className="mb-3"
							>
								<Form.Control
									className="input-modal"
									type="file"
									onChange={e => {
										handleFileChange(e)
									}}
								/>
							</Form.Group>
						</div>
						{data.length > 0 ? (
							<>
								<div className="header-table-modal">
									<h2>Productos</h2>
									<div className="">
										<Table
											className=" table-striped table-bordered table-hover"
											responsive
										>
											<thead>
												<tr>
													{dataHeader.map((item, index) => (
														<th key={index}>{item}</th>
													))}
												</tr>
											</thead>
											<tbody>
												{data.slice(1).map((rowData, rowIndex) => (
													<tr key={rowIndex}>
														{rowData.map((cellData, cellIndex) => (
															<td key={cellIndex}>{cellData}</td>
														))}
													</tr>
												))}
											</tbody>
										</Table>
									</div>
								</div>
							</>
						) : null}
						<Modal.Footer>
							<div className="d-flex">
								{data.length > 0 ? (
									<Button
										style={{ width: "10rem", marginRight: "1rem" }}
										className="btn--primary"
										variant="primary"
										onClick={generarArregloJSON}
									>
										Cargar
									</Button>
								) : null}

								<Button
									variant="secondary"
									onClick={handleClose}
								>
									Cerrar
								</Button>
							</div>
						</Modal.Footer>
					</Modal.Body>
				</div>
			</Modal>

			{/* Se agrega el pop-up */}
			{variable ? (
				<AnimacionSuccessful
					errorBool={errorBool}
					avisomsg={avisomsg}
					refreshPage={redirectPage}
				/>
			) : (
				<></>
			)}
		</>
	)
}

export default CargaViaExcel
