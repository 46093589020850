import React, { useState } from "react"
import { Form, Table } from "react-bootstrap"

export default function PendingProductsTable({ pendingItems, handleItems, disabled }) {
	return (
		<div className="mb-3 ml-3">
			<div className="pendingProductsTable">Productos Pendientes</div>
			<Table responsive
				className=" rounded"
				borderless
			>
				<thead>
					<tr>
						<th className="text-start">Nombre</th>
						<th>C/ Pedida</th>
						<th>Medida</th>
						<th>Administrar</th>
					</tr>
				</thead>

				<tbody>
					{pendingItems.map(item => {
						return (
							<tr key={item.data._id}>
								<th className="text-start">{item.data.name}</th>
								<th>{item.quantityRequested}</th>
								<td>{item.data.measure?.name || item.data.unitType?.name}</td>
								<td>
									<span>Pendiente</span>
									<Form.Check
										className="d-inline p-1"
										type="checkbox"
										checked={true}
										onChange={() => handleItems(item.data._id)}
										disabled={disabled}
									/>
								</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		</div>
	)
}
