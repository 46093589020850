import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import "./EditProducts.css";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";
import AnimacionSuccessful from "../../AnimacionSuccessful/animacionSuccessful";
import TagSelectorForm from "../Components/TagSelectorForm";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;

const EditOtherProducts = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [expireDate, setExpireDate] = useState(new Date());
  const [lotNumber, setLotNumber] = useState("");
  const [unitType, setUnitType] = useState("");
  const [weightOrVolume, setWeightOrVolume] = useState(undefined)
  const [code, setCode] = useState(0);
  const [provider, setProvider] = useState("");
  const [allProviders, setAllProviders] = useState([]);
  const [redirectToProvider, setRedirectToProvider] = useState(false);
  const [tag, setTag] = useState(null) // Dejarlo en null para que el middleware funcione
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");

  const [allUnits, setAllUnits] = useState([]);

  // Manejando mensajes de error, éxito, etc
  const [variable, setVariable] = useState(false);
  const [errorBool, setErrorBool] = useState(false);
  const [avisomsg, setAvisomsg] = useState("");
  
	const [showWeightOrVolumeInput, setShowWeightOrVolumeInput] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    async function getDataProduct() {
      await axios
        .get(`${BACK_APP_URI}/otros_productos/${id}`)
        .then((result) => {
          setCode(result.data.code);
          setSelectedCat(result.data.category);
          setValue("category", result.data.category);
          setName(result.data.name)
          setValue("name", result.data.name);
          setQuantity(result.data.quantity);
          setValue("quantity", result.data.quantity);
          setWeightOrVolume(result.data.weightOrVolume);
          setValue("weightOrVolume", result.data.weightOrVolume);
          setLotNumber(result.data.lotNumber);
          setValue("lotNumber", result.data.lotNumber);
          setUnitType(result.data.unitType);
          setValue("unitType", result.data.unitType);
          setProvider(result.data.provider);
          setValue("provider", result.data.provider);
          setTag(result.data.tag);
        });
    }
    async function getUnitOtros() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/unidades`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((result) => {
          setAllUnits(result.data.units);
        });
    }
    async function getDataCategories() {
      const token = Cookies.get("token");
      try {
        const response = await axios.get(`${BACK_APP_URI}/categories`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setAllCategories(response.data.categories);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    async function getDataProviders() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/proveedor`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((result) => {
          setAllProviders(result.data.provider);
        });
    }
    getDataProviders();
    getDataCategories();
    getUnitOtros();
    getDataProduct();
  }, []);

  useEffect(() => {

    if(allUnits && unitType) {
      const unitAssigned = allUnits.find(unit => unit._id === unitType)

      if(unitAssigned && unitAssigned.name !== "Unitarios" && unitAssigned.name !== "Primaria") {
        setShowWeightOrVolumeInput(true)
      }
    }

  }, [allUnits, weightOrVolume])

  const onSubmit =  async (e) => {
    e.preventDefault()
    axios({
      method: "put",
      url: `${BACK_APP_URI}/otros_productos/edit/${id}`,
      data: {
        code: code,
        category: selectedCat,
        name: name,
        quantity: quantity,
        weightOrVolume: weightOrVolume,
        expireDate: expireDate,
        lotNumber: lotNumber,
        unitType: unitType,
        provider: provider,
        tag,
      },
    })
      .then((data) => {
        console.log(data);
        setVariable(true);
        setErrorBool(false);
        setAvisomsg(data.data.msg);
        setTimeout(() => {
          setVariable(false);
          navigate("/productos");
        }, 1200);
      })
      .catch((data) => {
        setErrorBool(true);
        setVariable(true);
        setAvisomsg(data.response.data.msg);
        setTimeout(() => {
          setVariable(false);
        }, 1200);
        console.log(".:Mensaje de error:.");
        console.log(data.response.data.msg);
      });
  };

  //Función para manejar el click en el select Agregar Proveedor
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "add") {
      setRedirectToProvider(true);
    } else {
      setProvider(selectedValue);
    }
  };
  const handleAddProvider = (action) => {
    if (action === "agregar") {
      window.location.href = "/provider/add"; // Redirecciona a crear proveedor
    } else if (action === "cancelar") {
      setRedirectToProvider(false); // Cambia el estado de redirectToProvider a false
    }
  };

  return (
    <div className="container-principal">
      <div className="container-tarjet">
        <h1>Editar Productos Varios</h1>
        <div className="dividers" />
        <div className="container-content-tarjet">
          <h1 className="h1-productos">Producto</h1>
          <Form className="container-form" onSubmit={ e => handleSubmit(onSubmit(e))}>
            <FloatingLabel label="Codigo de producto" className="mb-3">
              <Form.Control
                value={code}
                className="input--primary"
                placeholder="Codigo" 
                name="Codigo"
                type="text"
                {...register("code", {
                  required: true,
                  maxLength: 40,
                })}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
              {errors.code?.type === "required" && (
                <p className="error">Campo requerido </p>
              )}
              {errors.code?.type === "maxLength" && (
                <p className="error">Cantidad máxima de caracteres es de 40</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Categoría" className="mb-3">
              <Form.Select
                className="selectFormLabel"
                {...register("category", {
                  required: true,
                })}
                value={selectedCat}
                onChange={(e) => {
                  setSelectedCat(e.target.value);
                }}
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {allCategories.map((cat) => (
                  <option key={cat._id} value={cat._id}>
                    {cat.name}
                  </option>
                ))}
              </Form.Select>
              {errors.category?.name && (
                <p className="error">Campo requerido</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Nombre" className="mb-3">
              <Form.Control
                value={name}
                className="input--primary"
                placeholder="name"
                name="name"
                type="text"
                {...register("name", {
                  required: true,
                  maxLength: 40,
                })}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {errors.name?.type === "required" && (
                <p className="error">Campo requerido </p>
              )}
              {errors.name?.type === "maxLength" && (
                <p className="error">Cantidad máxima de caracteres es de 40</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Cantidad" className="mb-3">
              <Form.Control
                value={quantity}
                className="input--primary"
                placeholder="Cantidad"
                name="Cantidad"
                type="text"
                {...register("quantity", {
                  required: true,
                  maxLength: 40,
                  pattern: /^[0-9]+$/, //Numeros
                })}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              />
              {errors.quantity?.type === "required" && (
                <p className="error">Campo requerido </p>
              )}
              {errors.quantity?.type === "maxLength" && (
                <p className="error">Cantidad máxima de caracteres es de 40</p>
              )}
              {errors.quantity?.type === "pattern" && (
                <p className="error">Solo puede contener numeros</p>
              )}
            </FloatingLabel>
            <FloatingLabel label="Tipo de unidad" className="mb-3">
              <Form.Select
                className="selectFormLabel"
                {...register("unitType", {
                  required: true,
                })}
                value={unitType}
                onChange={(e) => {
                  setUnitType(e.target.value);
                  const selectedUnit = allUnits.find(
                    (unit) => unit._id === e.target.value
                  );
                  if (selectedUnit && (selectedUnit.name === "Unitarios" || selectedUnit.name === "Primaria")) {
                    setShowWeightOrVolumeInput(false);
                    setWeightOrVolume(null)
                    setValue("weightOrVolume", null);
                  } else {
                    setShowWeightOrVolumeInput(true);
                  }
                }}
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {allUnits.map((unitType) => (
                  <option key={unitType._id} value={unitType._id}>
                    {unitType.name}
                  </option>
                ))}
              </Form.Select>
              {errors.unitType && (
                <p className="error">Campo requerido</p>
              )}
            </FloatingLabel>
            {showWeightOrVolumeInput && (
              <FloatingLabel label="Peso o Volumen" className="mb-3">
                <Form.Control
                  className="input--primary"
                  placeholder="Peso o Volumen"
                  name="weightOrVolume"
                  value={weightOrVolume}
                  type="text"
                  {...register("weightOrVolume", {
                    required: true,
                    maxLength: 40,
                    pattern: /^[0-9]+$/, //Numeros
                  })}
                  onChange={(e) => {
                    setWeightOrVolume(e.target.value);
                  }}
                />
                {errors.weightOrVolume?.type === "required" && (
                  <p className="error">Campo requerido </p>
                )}
                {errors.weightOrVolume?.type === "maxLength" && (
                  <p className="error">
                    Cantidad máxima de caracteres es de 40
                  </p>
                )}
                {errors.weightOrVolume?.type === "pattern" && (
                  <p className="error">Solo puede contener numeros</p>
                )}
              </FloatingLabel>
            )}
            {/* Select de proveedores y la opción de agregar uno */}
            {!redirectToProvider && (
              <FloatingLabel label="Proveedor" className="mb-3">
                <Form.Select
                  className="selectFormLabel"
                  value={provider}
                  onChange={handleSelectChange}
                >
                  <option value="" disabled>
                    Seleccionar
                  </option>
                  {allProviders.map((provider) => (
                    <option key={provider._id} value={provider._id}>
                      {provider.name}
                    </option>
                  ))}
                  <option value="add">Agregar proveedor</option>
                </Form.Select>
                {errors.provider?.type === "required" && (
                  <p className="error">Campo requerido</p>
                )}
              </FloatingLabel>
            )}

            {redirectToProvider && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  style={{ marginRight: "auto" }}
                  className="btn btn--primary mb-3"
                  onClick={() => handleAddProvider("agregar")}
                >
                  Agregar proveedor
                </Button>
                <Button
                  style={{ marginLeft: "auto" }}
                  className="btn btn--secondary mb-3"
                  onClick={() => handleAddProvider("cancelar")}
                >
                  Cancelar
                </Button>
              </div>
            )}

            <TagSelectorForm tagSelected={tag} setTagSelected={setTag} isForBiochemicals={false} />

            <div className="button-container" style={{ marginTop: "8%", marginBottom: "5%" }}>
            <button
                children="Cancelar"
                type="submit"
                className="btn btn--primary"
                onClick={() => {
                  navigate("/productos");
                }}
              />
              <button
                children="Guardar"
                type="submit"
                className="btn btn--primary"
                
              />
              </div>
            <div />
          </Form>
        </div>
      </div>
      {variable ? (
        <AnimacionSuccessful errorBool={errorBool} avisomsg={avisomsg} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditOtherProducts;
