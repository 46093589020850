import React from "react"
import { Form } from "react-bootstrap"

export default function UserSelectionDropbox({ users, userLabType, handleUserLabTypeChange}) {
	return (
		<div className="ml-3 mb-3 ">
			<div className="no-wrap text-28">
				Usuario:{" "}
				{userLabType ? (
					<b className="text-32">{users.find(user => user.username === userLabType).username}</b>
				) : (
					<i className="opacity ">Seleccionar Usuario</i>
				)}
			</div>
			<div className="text-32">
				<Form.Select onChange={e => handleUserLabTypeChange(e.target.value)}>
					<option value="">Seleccionar usuario</option>
					{users.map(user => {
						return (
							<option
								key={user._id}
								value={user.username}
							>
								{user.username !== "" ? user.username : user.username}
							</option>
						)
					})}
				</Form.Select>
			</div>
		</div>
	)
}
