import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Cookies from "js-cookie";
import "./merma.css";
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

//Comenta el codigo sapo re qliao
const AddMerma = () => {
  const navigate = useNavigate();
  const [productid, setProductid] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [allProducts, setAllProducts] = useState([]);

  const addProduct = (productid, quantity) => {
    axios({
      method: "post",
      url: `${BACK_APP_URI}/merma/add`,
      data: {
        idProduct: productid,
        stockDeposito: quantity,
      },
    });
  };

  React.useEffect(() => {
    async function getDataProducts() {
      const token = Cookies.get("token");
      await axios
        .get("http://localhost:4000/productos", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((result) => {
          console.log(result.data);
          setAllProducts(result.data);
          setProductid(result.data[0]._id)
        });
    }
    getDataProducts();
  }, []);

  return (
    <div className="container-principal">
      <div className="container-tarjet">
        <h1>Agregar cantidad de Productos en Deposito</h1>
        <div className="dividers" />
        <div className="container-content-tarjet">
          <h1 className="h1-producto">Producto</h1>
          <Form className="container-form">
            <FloatingLabel label="Nombre" className="mb-3">
              <Form.Select
                className="selectFormLabel"
                onChange={(e) => {
                  setProductid(e.target.value || allProducts[0]._id);
                  console.log(e.target.value || allProducts[0]._id);
                }}
              >
                {allProducts.map((product) => {
                  return (
                    <option key={product._id} value={product._id}>
                      {product.name}
                    </option>
                  );
                })}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel label="Cantidad" className="mb-3">
              <Form.Control
                className="input--primary"
                placeholder="Cantidad"
                name="Cantidad"
                type="text"
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              />
            </FloatingLabel>
          </Form>
        </div>
        <div className="dividers" />
        <div className="button-container">
          <Button
            style={{ width: "140px" }}
            children="Agregar"
            onClick={() => {
              addProduct(productid, quantity);
              console.log(productid, quantity)
              navigate("/merma");
            }}
          />
          <Button
            style={{ width: "140px" }}
            children="volver"
            onClick={() => {
              navigate("/merma");
            }}
          />
        </div>
        <div />
      </div>
    </div>
  );
};

export default AddMerma;
